<script setup lang="ts" generic="T extends number = number">
import { computed } from 'vue'
import type {
  ComponentClass,
  FAIconName,
  VTabsAlign,
  VTabsItem,
} from '/@src/types/elements-ui'
import { useField } from 'vee-validate'

interface FormRatingProps {
  name: string
  label: string
  tabs: VTabsItem<T>[]
  tabIndex?: number
  iconLeft?: FAIconName
  iconRight?: FAIconName
  fieldClass?: ComponentClass
  align?: VTabsAlign
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<FormRatingProps>(), {
  type: 'text',
  tabIndex: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  fieldClass: undefined,
})

const { value, errors } = useField<number>(props.name)

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'danger'
  }
  return 'success'
})
</script>

<template>
  <VField
    :label
    :color
    :message="errors.join('\n')"
    :icon-left
    :icon-right
    :class="fieldClass"
  >
    <VTabs v-model:selected="value" :tabs type="rating" rounded :align />
  </VField>
</template>

<style scoped lang="scss"></style>
