<script lang="ts" setup>
import { computed } from 'vue'
import { useHead } from '@unhead/vue'
import ApexChart from 'vue3-apexcharts'
import type { ApexOptions } from 'apexcharts'
import { useDashboardStore } from '/@src/stores/dashboard'
import type { Rock } from '/@src/types/reporting'
import { inRange } from 'lodash'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import localeData from 'dayjs/plugin/localeData'
import { type Month, monthlyRockTargets } from '/@src/models/dashboard'
import 'dayjs/locale/en'
import { dayjsUTC } from '/@src/utils/date-formatter'
import { emojiPopper } from '/@src/utils/emoji-popper'

dayjs.extend(quarterOfYear)
dayjs.extend(localeData)
dayjs.locale('en')

definePage({
  meta: {
    requiresAdmin: true,
  },
})

useHead({
  title: 'Rock | Thuisgekookt Admin',
})

const dashboardStore = useDashboardStore()

const rockStatistics = ref<Rock>()

const currentQuarter = dayjsUTC().quarter()
const selectedQuarter = ref(currentQuarter)

const currentMonthAsIndex = dayjsUTC().month()
const currentMonthAsString = dayjs.months()[currentMonthAsIndex].toLowerCase() as Month

const quarters = ['Q1', 'Q2', 'Q3', 'Q4'].map((quarter, index) => {
  return {
    id: index + 1,
    name: quarter,
  }
})

const months = computed(() => {
  return monthlyRockTargets
    .map((month, index) => {
      return {
        key: month.key,
        index: index,
        name: month.month,
        target: month.target,
        quarter: month.quarter,
      }
    })
    .filter((month) => month.quarter === selectedQuarter.value)
})

const hasReachedRockTarget = computed<boolean>(() => {
  if (rockStatistics.value?.currentMatches && rockStatistics.value?.yearTarget) {
    return rockStatistics.value?.currentMatches >= rockStatistics.value?.yearTarget
  }

  return false
})

const nonMatchesAmount = computed<number>(() => {
  if (rockStatistics.value?.currentMatches && rockStatistics.value?.yearTarget) {
    return rockStatistics.value?.yearTarget - rockStatistics.value?.currentMatches
  }

  return 0
})

const pieChartTitle = computed<string>(() => {
  if (hasReachedRockTarget.value) {
    return 'Goed werk iedereen! We hebben ons doel behaald 🥳!'
  } else if (rockStatistics.value?.currentMatches && rockStatistics.value?.yearTarget) {
    if (rockStatistics.value?.yearTarget - rockStatistics.value?.currentMatches === 1) {
      return 'Er moet nog 1 match worden gemaakt om ons doel van 4100 matches te bereiken'
    } else {
      return `Er moeten nog ${
        rockStatistics.value?.yearTarget - rockStatistics.value?.currentMatches
      } matches worden gemaakt om ons doel van 4100 matches te bereiken`
    }
  }

  return ''
})

const pie = computed<ApexOptions>(() => {
  return {
    series: [rockStatistics.value?.currentMatches ?? 0, nonMatchesAmount.value],
    colors: ['#fef098', '#4b7967'],
    chart: {
      width: 760,
      type: 'donut',
    },
    title: {
      text: pieChartTitle.value,
    },
    labels: ['Huidige aantal matches', 'Nog te maken matches'],
    dataLabels: {
      formatter: (val, { seriesIndex, w }) => w.config.series[seriesIndex],
      style: {
        fontSize: '1.2rem',
        fontFamily: 'Montserrat, sans-serif',
        colors: ['#fff'],
      },
    },
    responsive: [
      {
        breakpoint: 1023,
        options: {
          chart: {
            width: 570,
          },
        },
      },
    ],
  } satisfies ApexOptions
})

const showConfetti = () => {
  nextTick(() => {
    emojiPopper.pop({
      location: {
        x: 0,
        y: 0,
      },
      centerOfPage: true,
    })
  })
}

const setInterimTarget = (interimTarget: number) => {
  if (rockStatistics.value?.currentMatches && rockStatistics.value?.yearTarget) {
    if (
      dashboardStore.currentInterimRockTarget !== interimTarget ||
      hasReachedRockTarget.value
    ) {
      if (rockStatistics.value?.currentMatches < rockStatistics.value?.yearTarget) {
        dashboardStore.currentInterimRockTarget = interimTarget
      } else {
        dashboardStore.currentInterimRockTarget = undefined
      }

      showConfetti()
    }
  }
}

onMounted(async () => {
  const result = await dashboardStore.getRockStatistics()

  if (result) {
    rockStatistics.value = result

    if (rockStatistics.value?.currentMatches && rockStatistics.value?.yearTarget) {
      for (let i = 250; i < rockStatistics.value?.yearTarget; i += 250) {
        if (inRange(rockStatistics.value?.currentMatches, i, i + 250)) {
          setInterimTarget(i)
        }
      }
    }
  }
})
</script>

<template>
  <div>
    <VCard>
      <template #title> (Minimaal) aantal matches per maand</template>

      <template #icon>
        <VSelect v-model="selectedQuarter" :options="quarters" />
      </template>

      <template #content>
        <table class="table is-striped is-fullwidth">
          <tbody>
            <tr class="columns">
              <th
                v-for="month in months"
                :key="month.key"
                class="has-text-centered column is-one-third"
                :class="
                  currentMonthAsString === month.key
                    ? 'has-text-weight-bold'
                    : 'has-text-weight-normal'
                "
              >
                {{ month.name }}
              </th>
            </tr>

            <tr class="columns">
              <td
                v-for="month in months"
                :key="month.key"
                class="has-text-centered column is-one-third"
              >
                <span v-if="currentMonthAsIndex >= month.index">
                  {{ rockStatistics?.[month.key] ?? '-' }} /
                </span>
                <span>{{ month.target }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </VCard>

    <div id="chart" class="is-flex is-justify-content-center is-align-items-center">
      <ApexChart
        :options="pie"
        :series="pie.series"
        :type="pie.chart?.type"
        :width="pie.chart?.width"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#chart {
  height: 100vh;
}
</style>
