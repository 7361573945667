import { definePlugin } from '/@src/utils/app-helper'
import { defineAsyncComponent } from 'vue'
import 'v-calendar/style.css'

export default definePlugin((vuero) => {
  const { app } = vuero

  app.component(
    'VCalendar',
    defineAsyncComponent({
      loader: () => import('v-calendar').then((mod) => mod.Calendar),
      delay: 0,
      suspensible: false,
    }),
  )

  app.component(
    'VDatePicker',
    defineAsyncComponent({
      loader: () => import('v-calendar').then((mod) => mod.DatePicker),
      delay: 0,
      suspensible: false,
    }),
  )
})
