import type { AbstractId } from '/@src/types/utils'
import type { TimeColumn } from '/@src/types/reporting'
import type { Dayjs } from 'dayjs'

export type FocusMunicipalityId = AbstractId<'focus-municipality'>

export enum FocusMunicipalityType {
  Municipality = 'municipality',
  Borough = 'borough',
  Neighborhood = 'neighborhood',
  District = 'district',
}

export interface FocusMunicipality {
  id: FocusMunicipalityId

  name: string

  activeFrom: Dayjs
  activeUntil: Dayjs
  isActive: boolean

  target: number
  isExpected: boolean
  isVoorproefje: boolean
  type: FocusMunicipalityType
  belongsToMunicipality: string | null
}

export interface FocusMunicipalityTargetStatusMinimal {
  totalTarget: number | null
  normalisedTarget: number | null
  matchExpected: number
  matchActual: number
}
export interface FocusMunicipalityTargetStatus
  extends FocusMunicipalityTargetStatusMinimal {
  isActive: boolean
  activeFrom: Dayjs
  activeUntil: Dayjs
}

export enum FocusMunicipalityTargetStatusEnum {
  Red = 0,
  Orange = 1,
  Bronze = 2,
  Silver = 3,
  Gold = 4,
}

export type TargetStatusHistory = Record<
  TimeColumn,
  Record<string, FocusMunicipalityTargetStatusMinimal>
>

export interface CreateFocusMunicipalityParams {
  name: string
  type: FocusMunicipalityType

  target: number

  activeFrom: Dayjs
  activeUntil: Dayjs

  isExpected: boolean
  isVoorproefje: boolean
}

export interface UpdateFocusMunicipalityParams {
  target: number

  activeFrom: Dayjs
  activeUntil: Dayjs

  isExpected: boolean
  isVoorproefje: boolean
}

export interface FocusMunicipalityChangesResponse {
  old: Record<string, FocusMunicipalityTargetStatus | undefined> | null
  new: Record<string, FocusMunicipalityTargetStatus | undefined> | null
}
