import { z } from 'zod'
import type { Dayjs } from 'dayjs'
import type { BulmaColor, FAIconName, TailwindColor } from '/@src/types/elements-ui'
import type { UserId } from '/@src/types/users'
import type { AbstractId } from '/@src/types/utils'
import type { AdminUserId } from '/@src/types/admin-users'

export type BugId = AbstractId<'bug'>

export interface SearchBugParams {
  query: string
  limit: number
  maxDaysOld: null
}

export interface BugParams {
  reproductionSteps: string
  expectedOutcome: string
  actualOutcome: string
  link: string
  userId: UserId
  linkToSlackMessage: string | null | undefined
}

export interface Bug {
  id: BugId
  userId: UserId
  developerId: AdminUserId | null
  reproductionSteps: string
  expectedOutcome: string
  actualOutcome: string
  link: string
  createdAt: Dayjs
  status: BugStatusOptionsType
  priority: BugPriorityOptionsType
  comments: string | null
  linkToSlackMessage: string | null
}

export interface BugForm {
  priority: BugPriorityOptionsType
  status: BugStatusOptionsType
  comments: string | null
}

export interface UpdateBugParams extends BugForm {
  developerId: AdminUserId
}

export enum BugSortOption {
  Date = 'date',
  Priority = 'priority',
  Developer = 'developer',
}

export enum BugAssignee {
  Aurel = 1,
  Jonas = 4,
  Unknown = -1,
}

export const bugAssigneeColor: Record<BugAssignee, TailwindColor> = {
  [BugAssignee.Aurel]: 'success',
  [BugAssignee.Jonas]: 'danger',
  [BugAssignee.Unknown]: 'info',
}

export enum BugStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Resolved = 'resolved',
  WontFix = 'wont_fix',
  NoBug = 'no_bug',
  BelongsToThirdParty = 'belongs_to_third_party',
  ResolvedByThirdParty = 'resolved_by_third_party',
  WaitingForConfirmation = 'waiting_for_confirmation',
  New = 'new',
}

export enum BugStatusCamelCase {
  Active = 'active',
  Cancelled = 'cancelled',
  Resolved = 'resolved',
  WontFix = 'wontFix',
  NoBug = 'noBug',
  BelongsToThirdParty = 'belongsToThirdParty',
  ResolvedByThirdParty = 'resolvedByThirdParty',
  WaitingForConfirmation = 'waitingForConfirmation',
  New = 'new',
}

export const bugStatusLabel: Record<BugStatus, string> = {
  [BugStatus.Active]: 'Actief',
  [BugStatus.Resolved]: 'Opgelost',
  [BugStatus.Cancelled]: 'Geannuleerd',
  [BugStatus.WontFix]: 'Wordt niet opgelost',
  [BugStatus.NoBug]: 'Geen bug',
  [BugStatus.BelongsToThirdParty]: 'Ligt bij 3e partij',
  [BugStatus.ResolvedByThirdParty]: 'Opgelost door 3e partij',
  [BugStatus.WaitingForConfirmation]: 'Wachten op bevestiging',
  [BugStatus.New]: 'Nieuw',
}

export const bugStatusColor: Record<BugStatus, TailwindColor> = {
  [BugStatus.Active]: 'danger',
  [BugStatus.Resolved]: 'success',
  [BugStatus.Cancelled]: 'info',
  [BugStatus.WontFix]: 'warning',
  [BugStatus.NoBug]: 'link',
  [BugStatus.BelongsToThirdParty]: 'primary',
  [BugStatus.ResolvedByThirdParty]: 'success',
  [BugStatus.WaitingForConfirmation]: 'dark',
  [BugStatus.New]: 'white',
}

export enum BugPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Unknown = 'unknown',
}

export const bugPriorityLabel: Record<BugPriority, string> = {
  [BugPriority.High]: 'Hoog',
  [BugPriority.Medium]: 'Normaal',
  [BugPriority.Low]: 'Laag',
  [BugPriority.Unknown]: 'Onbekend',
}

export const bugPriorityIcon: Record<BugPriority, FAIconName> = {
  [BugPriority.High]: 'fa-bugs',
  [BugPriority.Medium]: 'fa-bug',
  [BugPriority.Low]: 'fa-bug-slash',
  [BugPriority.Unknown]: 'fa-circle-question',
}

export const bugPriorityColor: Record<BugPriority, TailwindColor> = {
  [BugPriority.High]: 'danger',
  [BugPriority.Medium]: 'warning',
  [BugPriority.Low]: 'success',
  [BugPriority.Unknown]: 'white',
}

export const BugStatusOptions = z.nativeEnum(BugStatus)
export type BugStatusOptionsType = z.infer<typeof BugStatusOptions>

export const BugPriorityOptions = z.nativeEnum(BugPriority)
export type BugPriorityOptionsType = z.infer<typeof BugPriorityOptions>
