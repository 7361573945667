import { PressFieldEnum } from '/@src/models/types'
import type { AddressQuery } from '/@src/types/dashboard'
import { type UserId, UserRoleEnum, type UserTypeEnum } from '/@src/types/users'
import { Dayjs } from 'dayjs'
import type {
  AbstractId,
  Address,
  IsoDay,
  PaymentInformation,
  RecursivePartial,
  VogStatus,
} from '/@src/types/utils'
import { z } from 'zod'
import {
  type Frequency,
  type MatchRequestId,
  MatchRequestStatus,
} from '/@src/types/matchRequests'
import {
  type MatchMeal,
  type MatchMealId,
  type PaymentMethod,
  type PreprocessCancelMeal,
} from '/@src/types/meals'
import type { CookId } from '/@src/types/cooks'
import type { StopReason, StopReasonId } from '/@src/types/stop-reason'
import { VOGFilters } from '/@src/types/filters'
import { type TagConnection } from '/@src/types/tags'
import type { AdminTask } from '/@src/types/admin-tasks'
import type { FoodieId } from '/@src/types/foodies'
import { type TransportType } from '/@src/types/shared'
import type { MatchContactMoment, MatchContactMomentId } from '/@src/types/contact-moment'
import { ContactMoment } from '/@src/types/contact-moment'

export type MatchId = AbstractId<'match'>

export interface Match {
  id: MatchId
  createdAt: Dayjs
  updatedAt: Dayjs

  startDate: Dayjs
  endDate: Dayjs
  status: 0 | 1 | 2 // cancelled - active - paused
  isActive: boolean

  isSampleMeal: boolean
  isFlexible: boolean

  pauseFrom: Dayjs | null
  pauseUntil: Dayjs | null

  transport: TransportType
  recurring: Recurring
  daysOfWeek: IsoDay[]

  portions: number
  amountOfPeople: number

  price: number
  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod
  noCommission: boolean
  commissionType: CommissionType
  isPrepaid: boolean

  comments: string | null
  hasBrokenMeals: boolean
  upcomingCustomizableMealDate: Dayjs

  stopReason: StopReason
  stopReasonText?: string
}

export enum MatchStatus {
  Inactive = 0,
  Active = 1,
  Paused = 2,
}

export interface UserMatchOverviewResource {
  id: MatchId
  status: MatchStatus
  cookName: string
  foodieName: string
  matchmakerId: UserId | null
  municipality: string
  isSampleMeal: boolean
  isFlexible: boolean
  recurring: Recurring
  isActive: boolean
}

export interface NewMatchCook {
  id: CookId
  userId: UserId
  email: string
  phone: string
  phoneAlt: string | null
  fullName: string
  matchComments: string

  paymentInformation: PaymentInformation
  vog: VogStatus
  address: Address
}

export enum RecurringEnum {
  NotRecurring = 0,
  Weekly = 1,
  TwoWeekly = 2,
  ThreeWeekly = 3,
}

export const RecurringOptions = z.nativeEnum(RecurringEnum)
export type Recurring = z.infer<typeof RecurringOptions>

export interface NewMatchGeneralInfoStep {
  startDate: Dayjs
  endDate?: Dayjs
  recurring: Recurring
  daysOfWeek: IsoDay[]
  isSampleMeal: boolean
  isFlexible: boolean
}

export interface NewMatchMealStep {
  transport: TransportType
  portions: number
  amountOfPeople: number
  price: number
}

export const CommissionTypeOptions = z.enum([UserRoleEnum.Cook, UserRoleEnum.Foodie])
export type CommissionType = z.infer<typeof CommissionTypeOptions>

export interface NewMatchPaymentStep {
  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod
  isPrepaid?: boolean
  commissionType?: CommissionType
}

export interface NewMatchPaymentInfoStep {
  isOverride?: boolean
  bankaccountNumber?: string
  bankaccountName?: string
}

export interface NewMatchFinishedStep {
  sendConfirmationMail?: boolean
}

export type NewMatchFormIndex =
  | 'general'
  | 'meal'
  | 'payment'
  | 'payment-info-foodie'
  | 'payment-info-cook'
  | 'finished'

export interface NewMatch {
  matchRequestId: MatchRequestId
  cookId: CookId
  step: NewMatchFormIndex

  generalInfoStep: NewMatchGeneralInfoStep

  mealStep: NewMatchMealStep

  paymentStep: NewMatchPaymentStep

  paymentInfoStepCook: NewMatchPaymentInfoStep
  paymentInfoStepFoodie: NewMatchPaymentInfoStep
  finishedStep: NewMatchFinishedStep
}

export interface NewMatchParams {
  matchRequestId: MatchRequestId
  cookId: CookId
  startDate: Dayjs
  endDate?: Dayjs
  recurring: Recurring
  daysOfWeek: string[]
  transport: TransportType

  portions: number
  amountOfPeople: number

  price: number
  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod
  noCommission?: boolean
  commissionType?: CommissionType

  isPrepaid: boolean
  isSampleMeal: boolean
  isFlexible: boolean

  sendConfirmationMail: boolean

  foodiePaymentInfo: NewMatchPaymentInfoStep
  cookPaymentInfo: NewMatchPaymentInfoStep
}

export type BaseNewMatch = RecursivePartial<NewMatch>

export enum PaymentStatusSearchParam {
  PaymentInfoMissing = 'payment_info_missing',
  PaymentInfoComplete = 'payment_info_complete',
}

export enum DonationPaymentSearchParam {
  DoesntReceiveDonation = 'doesnt_receive_donation',
  DoesntGiveDonation = 'doesnt_give_donation',
  ReceivesDonation = 'receives_donation',
  GivesDonation = 'gives_donation',
}

export interface SearchMatchParams {
  query: string
  addressQuery: AddressQuery
  limit: number
  maxDaysOld: number | null
  matchmakerId: UserId
  impactAcceleratorId: UserId

  sampleMealFilter: number | null
  vogFilter: VOGFilters | null
  matchStatus: MatchStatus | null

  tags: number[]

  filterOnPress: {
    cook: PressFieldEnum[]
    foodie: PressFieldEnum[]
  }
  filterOnAmbassador: {
    cook: PressFieldEnum[]
    foodie: PressFieldEnum[]
  }
  paymentMethod: PaymentMethod | null
  paymentStatus: PaymentStatusSearchParam | null
  donationPaymentMethods: DonationPaymentSearchParam[]
}

export interface TableMatch {
  id: MatchId
  matchRequestId: MatchRequestId

  cookUserId: UserId
  cookName: string
  cookBlockedAt: Dayjs | null

  foodieUserId: UserId
  foodieName: string
  foodieBlockedAt: Dayjs | null

  matchmakerId: UserId

  status: MatchStatus
  isActive: boolean
  pauseUntil: Dayjs | null

  startDate: Dayjs
  endDate: Dayjs | null

  recurring: Recurring
  daysOfWeek: number[] | null
  transport: TransportType

  portions: number
  price: number
  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod
  commissionType: CommissionType | null

  isPrepaid: boolean
  isSampleMeal: boolean
  isFlexible: boolean
}

export interface MatchPaymentInfo {
  portions: number
  amountOfPeople: number
  price: number
  noCommission: boolean
}

export interface MatchResourceMatchRequest {
  id: MatchRequestId
  matchmakerId: UserId
  frequency: Frequency
  transport: TransportType
  status: MatchRequestStatus
  portions: number
  amountOfPeople: number
  timesAWeek: number
  daysPreferred: IsoDay[]
  daysBlocked: IsoDay[]
  comments: string | null
}

export interface MatchResourceUser {
  id: AbstractId<string>
  userId: UserId

  createdAt: Dayjs | null

  fullName: string
  birthdate: Dayjs
  email: string
  phone: string
  phoneAlt: string | null
  avatar: string | null
  role: UserTypeEnum
  isPrepaid: boolean
  balance: number
  activeMatchCount: number

  isDeleted: boolean
  blockedAt: Dayjs | null

  address: Address
  tags: TagConnection[]
  paymentInformation: PaymentInformation
}

export interface MatchResourceFoodie extends MatchResourceUser {
  id: FoodieId
  role: UserRoleEnum.Foodie
}

export interface MatchResourceCook extends MatchResourceUser {
  id: CookId
  role: UserRoleEnum.Cook
  matchComments: string | null
}

export interface DetailMatch extends Match {
  meals: MatchMeal[]
  latestMealDate: Dayjs | null

  matchRequest: MatchResourceMatchRequest
  foodie: MatchResourceFoodie
  cook: MatchResourceCook
  tags: TagConnection[]
  nextTask: AdminTask | null
}

// Added `Resource` this time because else the name can be confusing
export interface MatchMonitoringResource {
  id: MatchId
  matchRequestId: MatchRequestId

  matchmakerId: UserId
  assignedUserId: UserId | null

  startDate: Dayjs
  endDate: Dayjs | null

  status: MatchStatus
  isFlexible: boolean

  comments: string | null

  pauseFrom: Dayjs | null
  pauseUntil: Dayjs | null

  latestContactMomentAt: Dayjs
  nextContactMoment: {
    id: MatchContactMomentId | null
    matchId: MatchId
    comments: string | null
    type: ContactMoment | `${number}-months`
  }

  cook: {
    id: CookId
    userId: UserId
    fullName: string
    municipality: string
  }

  foodie: {
    id: FoodieId
    userId: UserId
    fullName: string
    municipality: string
  }
}

export interface MatchMonitoringPageData {
  openMatches: MatchMonitoringResource[]
  assignedMatches: MatchMonitoringResource[]
  matchesThisWeek: number
}

export interface MatchMonitoringWebsocketUpdate {
  matchId: MatchId
  userId: UserId
  matchesThisWeek: number
}

export interface ExternalMatchContactMoment {
  id: MatchId
  startDate: Dayjs
  endDate: Dayjs | null
  status: MatchStatus

  matchmaker: string

  comments: string | null

  foodie: {
    id: FoodieId
    userId: UserId
    fullName: string
    phone: string
    phoneAlt: string | null
    birthdate: Dayjs | null
    tags: TagConnection[]
  }
  cook: {
    id: CookId
    userId: UserId
    fullName: string
    phone: string
    phoneAlt: string | null
    birthdate: Dayjs | null
    tags: TagConnection[]
  }

  latestContactMomentAt: Dayjs | null
  contactMoment: MatchContactMoment
}

export type MatchAutomaticUpdateField = 'transport'
export type MatchAutomaticUpdateParams = {
  id: MatchId
  field: MatchAutomaticUpdateField
}

type MatchFieldUpdateComments = {
  field: 'comments'
  value: string
}

type MatchFieldUpdatePauseUntil = {
  field: 'pause-until'
  value: Dayjs
}

export type MatchUpdateFieldOptions =
  | MatchFieldUpdateComments
  | MatchFieldUpdatePauseUntil
export type MatchFieldParams = {
  id: MatchId
} & MatchUpdateFieldOptions

export interface UpdatePaymentInformation {
  paymentInformation: PaymentInformation
  isPrepaid: boolean
  needsPaymentInfo: boolean
}

export interface PriceSettingsForm {
  price: number
  commissionType: CommissionType
  portions: number
  amountOfPeople: number
  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod
}

export type PriceSettingsParams = PriceSettingsForm & {
  id: MatchId
} & MatchStartChangesDateForm

export interface PlanningSettingsForm {
  recurring: Recurring
  daysOfWeek: IsoDay[]
}

export type PlanningSettingsParams = PlanningSettingsForm & {
  id: MatchId
} & MatchStartChangesDateForm

export interface MatchStartChangesDateForm {
  modifyFrom: Dayjs
}

export interface CreateMealsInFutureMatchParams {
  endDate: Dayjs
  cancelDates: Dayjs[]
}

export type PauseMatchFormIndex = 'general' | 'check-meals' | 'finished'

export interface PauseMatchGeneralStep {
  pauseFrom: Dayjs | null
  pauseUntil: Dayjs | null
}

export type StopMatchType = 'stopMatch' | 'pauseMatch'

export interface PauseMatch {
  id: MatchId
  step: PauseMatchFormIndex

  generalStep: PauseMatchGeneralStep
  checkMealsStep: CheckCancelledMealsStep
}

export type BasePauseMatch = RecursivePartial<PauseMatch>

export interface StopMatchStartData {
  cook: {
    hasHadRecentDonation: boolean
  }
  foodie: {
    hasHadRecentDonation: boolean
  }
}

export type StopMatchFormIndex =
  | 'general'
  | 'check-meals'
  | 'payment-info-foodie'
  | 'payment-info-cook'
  | 'finished'

export type StopMatchSkipUserType = 'more-active-matches' | 'no-balance-change' | false

export interface StopMatchGeneralStep {
  endDate: Dayjs
  stopReasonId: StopReasonId
  stopReasonText?: string
  availableForMatch: boolean
  unavailableForMatchesUntil?: Dayjs
  cookComments: string
}

export interface CheckCancelledMealsStep {
  correction: PreprocessCancelMeal[]
  modifiable: PreprocessCancelMeal[]
  futureNonCancelled: PreprocessCancelMeal[]
}

export enum StopMatchPaymentTypeEnum {
  AddToBatch = 'add-to-batch',
  AddToBatchLater = 'add-to-batch-later',
  Donation = 'donation',
}

export const StopMatchPaymentTypeOptions = z.nativeEnum(StopMatchPaymentTypeEnum)
export type StopMatchPaymentType = z.infer<typeof StopMatchPaymentTypeOptions>

export interface StopMatchPaymentInfoStep {
  paymentType: StopMatchPaymentType
  bankaccountName: string | null
  bankaccountNumber: string | null

  donationWalletComment: string | null
}

export interface StopMatchCookParams extends StopMatchPaymentInfoStep {
  availableForMatch: boolean
  unavailableForMatchesUntil?: Dayjs
  cookComments: string
}

export interface StopMatchFinishedStep {
  sendConfirmationMail: boolean
}

export interface StopMatch {
  id: MatchId
  step: StopMatchFormIndex

  generalStep: StopMatchGeneralStep
  checkMealsStep: CheckCancelledMealsStep
  paymentInfoFoodieStep: StopMatchPaymentInfoStep
  paymentInfoCookStep: StopMatchPaymentInfoStep
  finishedStep: StopMatchFinishedStep
}

export type BaseStopMatch = RecursivePartial<StopMatch>

export interface PreprocessStopMatchParams {
  id: MatchId
  cancelFrom: Dayjs
  cancelUntil?: Dayjs | null
  mealIds?: MatchMealId[]
}

export interface PreprocessCancelMealUserChanges {
  amount: number
  price: number
  newBalance: number
}

export interface PauseMatchParams {
  id: MatchId
  pauseFrom: Dayjs | null
  pauseUntil: Dayjs | null
}

export interface StopMatchParams {
  id: MatchId

  endDate: Dayjs
  stopReasonId: StopReasonId
  stopReasonText?: string

  sendConfirmationMail: boolean

  cook: StopMatchCookParams
  foodie?: StopMatchPaymentInfoStep
}

export enum MatchSortOption {
  NEW_TO_OLD = 'new_to_old',
  OLD_TO_NEW = 'old_to_new',
}
