<script setup lang="ts">
interface VTagsProps {
  addons?: boolean
  noWrap?: boolean
  centered?: boolean
  overrideClickable?: boolean
  forceClickable?: boolean
}

const props = withDefaults(defineProps<VTagsProps>(), {
  addons: true,
  noWrap: true,
})

const attrs = useAttrs()
const computedClickable = computed(
  () =>
    ((!!attrs.onClick && !props.overrideClickable) || props.forceClickable) &&
    'tw-cursor-pointer',
)
</script>

<template>
  <div
    class="tw-tags"
    :class="[
      computedClickable,
      addons && 'has-addons',
      noWrap && 'tw-flex-nowrap',
      centered && 'tw-justify-center',
    ]"
  >
    <slot></slot>
  </div>
</template>

<style scoped></style>
