import { useAuthStore } from '/@src/stores/auth'
import dayjs from 'dayjs'
import { type MaybeRefOrGetter, type Ref, type WritableComputedRef } from 'vue'
import { formatDateAndTime } from '/@src/utils/date-formatter'
import type { AbstractId } from '/@src/types/utils'

export function useCommentAutomation<
  const T extends AbstractId<any> | undefined = undefined,
>(
  commentString:
    | WritableComputedRef<string | undefined | null>
    | Ref<string | undefined | null>,
  standardInfoId?: MaybeRefOrGetter<T | undefined>,
  standardInfoName?: MaybeRefOrGetter<string | undefined>,
) {
  const authStore = useAuthStore()

  const originalValue = toValue(commentString)
  const hasAddedStandardInfo = ref<boolean>(false)

  watch(
    () => toValue(commentString),
    (newValue) => {
      if (newValue === originalValue || (!newValue && !originalValue)) {
        hasAddedStandardInfo.value = false
      }
    },
  )

  const standardCommentString = computed(() => {
    const dateString = formatDateAndTime(dayjs())

    const authUser = authStore.authUser
    if (!authUser) {
      return 'Onbekend'
    }

    const authUserFullName = `${authUser.firstName} ${authUser.lastName}`

    return `${dateString} ${authUserFullName}`
  })

  const getUserCommentString = (id: T, name: string) => {
    return `${standardCommentString.value} ${id} (${name})`
  }

  const setInfoString = (infoString: string, additionalText?: string) => {
    if (commentString.value?.includes('<p>')) {
      commentString.value = commentString.value!.replace(
        '<p>',
        `<p>${infoString}: ${additionalText ?? ''}<br>`,
      )
    } else {
      commentString.value = `<p>${infoString}: ${additionalText ?? ''}<br></p> ${
        commentString.value
      }`
    }
  }

  const addStandardInfo = (additionalText?: string | unknown) => {
    if (!hasAddedStandardInfo.value) {
      hasAddedStandardInfo.value = true

      if (!commentString.value) {
        commentString.value = ''
      }

      // the @focus emit from the editor sends an object, so check if it really is a string
      let actualAdditionalText: string | undefined
      if (additionalText && typeof additionalText === 'string') {
        actualAdditionalText = additionalText
      }

      const standardId = toValue(standardInfoId)
      const standardName = toValue(standardInfoName)

      if (standardId && standardName) {
        const infoString = getUserCommentString(standardId, standardName)
        setInfoString(infoString, actualAdditionalText)
      } else {
        setInfoString(standardCommentString.value, actualAdditionalText)
      }
    }
  }

  const addUserInfo = (id: T, name: string) => {
    if (!hasAddedStandardInfo.value) {
      hasAddedStandardInfo.value = true

      if (!commentString.value) {
        commentString.value = ''
      }

      const infoString = getUserCommentString(id, name)
      setInfoString(infoString)
    }
  }

  return {
    hasAddedStandardInfo,
    getStandardCommentString: standardCommentString,
    getCookCommentString: getUserCommentString,
    addStandardInfo,
    addUserInfo,
  }
}
