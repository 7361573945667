<script setup lang="ts">
import { computed, type MaybeRefOrGetter, toValue, useTemplateRef } from 'vue'
import { useDarkMode } from '/@src/stores/darkmode'
import { useModal } from '/@src/composable/useModal'
import { useMagicKeys, whenever } from '@vueuse/core'
import { Toaster } from 'vue-sonner'
import type { RouteLocationNormalized } from 'vue-router/auto'
import { getRole } from '/@src/utils/helpers'
import { useAuthStore } from '/@src/stores/auth'
import {
  AnalysisNavbarIcon,
  BugNavbarIcon,
  CookIcon,
  DashboardNavbarIcon,
  DonationsNavbarIcon,
  EventsNavbarIcon,
  ExperimentalIcon,
  FoodieIcon,
  MatchesNavbarIcon,
  PaymentsNavbarIcon,
  ReportingNavbarIcon,
  RockNavbarIcon,
  SettingsNavbarIcon,
  SurveyNavbarIcon,
  TasksNavbarIcon,
  UserIcon,
  VOGNavbarIcon,
} from '/@src/models/standardIcons'
import { UserRoleEnum } from '/@src/types/users'
import { useGlobalsStore } from '/@src/stores/global'
import {
  devIsInProdEnvironment,
  isDeveloper,
  isFinance,
  isHelpdesker,
  isLeadMatchmaker,
  mobileOperatingSystem,
} from '/@src/utils/entity-checks'
import { useTasksStore } from '/@src/stores/tasks'
import type { FAIconName, FAIconPack } from '/@src/types/elements-ui'
import type { RouteLocationRaw } from 'vue-router'

const globalsStore = useGlobalsStore()
const authStore = useAuthStore()
const darkModeStore = useDarkMode()
const taskStore = useTasksStore()

const route = useRoute()

const dashboardPage = useTemplateRef('dashboardPage')
const dashboardOverlay = computed(() =>
  dashboardPage.value?.querySelector('#dashboard-loadout-overlay'),
)

const showDashboard = ref(false)
watch(
  () => taskStore.taskModalParams.open,
  (newValue) => {
    if (newValue && showDashboard.value) {
      showDashboard.value = false
    }
  },
)

const isDark = computed(() => darkModeStore.isDark)

const isAdmin = computed(() => authStore.hasRole(UserRoleEnum.Admin))

const hasReportingRoleParam = computed(
  () =>
    authStore.user &&
    !authStore.hasRole(UserRoleEnum.Admin) &&
    getRole(authStore.user, UserRoleEnum.Reporting)?.param,
)

const isExternal = computed(
  () =>
    authStore.hasRole(UserRoleEnum.External) && !authStore.hasRole(UserRoleEnum.Admin),
)

const hideElements = computed(() => !!hasReportingRoleParam.value || isExternal.value)

const {
  modalIsOpen: errorReportingModalIsOpen,
  openModal: openErrorReportingModal,
  closeModal: closeErrorReportingModal,
} = useModal()

const { h_e_l_p } = useMagicKeys()
whenever(h_e_l_p, openErrorReportingModal)

const {
  modalIsOpen: trainCostsModalIsOpen,
  openModal: openTrainCostsModal,
  closeModal: closeTrainCostsModal,
} = useModal()

const {
  modalIsOpen: enpsModalIsOpen,
  openModal: openEnpsModal,
  closeModal: closeEnpsModal,
} = useModal()

const { modalIsOpen: searchModalIsOpen, openModal: openSearchModal } = useModal()

const fullPageClasses = computed(() => [
  route.fullPath.startsWith('/reporting') && 'is-full-page',
  route.fullPath.startsWith('/reporting') &&
    !route.fullPath.startsWith('/reporting/statistics') &&
    !route.name.startsWith('/reporting/municipalities/[municipality]/report') &&
    'is-full-reporting-page',
])

const closeDashboard = (event: Event) => {
  if (dashboardOverlay.value && !dashboardOverlay.value?.contains(event.target as Node)) {
    showDashboard.value = false
  }
}

const getTailwindClasses = (active?: boolean) => {
  if (!!authStore.adminUser) {
    return `tw-text-danger-text hover:tw-text-danger-text hover:!tw-border-dark-text ${active ? '!tw-border-dark-text' : ''}`
  } else if (devIsInProdEnvironment.value) {
    return `tw-text-primary-text hover:tw-text-primary-text hover:!tw-border-text ${active ? '!tw-border-text' : ''}`
  } else if (isDark.value) {
    return active
      ? 'tw-bg-background-darkest tw-border-[0.5px] !tw-border-border-dark-light tw-text-primary hover:tw-text-primary'
      : 'tw-text-text-navbar hover:tw-bg-background-darkest hover:tw-text-text-navbar'
  } else {
    return active
      ? 'tw-border-[0.5px] !tw-border-border-gray-dark tw-shadow tw-text-primary hover:tw-text-primary'
      : 'tw-text-text-navbar hover:tw-bg-gray-light-800 hover:tw-text-text-navbar'
  }
}

interface NavbarTab {
  to: Partial<RouteLocationNormalized>
  name: MaybeRefOrGetter<string>
  icon: MaybeRefOrGetter<FAIconName>
  iconPack?: MaybeRefOrGetter<FAIconPack | undefined>
  badge?: () => string | undefined
  enabled?: MaybeRefOrGetter<boolean | undefined>
}

const tabs: NavbarTab[] = [
  {
    to: { name: '/to-do/' },
    name: 'Herin­neringen',
    icon: TasksNavbarIcon,
    badge: () =>
      taskStore.activeTasksForToday.length === 0
        ? undefined
        : taskStore.activeTasksForToday.length.toString(),
  },
  {
    to: { name: '/dashboard/' },
    name: 'Dashboard',
    icon: DashboardNavbarIcon,
    enabled: true,
  },
  {
    to: { name: '/reporting' },
    name: 'Rapportage',
    icon: ReportingNavbarIcon,
    enabled: true,
  },
  {
    to: { name: '/analysis' },
    name: 'Analyse',
    icon: AnalysisNavbarIcon,
  },
  {
    to: { name: '/matching/' },
    name: 'Maaltijd­zoekers',
    icon: FoodieIcon,
  },
  {
    to: { name: '/matches/' },
    name: 'Matches',
    icon: MatchesNavbarIcon,
  },
  {
    to: { name: '/cooks/' },
    name: 'Thuiskoks',
    icon: CookIcon,
  },
  {
    to: { name: '/users/' },
    name: 'Gebruikers',
    icon: UserIcon,
  },
  {
    to: { name: '/vogs' },
    name: 'VOGs',
    icon: VOGNavbarIcon,
  },
  {
    to: { name: '/surveys/' },
    name: 'Vragen­lijsten',
    icon: SurveyNavbarIcon,
  },
  {
    to: { name: '/events/' },
    name: 'Evenementen',
    icon: EventsNavbarIcon,
  },
  {
    to: { name: '/payments/' },
    name: 'Betalingen',
    icon: PaymentsNavbarIcon,
    enabled: () => isFinance.value || isLeadMatchmaker.value,
  },
  {
    to: { name: '/donations' },
    name: 'Donaties',
    icon: DonationsNavbarIcon,
    enabled: () =>
      isFinance.value || isHelpdesker.value || authStore.hasRole(UserRoleEnum.Strategy),
  },
  {
    to: { name: '/finances' },
    name: 'Financiën',
    icon: ExperimentalIcon,
    enabled: isFinance,
  },
  {
    to: { name: '/settings/' },
    name: 'Instellingen',
    icon: SettingsNavbarIcon,
  },
  {
    to: { name: '/rock' },
    name: 'Rock',
    icon: RockNavbarIcon,
  },
  {
    to: { name: '/bug-report' },
    name: 'Bug Report',
    icon: BugNavbarIcon,
    enabled: isDeveloper,
  },
]

const mobileTabs: NavbarTab[] = [
  {
    to: { name: '/apps' },
    name: 'Apps',
    icon: () => {
      if (mobileOperatingSystem.value === 'Android') {
        return 'fa-google-play'
      } else if (mobileOperatingSystem.value === 'iOS') {
        return 'fa-app-store'
      }
      return 'fa-mobile-button'
    },
    iconPack: () => {
      if (
        mobileOperatingSystem.value === 'Android' ||
        mobileOperatingSystem.value === 'iOS'
      ) {
        return 'fa-brands'
      }
      return 'fas'
    },
  },
]
</script>

<template>
  <div>
    <div class="app-overlay"></div>

    <template v-if="!isExternal">
      <div v-if="showDashboard" class="global-dashboard-blur-overlay" />
      <Transition>
        <div
          ref="dashboardPage"
          class="global-dashboard-overlay"
          v-show="showDashboard"
          @click="closeDashboard"
        >
          <VIcon
            class="close-button"
            icon="fa-x"
            size="large"
            color="white"
            @click="showDashboard = false"
          />
          <DashboardPage is-overlay />
        </div>
      </Transition>
    </template>

    <Navbar>
      <template #bottom-bar>
        <Globals :hide-bars="hideElements" />
      </template>

      <template #title>
        <NavBarLogo />
      </template>

      <template #toolbar>
        <div class="toolbar">
          <VOnboarding />

          <VIcon
            v-if="!isExternal"
            class="mx-2"
            :icon="DashboardNavbarIcon"
            size="medium"
            :color="isDark ? 'white' : 'dark'"
            @click="showDashboard = !showDashboard"
          />

          <TasksToolbar v-if="!hasReportingRoleParam && !isExternal" />

          <div
            class="toolbar-link is-clickable"
            @click.prevent="darkModeStore.toggleDarkMode"
          >
            <label class="dark-mode">
              <input
                type="checkbox"
                :checked="!isDark"
                @change="darkModeStore.toggleDarkMode"
              />
              <span></span>
            </label>
          </div>

          <AdminUserDropdown
            :hide-options="hideElements"
            @error="openErrorReportingModal"
            @train="openTrainCostsModal"
            @search="openSearchModal"
            @enps="openEnpsModal"
          />
        </div>
      </template>

      <template #links>
        <div v-if="!hasReportingRoleParam && !isExternal" class="tw-navbar-menu">
          <template v-for="tab in tabs" :key="`navbar-tab-${tab.name}`">
            <RouterLink
              v-if="tab.enabled ? toValue(tab.enabled) : isAdmin"
              :to="tab.to as RouteLocationRaw"
              class="tw-navbar-item"
              :class="
                getTailwindClasses(
                  tab.to.name && route.path.startsWith(tab.to.name.slice(0, -1)),
                )
              "
            >
              <span v-if="tab.badge && tab.badge()" class="tw-navbar-item-badge">
                {{ tab.badge() }}
              </span>
              <VIcon
                class="mb-1"
                :icon="toValue(tab.icon)"
                icon-pack="fas"
                size="medium"
                disable-icon-class
              />
              <span class="tw-navbar-item-name">{{ toValue(tab.name) }}</span>
            </RouterLink>
          </template>
        </div>
        <div v-else></div>
      </template>

      <template #mobile-links>
        <div v-if="!hasReportingRoleParam && !isExternal" class="tw-navbar-menu">
          <template v-for="tab in mobileTabs" :key="`navbar-tab-${tab.name}`">
            <RouterLink
              v-if="tab.enabled ? toValue(tab.enabled) : isAdmin"
              :to="tab.to as RouteLocationRaw"
              class="tw-navbar-item"
              :class="
                getTailwindClasses(
                  tab.to.name && route.path.startsWith(tab.to.name.slice(0, -1)),
                )
              "
            >
              <span v-if="tab.badge && tab.badge()" class="tw-navbar-item-badge">
                {{ tab.badge() }}
              </span>
              <VIcon
                class="mb-1"
                :icon="toValue(tab.icon)"
                :icon-pack="tab.iconPack ? toValue(tab.iconPack) : 'fas'"
                size="medium"
                disable-icon-class
              />
              <span class="tw-navbar-item-name">{{ toValue(tab.name) }}</span>
            </RouterLink>
          </template>
        </div>
      </template>
    </Navbar>

    <div class="view-wrapper has-top-nav" :class="fullPageClasses">
      <div class="page-content-wrapper" :class="fullPageClasses">
        <div class="page-content is-relative">
          <div class="is-navbar-lg">
            <slot v-if="globalsStore.hasLoaded" />
          </div>
        </div>
      </div>
    </div>

    <ErrorReportingModal
      v-model:open="errorReportingModalIsOpen"
      @close="closeErrorReportingModal"
    />

    <TrainCostsModal v-model:open="trainCostsModalIsOpen" @close="closeTrainCostsModal" />

    <!--     Only load this after the admin user has been loaded in so the last workday check can be done -->
    <EnpsModal
      v-if="globalsStore.hasLoaded"
      v-model:open="enpsModalIsOpen"
      @close="closeEnpsModal"
    />

    <GlobalSearchModal v-model:open="searchModalIsOpen" />

    <Toaster
      rich-colors
      close-button
      :theme="darkModeStore.isDark ? 'dark' : 'light'"
      :toast-options="{ style: { padding: '16px !important' } }"
    />
  </div>
</template>

<style scoped lang="scss">
@media screen and (width <= 1216px) {
  ::v-deep(.search-tag) {
    .icon-text {
      span:last-child {
        display: none;
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
