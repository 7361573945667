import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  CreateFocusMunicipalityParams,
  FocusMunicipality,
  FocusMunicipalityId,
  FocusMunicipalityTargetStatus,
  FocusMunicipalityTargetStatusMinimal,
  UpdateFocusMunicipalityParams,
} from '/@src/types/focus-municipalities'
import { FocusMunicipalityTargetStatusEnum } from '/@src/types/focus-municipalities'
import type { GroupOption, GroupOptions, OptionsMap } from '/@src/types/elements-ui'
import { toCapitalizedWords } from '/@src/utils/helpers'
import {
  getMunicipalityId,
  getMunicipalityName,
  getTargetStatus,
} from '/@src/mapping/focus-municipalities'
import { useGlobalsStore } from '/@src/stores/global'
import { toast } from 'vue-sonner'
import { camelCase, fromPairs, groupBy, uniqBy } from 'lodash'
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { ApiResponse } from '/@src/types/utils'

const api = useApi()

export const useFocusMunicipalitiesStore = defineStore(
  'focusMunicipalities',
  () => {
    const data = ref<FocusMunicipality[]>([])

    const targetStatus = ref<Record<string, FocusMunicipalityTargetStatus>>()

    const municipalityMemo = ref<Record<string, boolean>>({})
    watch(data, () => (municipalityMemo.value = {}), { deep: true })

    useWebSocket({
      event: 'FocusMunicipality\\FocusMunicipalityTargetStatusUpdated',
      channel: 'tg-admin-channel-focus-municipalities',
      callback: (
        newTargetStatus: ApiResponse<Record<string, FocusMunicipalityTargetStatus>>,
      ) => {
        targetStatus.value = newTargetStatus.data
      },
    })

    const addMunicipality = async (
      municipality: CreateFocusMunicipalityParams,
    ): Promise<boolean> => {
      try {
        const result = await api.put('admin/focus-municipalities/', municipality)
        data.value.push(result.data.data)
        toast.success('Focus-gemeente toegevoegd')
        return true
      } catch {
        toast.error('Focus-gemeente toevoegen mislukt')
        return false
      }
    }

    const updateMunicipality = async (
      municipalityId: FocusMunicipalityId,
      params: UpdateFocusMunicipalityParams,
    ): Promise<boolean> => {
      try {
        await api.put(`admin/focus-municipalities/${municipalityId}`, params)
        toast.success('Focus-gemeente is aangepast')
        return true
      } catch {
        toast.error('Focus-gemeente aanpassen mislukt')
        return false
      }
    }

    const globalStore = useGlobalsStore()

    const deleteMunicipality = async (
      municipalityId: FocusMunicipalityId,
    ): Promise<boolean> => {
      try {
        await api.delete(`admin/focus-municipalities/${municipalityId}`)

        data.value = data.value.filter((item) => item.id !== municipalityId)
        return true
      } catch {
        toast.error('Focus-gemeente verwijderen mislukt')
        return false
      }
    }

    const activeFocusMunicipalities = computed<FocusMunicipality[]>(() =>
      data.value
        .filter((m) => m.isActive)
        .toSorted((a, b) => getMunicipalityName(a).localeCompare(getMunicipalityName(b))),
    )

    const municipalityOptionsMap = computed((): OptionsMap[] => {
      if (activeFocusMunicipalities.value.length === 0) {
        return []
      }

      const municipalities = globalStore.filteredAddressData.municipality

      const municipalityOptions = municipalities.map((municipality) => {
        return {
          id: municipality.toLowerCase(),
          name: toCapitalizedWords(municipality),
        }
      })

      const focusMunicipalityOptions = activeFocusMunicipalities.value.map(
        (municipality) => {
          let capitalizedName: string
          if (municipality.name === "'s-gravenhage") {
            capitalizedName = "'s-Gravenhage"
          } else if (municipality.name === "'s-hertogenbosch") {
            capitalizedName = "'s-Hertogenbosch"
          } else {
            capitalizedName = toCapitalizedWords(getMunicipalityName(municipality))
          }

          return {
            id: getMunicipalityId(municipality),
            name: capitalizedName,
          }
        },
      )

      return uniqBy([...municipalityOptions, ...focusMunicipalityOptions], 'id').sort(
        (a, b) => a.id.localeCompare(b.id),
      )
    })

    const municipalityGroupOptionsMap = computed((): GroupOptions[] => {
      if (activeFocusMunicipalities.value.length === 0) {
        return []
      }

      const focusMunicipalitiesOptions: GroupOption[] = activeFocusMunicipalities.value
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map((municipality) => {
          let capitalizedName: string
          if (municipality.name === "'s-gravenhage") {
            capitalizedName = "'s-Gravenhage"
          } else if (municipality.name === "'s-hertogenbosch") {
            capitalizedName = "'s-Hertogenbosch"
          } else {
            capitalizedName = toCapitalizedWords(getMunicipalityName(municipality))
          }

          return {
            name: getMunicipalityId(municipality),
            label: capitalizedName,
            category: 'Focus-gemeentes',
          }
        })

      const municipalities: GroupOption[] = globalStore.fullAddressData.municipality
        .filter(
          (municipality) =>
            !activeFocusMunicipalities.value.find(
              (m) => m.name.toLowerCase() === municipality.toLowerCase(),
            ),
        )
        .sort((a, b) => a.localeCompare(b))
        .map((municipality) => {
          return {
            name: municipality,
            label: municipality,
            category: 'Overige gemeentes',
          }
        })

      return [
        {
          label: 'Focus-gemeentes',
          values: focusMunicipalitiesOptions,
        },
        {
          label: 'Overige gemeentes',
          values: municipalities,
        },
      ]
    })

    const isFocusMunicipality = (
      municipality?: string,
      includeExpected: boolean = true,
    ): boolean => {
      if (!municipality) {
        return false
      }

      if (includeExpected && municipality in municipalityMemo.value) {
        return municipalityMemo.value[municipality]
      }

      const municipalities = useFocusMunicipalitiesStore().activeFocusMunicipalities

      if (!municipalities || municipality.length === 0) {
        return false
      }

      const foundMunicipality = municipalities.find(
        (m) => getMunicipalityId(m) === municipality.toLowerCase(),
      )

      if (foundMunicipality) {
        municipalityMemo.value[municipality] = true
        if (includeExpected) {
          return true
        }
        return !foundMunicipality.isExpected
      }

      municipalityMemo.value[municipality] = false
      return false
    }

    const getFocusMunicipalitiesWithYear = (
      year: string | number,
    ): FocusMunicipality[] => {
      return getFocusMunicipalitiesWithYears([year])
    }

    const getFocusMunicipalitiesWithYears = (
      years: (string | number)[],
    ): FocusMunicipality[] => {
      return years.flatMap((year: number | string) => {
        if (typeof year === 'string') {
          year = parseInt(year)
        }

        const municipalities = data.value.filter(
          (m) => m.activeFrom.year() <= year && m.activeUntil.year() >= year,
        )

        return municipalities.toSorted((a, b) =>
          getMunicipalityName(a).localeCompare(getMunicipalityName(b)),
        )
      })
    }

    const voorproefjeFocusMunicipalities = computed<FocusMunicipality[]>(() => {
      return activeFocusMunicipalities.value.filter((m) => m.isVoorproefje)
    })

    const municipalityIsVoorproefje = (
      municipality: string | undefined | null,
    ): boolean => {
      if (!municipality) {
        return false
      }
      return voorproefjeFocusMunicipalities.value.some(
        (m) => m.name === municipality.toLowerCase(),
      )
    }

    interface GroupedMunicipalityTargetStatus {
      red: FocusMunicipalityTargetStatusMinimal | undefined
      orange: FocusMunicipalityTargetStatusMinimal | undefined
      green: FocusMunicipalityTargetStatusMinimal | undefined
    }

    const groupedMunicipalityTargetStatuses = computed<GroupedMunicipalityTargetStatus>(
      () => {
        if (!targetStatus.value) {
          return {
            red: {
              totalTarget: 0,
              normalisedTarget: 0,
              matchExpected: 0,
              matchActual: 0,
            },
            orange: {
              totalTarget: 0,
              normalisedTarget: 0,
              matchExpected: 0,
              matchActual: 0,
            },
            green: {
              totalTarget: 0,
              normalisedTarget: 0,
              matchExpected: 0,
              matchActual: 0,
            },
          }
        }

        const filteredMunicipalities = Object.values(targetStatus.value).filter(
          (t) => t.isActive,
        )

        const grouped = groupBy(filteredMunicipalities, (target) => {
          const status = getTargetStatus(target)
          if (status === FocusMunicipalityTargetStatusEnum.Red) {
            return 'red'
          } else if (status === FocusMunicipalityTargetStatusEnum.Orange) {
            return 'orange'
          } else {
            return 'green'
          }
        })

        const reducedGroups = Object.entries(grouped).map(([type, targets]) => {
          const reducedTargets = targets.reduce<FocusMunicipalityTargetStatusMinimal>(
            (agg, curr) => {
              return {
                totalTarget: (curr.totalTarget ?? 0) + (agg.totalTarget ?? 0),
                normalisedTarget:
                  (curr.normalisedTarget ?? 0) + (agg.normalisedTarget ?? 0),
                matchExpected: curr.matchExpected + agg.matchExpected,
                matchActual: curr.matchActual + agg.matchActual,
              }
            },
            { totalTarget: 0, normalisedTarget: 0, matchExpected: 0, matchActual: 0 },
          )

          return [type, reducedTargets]
        })

        return fromPairs(reducedGroups) as GroupedMunicipalityTargetStatus
      },
    )

    const redFocusMunicipalities = computed<string[]>(() => {
      if (!targetStatus.value) {
        return []
      }
      return Object.entries(targetStatus.value)
        .filter(([_, m]) => {
          if (!m.isActive) {
            return false
          }
          const targetStatus = getTargetStatus(m)
          return targetStatus === FocusMunicipalityTargetStatusEnum.Red
        })
        .map(([m, _]) => camelCase(m))
    })

    const focusMunicipalityTargetMap = computed<
      Record<string, FocusMunicipalityTargetStatusEnum> | undefined
    >(() => {
      if (!targetStatus.value) {
        return undefined
      }

      const pairs = Object.entries(targetStatus.value)
        .filter(([_, m]) => {
          return m.isActive
        })
        .map(([name, status]) => {
          const targetStatus = getTargetStatus(status)
          return [name, targetStatus]
        })
      return fromPairs(pairs) as Record<string, FocusMunicipalityTargetStatusEnum>
    })

    return {
      data,
      targetStatus,
      groupedMunicipalityTargetStatuses,

      addMunicipality,
      updateMunicipality,
      deleteMunicipality,

      activeFocusMunicipalities,

      municipalityOptionsMap,
      municipalityGroupOptionsMap,

      redFocusMunicipalities,
      focusMunicipalityTargetMap,

      isFocusMunicipality,

      getFocusMunicipalitiesWithYear,
      getFocusMunicipalitiesWithYears,

      voorproefjeFocusMunicipalities,
      municipalityIsVoorproefje,
    }
  },
  {
    logger: {
      filter: (name) => name.name !== 'isFocusMunicipality',
    },
  },
)

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useFocusMunicipalitiesStore as any, import.meta.hot),
  )
}
