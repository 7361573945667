<script lang="ts" setup>
import { isArray } from 'lodash'
import type { OptionsMap } from '/@src/types/elements-ui'
import Multiselect from 'vue-multiselect'
import { useTemplateRef } from 'vue'
import type {
  VMultiselectModelValue,
  VMultiselectOptions,
  VMultiselectProps,
  VMultiselectValue,
} from '/@src/types/form-props'

type VMultiSelectEmits = {
  select: [selectedOption: string]
}

const emits = defineEmits<VMultiSelectEmits>()
const props = withDefaults(defineProps<VMultiselectProps>(), {
  placeholder: '',
  limit: 1,
  optionsLimit: undefined,
  openDirection: 'bottom',
  clearSelectedLabel: 'Selectie weghalen',
})

const value = defineModel<VMultiselectModelValue>()

const multiselect = useTemplateRef<InstanceType<typeof Multiselect>>('multiselect')

const clearSelection = () => {
  if (props.clearToNull) {
    value.value = null
  } else if (props.requireModelValue) {
    value.value = -1
  } else {
    value.value = undefined
  }

  if (props.closeOnSelect) {
    multiselect.value?.deactivate()
  }
}

const selectAllElements = () => {
  if (isOptionsArray(props.options)) {
    value.value = props.options.map((item) => item.id)
  } else if (isRecord(props.options)) {
    value.value = Object.keys(props.options)
  } else {
    value.value = props.options
  }
}

watch(
  () => props.options,
  () => generateOptions(),
)

const isOptionsArray = (options: VMultiselectOptions): options is OptionsMap[] => {
  return (
    isArray(props.options) &&
    props.options.length > 0 &&
    typeof props.options[0] === 'object'
  )
}

const isRecord = (options: VMultiselectOptions): options is Record<number, string> => {
  return !isArray(props.options)
}

const optionIds = ref<VMultiselectValue[]>([])
let customLabelsFunction: ((item1: number | string) => string | undefined) | undefined =
  undefined

const generateOptions = () => {
  if (isOptionsArray(props.options)) {
    optionIds.value = props.options
      .filter((item) => !toValue(item.disabled))
      .map((item) => item.id)
    customLabelsFunction = (item1: number | string) =>
      (props.options as OptionsMap[]).find(
        (item2) => item1.toString() === item2.id.toString(),
      )?.name
  } else if (isRecord(props.options)) {
    optionIds.value = unref(Object.keys(props.options).map(Number))
    customLabelsFunction = (item1: number | string) =>
      Object.values(props.options as Record<number, string>)[item1 as number]
  } else {
    optionIds.value = unref(props.options)
  }
}
generateOptions()

const canClearOption = computed(() => {
  if (!props.allowEmpty) {
    return false
  }

  if (Array.isArray(value.value)) {
    return value.value.length > 0
  } else if (props.requireModelValue) {
    return value.value !== -1
  } else {
    return value.value != null
  }
})

const multiSelectIsOpen = computed(() => (multiselect.value as any | undefined)?.isOpen)
defineExpose({ multiSelectIsOpen })
</script>
<template>
  <VueMultiselect
    ref="multiselect"
    v-model="value"
    :class="{
      'multiselect__limit-zero': limit === 0,
    }"
    :options="optionIds"
    :custom-label="customLabelsFunction"
    :show-labels="false"
    :close-on-select="closeOnSelect || !multiple"
    :multiple
    :searchable
    :placeholder
    :allow-empty
    :hide-selected
    :loading
    :taggable
    :open-direction
    :limit
    :limit-text="(count: number) => (limit === 0 ? '' : `en nog ${count} meer`)"
    :options-limit="optionsLimit"
    @select="(selectedOption: string) => emits('select', selectedOption)"
  >
    <template #beforeList>
      <li
        v-if="canClearOption"
        class="multiselect__element"
        @click="clearSelection"
        @keyup="clearSelection"
      >
        <span class="multiselect__option"> {{ clearSelectedLabel }} </span>
      </li>
      <li
        v-else-if="multiple && selectAll"
        class="multiselect__element"
        @click="selectAllElements"
        @keyup="selectAllElements"
      >
        <span class="multiselect__option"> Alles selecteren </span>
      </li>
    </template>
    <template #noResult> Geen resultaten gevonden</template>
    <template #noOptions> Geen resultaten beschikbaar</template>
    <template v-if="limit === 0" #limit>
      <slot name="limit"></slot>
    </template>
    <template v-if="limit === 0" #placeholder></template>
    <template v-if="limit === 0" #singleLabel></template>
  </VueMultiselect>
</template>

<style scoped></style>
