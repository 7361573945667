<script setup lang="ts">
import { useDashboardStore } from '/@src/stores/dashboard'
import type {
  DashboardLoadout,
  DashboardLoadoutId,
  DashboardWidgetSize,
  DashboardSlot,
  DashboardWidgetKey,
  DashboardWidgetTranslationDirection,
} from '/@src/types/dashboard'
import { getDefaultLayoutForRole, getNeighborCellSlot } from '/@src/mapping/dashboard'
import { useModal } from '/@src/composable/useModal'
import { onboarding } from '/@src/utils/onboarding'
import { useTemplateRef } from 'vue'
import WidgetSidebar from '/@src/components/organisms/dashboard/WidgetSidebar.vue'
import dayjs from 'dayjs'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import type { AdminUserId } from '/@src/types/admin-users'
import { type UserId, UserRoleEnum } from '/@src/types/users'

interface DashboardPageProps {
  isOverlay: boolean
}

const props = defineProps<DashboardPageProps>()

const adminUserStore = useAdminUsersStore()
const dashboardStore = useDashboardStore()

const sidebar = useTemplateRef<InstanceType<typeof WidgetSidebar>>('sidebar')

const isEditing = ref(false)

const selectedUserId = defineModel<UserId | null>('userId', { default: null })

const selectedSlot = ref<DashboardSlot>()
const {
  modalIsOpen: widgetSelectionModalIsOpen,
  openModal: openWidgetSelectModal,
  closeModal: closeWidgetSelectModal,
} = useModal({
  open: (slot: DashboardSlot) => {
    isEditing.value = true
    selectedSlot.value = slot
  },
  onClose: () => {
    selectedSlot.value = undefined
  },
})

const forceSlotBHidden = computed(() => {
  if (!dashboardStore.currentLoadout?.loadout) {
    return false
  }

  const slotB = dashboardStore.currentLoadout.loadout.position['slotB']
  if (slotB !== 'empty' && slotB !== 'hidden-empty') {
    return false
  }

  const slotA = dashboardStore.currentLoadout.loadout.position['slotA']
  const cellA = dashboardStore.getOrCreateWidgetSettings(slotA)

  return cellA?.size === 'horizontal'
})

const forceSlotCHidden = computed(() => {
  if (!dashboardStore.currentLoadout?.loadout) {
    return false
  }

  const slotC = dashboardStore.currentLoadout.loadout.position['slotC']
  if (slotC !== 'empty' && slotC !== 'hidden-empty') {
    return false
  }
  const slotA = dashboardStore.currentLoadout.loadout.position['slotA']
  const cellA = dashboardStore.getOrCreateWidgetSettings(slotA)

  return cellA?.size === 'vertical'
})

const forceSlotDHidden = computed(() => {
  if (!dashboardStore.currentLoadout?.loadout) {
    return false
  }

  const slotD = dashboardStore.currentLoadout.loadout.position['slotD']
  if (slotD !== 'empty' && slotD !== 'hidden-empty') {
    return false
  }

  const slotB = dashboardStore.currentLoadout.loadout.position['slotB']
  const cellB = dashboardStore.getOrCreateWidgetSettings(slotB)

  if (cellB?.size === 'vertical') {
    return true
  }

  const slotC = dashboardStore.currentLoadout.loadout.position['slotC']
  const cellC = dashboardStore.getOrCreateWidgetSettings(slotC)

  return cellC?.size === 'horizontal'
})

/**
 * Moves a cell in the given direction, if possible
 * @param slot
 * @param direction
 * @param skipEmptyCheck
 */
const move = (
  slot: DashboardSlot,
  direction: DashboardWidgetTranslationDirection,
  skipEmptyCheck: boolean = false,
) => {
  // Should not be possible, but check to keep typescript happy
  if (!dashboardStore.currentLoadout?.loadout) {
    return
  }

  // Empty cells are never allowed to move
  const activeCell = dashboardStore.currentLoadout.loadout.position[slot]
  if (activeCell === 'empty' || activeCell === 'hidden-empty') {
    if (skipEmptyCheck) {
      const neighborCellSlot = getNeighborCellSlot(slot, direction)
      if (!neighborCellSlot) {
        return
      }
      const tempKey = dashboardStore.currentLoadout.loadout.position[slot]
      dashboardStore.currentLoadout.loadout.position[slot] =
        dashboardStore.currentLoadout.loadout.position[neighborCellSlot]
      dashboardStore.currentLoadout.loadout.position[neighborCellSlot] = tempKey
    }

    return
  }

  const settings = dashboardStore.getOrCreateWidgetSettings(activeCell)
  // Checks for illegal moves with scaled up cells
  if (
    settings &&
    settings.size === 'vertical' &&
    (direction === 'up' || direction === 'down')
  ) {
    return
  } else if (
    settings &&
    settings.size === 'horizontal' &&
    (direction === 'left' || direction === 'right')
  ) {
    return
  }

  const neighborCellSlot = getNeighborCellSlot(slot, direction)

  // Checks for other illegal moves (i.e. SlotA moving up)
  if (!neighborCellSlot) {
    return
  }

  // Get the neighbor cell
  const neighborCell = dashboardStore.currentLoadout.loadout.position[neighborCellSlot]
  const neighborCellSettings = dashboardStore.getOrCreateWidgetSettings(neighborCell)

  if (
    !skipEmptyCheck &&
    (neighborCell === 'hidden-empty' || neighborCellSettings?.hidden)
  ) {
    return
  }

  if (settings?.size === 'horizontal') {
    if (direction === 'up') {
      move('slotB', 'down', true)
    } else if (direction === 'down') {
      move('slotD', 'up', true)
    }
  } else if (neighborCellSettings?.size === 'horizontal') {
    if (direction === 'up') {
      move('slotD', 'up', true)
    } else if (direction === 'down') {
      move('slotB', 'down', true)
    }
  } else if (settings?.size === 'vertical') {
    if (direction === 'left') {
      move('slotC', 'right', true)
    } else if (direction === 'right') {
      move('slotD', 'left', true)
    }
  } else if (neighborCellSettings?.size === 'vertical') {
    if (direction === 'left') {
      move('slotD', 'left', true)
    } else if (direction === 'right') {
      move('slotC', 'right', true)
    }
  }

  // And swap them around
  dashboardStore.currentLoadout.loadout.position[neighborCellSlot] = activeCell
  dashboardStore.currentLoadout.loadout.position[slot] = neighborCell

  dashboardStore.dashboardHistory.push({
    operation: 'move',
    slot: slot,
    direction: direction,
  })

  // Store the loadout
  dashboardStore.storeCurrentLoadout(true)
}

/**
 * Scales the given cell up or down in the given direction
 * @param slot
 * @param direction
 */
const scaleCell = (
  slot: DashboardSlot,
  direction: DashboardWidgetTranslationDirection,
) => {
  // Should not be possible, but check to keep typescript happy
  if (!dashboardStore.currentLoadout?.loadout) {
    return
  }

  // Empty cells are never allowed to scale
  const activeCell = dashboardStore.currentLoadout.loadout.position[slot]
  if (!activeCell || activeCell === 'empty' || activeCell === 'hidden-empty') {
    return
  }

  // Get the current widget settings and bail out if they do not exist (i.e. if they're empty)
  const currentSettings = dashboardStore.getOrCreateWidgetSettings(activeCell)
  if (!currentSettings) {
    return
  }

  // Get the new size of the current cell and bail out early if the move is illegal
  let newSize: DashboardWidgetSize
  let normalisedDirection = direction
  if (direction === 'down' || direction === 'up') {
    if (currentSettings.size === 'horizontal') {
      return
    }
    newSize = currentSettings.size === 'single' ? 'vertical' : 'single'

    // Only normalize if this is a scale down operation
    if (direction === 'up' && (slot === 'slotA' || slot === 'slotB')) {
      normalisedDirection = 'down'
    }
  } else {
    if (currentSettings.size === 'vertical') {
      return
    }
    newSize = currentSettings.size === 'single' ? 'horizontal' : 'single'

    // Only normalize if this is scale down operation
    if (direction === 'left' && (slot === 'slotA' || slot === 'slotC')) {
      normalisedDirection = 'right'
    }
  }

  // Get the neighboring cell and do a bunch of checks
  const neighborCellSlot = getNeighborCellSlot(slot, normalisedDirection)
  if (neighborCellSlot) {
    const neighborCell = dashboardStore.currentLoadout.loadout.position[neighborCellSlot]

    // Move is illegal if another cell has already covered up the neighboring cell
    if (neighborCell === 'hidden-empty' && newSize !== 'single') {
      return
    }
    const neighborCellSettings = dashboardStore.getOrCreateWidgetSettings(neighborCell)

    // Corrupted loadout, attempt to recover it
    if (
      neighborCellSettings &&
      currentSettings.size !== 'single' &&
      neighborCellSettings?.size !== 'single'
    ) {
      neighborCellSettings.size = 'single'
    }

    // Another check to see if the cell is already covered up by another cell
    if (
      (newSize !== 'single' && neighborCellSettings?.hidden) ||
      (neighborCellSettings && neighborCellSettings?.size !== 'single')
    ) {
      return
    }

    // Set visibility of the neighboring cell
    if (neighborCellSettings) {
      neighborCellSettings.hidden = newSize !== 'single'
    } else if (neighborCell === 'empty' || neighborCell === 'hidden-empty') {
      dashboardStore.currentLoadout.loadout.position[neighborCellSlot] =
        newSize === 'single' ? 'empty' : 'hidden-empty'
    }
  }

  // Swap the cells if needed
  if (
    (direction === 'up' && (slot === 'slotC' || slot === 'slotD')) ||
    (direction === 'left' && (slot === 'slotB' || slot === 'slotD'))
  ) {
    move(slot, direction, true)
  }

  dashboardStore.dashboardHistory.push({
    operation: 'scale',
    slot: slot,
    newSize: newSize,
  })

  // Set the new size
  currentSettings.size = newSize

  // And store the loadout
  dashboardStore.storeCurrentLoadout(true)
}

const addWidgetToCell = (slot: DashboardSlot | undefined, widget: DashboardWidgetKey) => {
  if (!slot) {
    return
  }

  // Should not be possible, but check to keep typescript happy
  if (!dashboardStore.currentLoadout?.loadout) {
    return
  }

  const activeCell = dashboardStore.currentLoadout.loadout.position[slot]

  // Get the current widget settings and bail out if they do not exist (i.e. if they're empty)
  const currentSettings = dashboardStore.getOrCreateWidgetSettings(activeCell)
  if (!currentSettings && activeCell !== 'empty' && activeCell !== 'hidden-empty') {
    return
  }

  const nextSettings = dashboardStore.getOrCreateWidgetSettings(widget)
  if (!nextSettings) {
    return
  }

  if (
    nextSettings.hidden &&
    nextSettings.componentKey !== 'empty' &&
    nextSettings.componentKey !== 'hidden-empty'
  ) {
    const positions = dashboardStore.currentLoadout.loadout.position
    const hiddenSlot = Object.keys(positions).find(
      (slot) => positions[slot as DashboardSlot] === widget,
    ) as DashboardSlot | undefined

    if (!hiddenSlot) {
      return
    }

    dashboardStore.currentLoadout.loadout.position[hiddenSlot] = 'hidden-empty'
    nextSettings.hidden = false
  }

  dashboardStore.dashboardHistory.push({
    operation: 'open',
    slot: slot,
    widgetKey: widget,
  })

  nextSettings.size = currentSettings?.size ?? 'single'
  dashboardStore.currentLoadout.loadout.position[slot] = widget
  dashboardStore.storeCurrentLoadout(true)
  closeWidgetSelectModal()
}

/**
 * Removes a widget from a cell
 * @param slot
 */
const closeCell = (slot: DashboardSlot) => {
  // Should not be possible, but check to keep typescript happy
  if (!dashboardStore.currentLoadout?.loadout) {
    return
  }

  // Empty cells cannot be closed
  const activeCell = dashboardStore.currentLoadout.loadout.position[slot]
  if (!activeCell || activeCell === 'empty' || activeCell === 'hidden-empty') {
    return
  }

  // Get the current settings and bail out if they do not exist
  const currentSettings = dashboardStore.getOrCreateWidgetSettings(activeCell)
  if (!currentSettings) {
    return
  }

  // Scale the cell down first if needed
  if (currentSettings.size !== 'single') {
    scaleCell(slot, currentSettings.size === 'horizontal' ? 'right' : 'down')
  }

  dashboardStore.dashboardHistory.push({
    operation: 'close',
    slot: slot,
  })
  // Turn the cell empty and store
  dashboardStore.currentLoadout.loadout.position[slot] = 'empty'
  dashboardStore.storeCurrentLoadout(true)
}

const createNewLoadoutForTutorial = () => {
  if (
    !dashboardStore.loadouts.find(
      (loadout) => loadout.loadoutName === 'De beste layout ever!',
    )
  ) {
    const newLoadout: DashboardLoadout = {
      loadoutName: 'De beste layout ever!',
      id: -5 as DashboardLoadoutId,
      createdAt: dayjs(),
      updatedAt: dayjs(),
      adminUserId: useAdminUsersStore().adminUser?.id ?? (-1 as AdminUserId),
      loadout: {
        position: {
          slotA: 'animals',
          slotB: 'animals',
          slotC: 'animals',
          slotD: 'animals',
        },
        settings: [
          {
            componentKey: 'animals',
            hidden: false,
            size: 'single',
            animal: useAdminUsersStore().adminUser?.favoriteAnimal ?? 'cat',
            isTutorial: true,
          },
        ],
      },
    }
    dashboardStore.loadouts.push(newLoadout)
  }
}

const clearTutorialLoadout = () => {
  const index = dashboardStore.loadouts.findIndex(
    (l) => l.id === (-5 as DashboardLoadoutId),
  )
  if (index !== -1) {
    dashboardStore.loadouts.splice(index, 1)
  }

  if (dashboardStore.currentLoadout?.id === (-5 as DashboardLoadoutId)) {
    dashboardStore.currentLoadout = dashboardStore.loadouts[0]
  }
}

interface RestoreDashboardProps {
  loadouts: DashboardLoadout[]
  currentLoadout: DashboardLoadout | undefined
}

if (!props.isOverlay) {
  onboarding.setTutorial({
    beforeStart: () => {
      isEditing.value = false

      const adminUser = adminUserStore.adminUser
      if (!adminUser) {
        return false
      }

      const storedDashboard = {
        loadouts: dashboardStore.loadouts,
        currentLoadout: dashboardStore.currentLoadout,
      } satisfies RestoreDashboardProps
      const newLayout = getDefaultLayoutForRole({
        ...adminUser,
        mainRole: UserRoleEnum.Admin,
      })
      newLayout.id = -2 as DashboardLoadoutId
      dashboardStore.loadouts = [newLayout]
      dashboardStore.currentLoadout = newLayout

      return storedDashboard
    },
    onEnd: (returnVal: RestoreDashboardProps) => {
      isEditing.value = false
      closeWidgetSelectModal()
      dashboardStore.loadouts = returnVal.loadouts
      dashboardStore.currentLoadout = returnVal.currentLoadout
    },
    beforeRouteLeave: onBeforeRouteLeave,
    tutorial: {
      steps: [
        {
          popover: {
            title: 'Het Nieuwe Dashboard',
            description:
              'Welkom bij het nieuwe dashboard! Je kunt jouw dashboard nu helemaal zelf samenstellen. Er zijn nu meerdere blokken beschikbaar, ook wel widgets genoemd. Deze kun je gebruiken om een dashboard in te stellen die voor jou het fijnste werkt.',
          },
        },
        {
          element: '#loadout-sidecard',
          popover: {
            title: 'Het Dashboard',
            description:
              'Het standaard dashboard ziet er nog precies hetzelfde uit als hiervoor, dus als je daar gelukkig mee bent, kan je deze tutorial ook weer sluiten. Wil je wel wat veranderen? Druk dan op deze knop aan de zijkant.',
            side: 'right',
            align: 'center',
            showButtons: ['next', 'previous', 'close'],
            onNextClick: async () => {
              sidebar.value?.openCard()
              setTimeout(() => onboarding.refresh(), 500)
            },
          },
        },
        {
          element: '#loadout-sidecard',
          popover: {
            title: 'Het Dashboard',
            description:
              "In dit scherm zie je de 'layout' die nu actief is, kan je snel wisselen naar andere layouts, en kan je de huidige layout aanpassen. <br > ",
            side: 'right',
            align: 'center',
            onPrevClick: async () => {
              sidebar.value?.closeCard()
              setTimeout(() => onboarding.refresh(), 500)
            },
          },
        },
        {
          element: '#loadout-edit-button',
          popover: {
            title: 'Een layout aanpassen',
            description:
              "Door op deze knop te drukken, gaat de 'aanpas'-modus aan. Het is dan mogelijk om widgets te verplaatsen, groter of kleinere te maken, of een nieuwe widget uit te kiezen.",
            onNextClick: () => {
              isEditing.value = true
              sidebar.value?.closeCard()
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: 'Een layout aanpassen',
            description: 'Hier kan je widgets verplaatsen, verwijderen, en toevoegen',
            onPrevClick: async () => {
              isEditing.value = false
              sidebar.value?.openCard()
              await nextTick()
            },
          },
        },
        {
          element: '#slot-slotC',
          popover: {
            title: 'Widgets',
            description:
              'Er zijn maximaal vier widgets tegelijk zichtbaar. Een widget kan één plek in beslag nemen, zoals deze, of een hele rij of kolom. Het is ook mogelijk om een plek leeg te laten. De volgende stappen laten zien hoe je widgets kan veranderen.',
          },
        },
        {
          element: '#slot-slotC-move-right',
          popover: {
            title: 'Een widget verplaatsen',
            description:
              'Met de grote pijlen verplaats je een widget in de aangegeven richting',
            onNextClick: () => {
              move('slotC', 'right')
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: 'Een widget verplaatsen',
            description: 'De onderste twee widgets zijn nu van plek gewisseld',
            onPrevClick: async () => {
              isEditing.value = false
              sidebar.value?.openCard()
              await nextTick()
            },
          },
        },
        {
          element: '#slot-slotA-scale-left',
          popover: {
            title: 'Een widget vergroten of verkleinen',
            description:
              'Met de pijlen aan de zijkanten vergroot of verklein je een widget',
            onNextClick: () => {
              scaleCell('slotA', 'left')
            },
            onPrevClick: () => {
              move('slotC', 'right')
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: 'Een widget vergroten of verkleinen',
            description: 'De widget linksboven is nu kleiner geworden',
            onPrevClick: async () => {
              isEditing.value = false
              sidebar.value?.openCard()
              await nextTick()
            },
          },
        },
        {
          element: '#slot-slotD-close',
          popover: {
            title: 'Een widget weghalen',
            description: "Door op de 'X' te drukken, haal je een widget weg",
            onNextClick: () => {
              closeCell('slotD')
            },
            onPrevClick: () => {
              scaleCell('slotA', 'right')
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: 'Een widget weghalen',
            description:
              'De widget rechtsonder is nu verdwenen. De gegevens zijn nog wel opgeslagen, het is alleen niet meer zichtbaar',
            onPrevClick: async () => {
              isEditing.value = false
              sidebar.value?.openCard()
              await nextTick()
            },
          },
        },
        {
          element: '#slot-slotB-add',
          popover: {
            title: 'Een nieuwe widget kiezen',
            description:
              "Door op de '+' te drukken, verschijnt een pop-up om een nieuwe widget te selecteren",
            onNextClick: () => {
              openWidgetSelectModal('slotB')
              move('slotC', 'right')
              addWidgetToCell('slotC', 'focus-municipalities')
            },
            onPrevClick: () => {
              addWidgetToCell('slotD', 'focus-municipalities')
            },
          },
        },
        {
          element: '#widget-modal',
          popover: {
            title: 'Een nieuwe widget kiezen',
            description:
              'Hier zie je een overzicht van widgets die voor jou beschikbaar zijn',
            onPrevClick: () => {
              move('slotC', 'right')
              closeWidgetSelectModal()
            },
          },
        },
        {
          element: '#widget-modal-notes',
          popover: {
            title: 'Een nieuwe widget toevoegen',
            description:
              'Met deze widget heb je bijvoorbeeld een notitieblokje gelijk op het dashboard beschikbaar. De notities worden opgeslagen, dus je raakt ze niet kwijt. Laten we deze nu toevoegen.',
            onNextClick: () => {
              addWidgetToCell('slotB', 'notes')
              closeWidgetSelectModal()
              isEditing.value = false

              const settings = dashboardStore.getOrCreateWidgetSettings('notes')
              if (settings) {
                settings.stringInput = 'Hallo! Gebruik mij om notities op te slaan :)'
              }
            },
          },
        },
        {
          element: '#slot-slotB',
          popover: {
            title: 'Een nieuwe widget toevoegen',
            description: 'Hier verschijnt nu vanzelf het notitieblokje!',
            onNextClick: () => {
              closeCell('slotB')
              scaleCell('slotA', 'right')
            },
            onPrevClick: () => {
              closeCell('slotB')
              openWidgetSelectModal('slotB')
              isEditing.value = true
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: 'Alle instellingen worden automatisch opgeslagen',
            description:
              'Ook die van widgets die niet (meer) zichtbaar zijn, zoals nu het notitieblokje',
            onNextClick: async () => {
              createNewLoadoutForTutorial()
              sidebar.value?.openCard()
              setTimeout(() => onboarding.refresh(), 500)
            },
            onPrevClick: () => {
              scaleCell('slotA', 'left')
            },
          },
        },
        {
          element: '#loadout-sidecard',
          popover: {
            title: 'Het Dashboard',
            description:
              'Terug hiernaar. Het is ook mogelijk om nieuwe layouts te maken en snel van layouts te veranderen. Zo kan je voor elke werkwijze een specifieke layout maken. Bijv. een dashboard voor regiocoördinatorwerk of voor het aanvragen van VOGs. Je kunt dan wisselen wanneer je van werk wisselt, en ziet niet de hele tijd alle informatie',
            side: 'right',
            align: 'center',
            onPrevClick: async () => {
              sidebar.value?.closeCard()
              setTimeout(() => onboarding.refresh(), 500)
              clearTutorialLoadout()
            },
          },
        },
        {
          element: '#loadout-list--5',
          popover: {
            title: 'Layouts',
            description: 'Hier is bijvoorbeeld een nieuwe layout, speciaal voor jou',
            onNextClick: () => {
              dashboardStore.setCurrentLoadout(-5 as DashboardLoadoutId)
              sidebar.value?.closeCard()
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: '😍😍😍😍',
            onNextClick: () => {
              clearTutorialLoadout()
              sidebar.value?.openCard()
              setTimeout(() => onboarding.refresh(), 500)
            },
            onPrevClick: () => {
              sidebar.value?.openCard()
            },
          },
        },
        {
          element: '#new-loadout-button',
          popover: {
            title: 'Nieuwe layout maken',
            description: 'Druk hier om een nieuwe layout te maken',
            onNextClick: () => {
              dashboardStore.setCurrentLoadout(null, true)
              isEditing.value = true
              sidebar.value?.closeCard()
            },
            onPrevClick: () => {
              createNewLoadoutForTutorial()
              dashboardStore.setCurrentLoadout(-5 as DashboardLoadoutId)
              sidebar.value?.closeCard()
            },
          },
        },
        {
          element: '#dashboard-loadout',
          popover: {
            title: 'Nieuwe layout maken',
            description:
              'In een nieuwe layout kan je helemaal los gaan en het precies zo instellen zoals jij wilt!',
            onNextClick: () => {
              sidebar.value?.openCard()
              setTimeout(() => onboarding.refresh(), 500)
              isEditing.value = false
            },
            onPrevClick: () => {
              const previousLoadout = dashboardStore.loadouts[0]
              dashboardStore.setCurrentLoadout(previousLoadout.id)
              isEditing.value = false
              sidebar.value?.openCard()
              setTimeout(() => onboarding.refresh(), 500)
            },
          },
        },
        {
          element: '#loadout-list--1',
          popover: {
            title: 'Nieuwe layout maken',
            description:
              'Als laatste kan je layouts ook een naam geven. Dan vind je snel de goede. Druk op de naam om de naam aan te passen',
            onNextClick: () => {
              if (dashboardStore.currentLoadout) {
                sidebar.value?.openNameInputForTutorial(dashboardStore.currentLoadout)
              }
            },
          },
        },
        {
          element: '#change-loadout-name-modal',
          popover: {
            title: 'Naam geven aan layout',
            description:
              'De naam kan van alles zijn, meerdere layouts mogen ook dezelfde naam hebben, als je daar gelukkig van wordt 😜',
            onNextClick: () => {
              sidebar.value?.closeChangeNameModal()
              clearTutorialLoadout()
              sidebar.value?.closeCard()

              const loadout = dashboardStore.loadouts[0]
              dashboardStore.setCurrentLoadout(loadout.id)
              setTimeout(() => onboarding.refresh(), 500)
            },
            onPrevClick: () => {
              sidebar.value?.closeChangeNameModal()
            },
          },
        },
        {
          popover: {
            title: 'Done!',
            description:
              'Dit was de basis van het nieuwe dashboard! In de toekomst komen er nog nieuwe widgets bij en worden de standaard dashboards helemaal toegespitst per rol. Voor nu heeft dit hopelijk alles iets duidelijker gemaakt. Feedback is altijd welkom!',
            onPrevClick: () => {
              sidebar.value?.openCard()
              dashboardStore.setCurrentLoadout(null, true)
              if (dashboardStore.currentLoadout) {
                sidebar.value?.openNameInputForTutorial(dashboardStore.currentLoadout)
              }
            },
          },
        },
      ],
    },
  })
}
</script>

<template>
  <div
    class="dashboard-page"
    :id="isOverlay ? 'dashboard-loadout-overlay' : 'dashboard-loadout'"
  >
    <WidgetSidebar
      v-if="!isOverlay"
      ref="sidebar"
      v-model:editing="isEditing"
      v-model:user-id="selectedUserId"
    />

    <div class="grid-wrapper">
      <DashboardGridItem
        slot="slotA"
        :component-key="dashboardStore.currentLoadout?.loadout.position?.['slotA']"
        :editing="isEditing"
        :is-overlay
        :user-id="selectedUserId"
        @move="(direction) => move('slotA', direction)"
        @scale="(direction) => scaleCell('slotA', direction)"
        @select="openWidgetSelectModal('slotA')"
        @close="closeCell('slotA')"
      />

      <DashboardGridItem
        slot="slotB"
        :component-key="dashboardStore.currentLoadout?.loadout.position?.['slotB']"
        :editing="isEditing"
        :force-hidden="forceSlotBHidden"
        :is-overlay
        :user-id="selectedUserId"
        @move="(direction) => move('slotB', direction)"
        @scale="(direction) => scaleCell('slotB', direction)"
        @select="openWidgetSelectModal('slotB')"
        @close="closeCell('slotB')"
      />

      <DashboardGridItem
        slot="slotC"
        :component-key="dashboardStore.currentLoadout?.loadout.position?.['slotC']"
        :editing="isEditing"
        :force-hidden="forceSlotCHidden"
        :is-overlay
        :user-id="selectedUserId"
        @move="(direction) => move('slotC', direction)"
        @scale="(direction) => scaleCell('slotC', direction)"
        @select="openWidgetSelectModal('slotC')"
        @close="closeCell('slotC')"
      />

      <DashboardGridItem
        slot="slotD"
        :component-key="dashboardStore.currentLoadout?.loadout.position?.['slotD']"
        :editing="isEditing"
        :force-hidden="forceSlotDHidden"
        :is-overlay
        :user-id="selectedUserId"
        @move="(direction) => move('slotD', direction)"
        @scale="(direction) => scaleCell('slotD', direction)"
        @select="openWidgetSelectModal('slotD')"
        @close="closeCell('slotD')"
      />
    </div>

    <DashboardWidgetModal
      v-if="!isOverlay"
      v-model:open="widgetSelectionModalIsOpen"
      :slot="selectedSlot"
      :is-overlay
      @select="(slot, key) => addWidgetToCell(slot, key)"
    />
  </div>
</template>

<style scoped lang="scss"></style>
