<script setup lang="ts">
import { range } from 'lodash'
import type { OptionsMap } from '/@src/types/elements-ui'
import { dayjsUTC } from '/@src/utils/date-formatter'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { getMunicipalityName } from '/@src/mapping/focus-municipalities'
import { match } from 'ts-pattern'
import { useWebSocketModel } from '/@src/composable/useWebSocket'
import type {
  FocusMunicipality,
  FocusMunicipalityId,
} from '/@src/types/focus-municipalities'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'

definePage({
  meta: {
    roles: ['manager', 'impact-accelerator', 'marketing'],
  },
})

type ExpectedFilterOption = 'expected' | 'actual' | 'both'

const focusMunicipalitiesStore = useFocusMunicipalitiesStore()

const currentYear = dayjsUTC().year()
const inputYears: OptionsMap[] = range(currentYear - 3, currentYear + 3, 1).map(
  (year) => {
    return {
      id: year,
      name: year.toString(),
    }
  },
)
const inputYear = ref<number>(currentYear)
const nameFilter = ref<string>('')

const expectedFilter = ref<ExpectedFilterOption>('both')

const activeFocusMunicipalityId = ref<FocusMunicipalityId>()

const expectedFilterOptions: OptionsMap<ExpectedFilterOption>[] = [
  {
    id: 'expected',
    name: 'Alleen verwachte focus-gemeentes',
  },
  {
    id: 'actual',
    name: 'Alleen definitieve focus-gemeentes',
  },
  {
    id: 'both',
    name: 'Beide',
  },
]

const { setModels } = useWebSocketModel<FocusMunicipality>({
  baseChannel: 'tg-admin-channel-focus-municipality',
  event: '.FocusMunicipalityUpdated',
  callback: (newMunicipality: WebSocketsModelReply<FocusMunicipality>) => {
    const municipality = focusMunicipalitiesStore.data.find(
      (m) => m.id === newMunicipality.model.id,
    )

    if (municipality) {
      updateModel(municipality, newMunicipality.model)
    }
  },
})
watch(
  () => focusMunicipalitiesStore.data,
  (newValue) => setModels(newValue),
  { immediate: true },
)

const focusMunicipalities = computed(() =>
  focusMunicipalitiesStore.getFocusMunicipalitiesWithYear(inputYear.value),
)
const filteredMunicipalities = computed(() => {
  const filter = nameFilter.value?.toLowerCase()

  return focusMunicipalities.value.filter((m) => {
    if (filter) {
      if (!getMunicipalityName(m).toLowerCase().includes(filter)) {
        return false
      }
    }

    return match(expectedFilter.value)
      .with('both', () => true)
      .with('expected', () => m.isExpected)
      .with('actual', () => !m.isExpected)
      .exhaustive()
  })
})

const resetFilters = () => {
  inputYear.value = currentYear
  nameFilter.value = ''
  expectedFilter.value = 'both'
}
</script>

<template>
  <div>
    <div class="page-content-inner">
      <div class="columns is-multiline">
        <div class="column is-one-third">
          <VCard class="mb-3">
            <template #content>
              <h3 class="title is-size-4">Filters</h3>
              <h4 class="subtitle is-size-6">
                Totaal: {{ filteredMunicipalities.length }}
              </h4>

              <div class="columns is-multiline">
                <div class="column is-full">
                  <VMultiselect v-model="inputYear" :options="inputYears" />
                </div>

                <TableFilter v-model="nameFilter" full-width />

                <div class="column is-full">
                  <VMultiselect
                    v-model="expectedFilter"
                    :options="expectedFilterOptions"
                  />
                </div>
              </div>
            </template>

            <template #footer>
              <ResetButton @reset="resetFilters" />
            </template>
          </VCard>

          <AddFocusMunicipalityCard />
        </div>
        <div class="column is-two-thirds">
          <!--List-->
          <div class="list-view list-view-v3">
            <!--Item-->
            <VCard v-if="filteredMunicipalities.length === 0">
              <template #content>
                <h3 class="title is-6 mb-4">Geen focus-gemeentes</h3>
              </template>
            </VCard>
            <FocusMunicipalityCard
              v-for="focusMunicipality in filteredMunicipalities"
              :key="focusMunicipality.id"
              :focus-municipality="focusMunicipality"
              :active="focusMunicipality.id === activeFocusMunicipalityId"
              @activate="activeFocusMunicipalityId = focusMunicipality.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
