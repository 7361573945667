<script setup lang="ts">
import type { FAIconName, TailwindColor } from '/@src/types/elements-ui'

type ResetButtonEmits = {
  reset: []
}

interface ResetButtonProps {
  icon?: FAIconName
  time?: number | `${number}`
  color?: TailwindColor
  secondaryColor?: TailwindColor
  width?: number | `${number}` | 'full'
  minWidth?: `${number}%`
  notAutomatic?: boolean
  loading?: boolean
  noLabel?: boolean
}

const emits = defineEmits<ResetButtonEmits>()
const props = withDefaults(defineProps<ResetButtonProps>(), {
  icon: 'fa-arrows-rotate',
  time: 0.4,
  color: 'warning',
  secondaryColor: 'success',
  width: 'full',
  minWidth: '90%',
})
</script>

<template>
  <VAnimatedButton
    :icon
    :color
    :secondary-color
    :time
    :min-width
    :width
    :not-automatic
    :no-label
    @click="emits('reset')"
    @keyup="emits('reset')"
  >
    <slot> Reset </slot>
  </VAnimatedButton>
</template>

<style scoped lang="scss"></style>
