import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  NationalTargetSurveyRatings,
  NormalisedYearlyTargetValues,
  WeeklyTarget,
  YearlyTarget,
  YearlyTargetParams,
  YearlyTargetWithWeeklyTargetsParams,
} from '/@src/types/national-targets'
import dayjs, { Dayjs } from 'dayjs'
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { toast } from 'vue-sonner'
import type { MaybeRefOrGetter } from 'vue'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import type { ApiResponse } from '/@src/types/utils'
import type { AxiosError } from 'axios'
import isLeapYear from 'dayjs/plugin/isLeapYear'

dayjs.extend(quarterOfYear)
dayjs.extend(isLeapYear)

const api = useApi()

export const useNationalTargetsStore = defineStore('nationalTargets', () => {
  const yearlyTargets = ref<YearlyTarget[]>([])
  const weeklyTargets = computed<WeeklyTarget[]>(() =>
    yearlyTargets.value.flatMap((y) => y.weeklyTargets ?? []),
  )

  const internallyUpdateYearlyTarget = (newModel: YearlyTarget) => {
    const foundModel = yearlyTargets.value.find((m) => m.year === newModel.year)

    if (foundModel) {
      updateModel(foundModel, newModel)
    } else {
      yearlyTargets.value?.push(newModel)
    }
  }

  useWebSocket({
    event: '.YearlyTargetUpdatedOrCreated',
    callback: (newModel: WebSocketsModelReply<YearlyTarget>) => {
      internallyUpdateYearlyTarget(newModel.model)
    },
    channel: 'tg-admin-channel-yearly-targets',
    cancelOnUnmounted: false,
  })

  useWebSocket({
    event: '.WeeklyTargetUpdatedOrCreated',
    callback: (newModel: WebSocketsModelReply<WeeklyTarget>) => {
      const yearModel = yearlyTargets.value.find((m) => m.year === newModel.model.year)
      if (!yearModel) {
        return
      }

      const foundModel = yearModel.weeklyTargets.find((m) =>
        m.weekStartDate.isSame(newModel.model.weekStartDate, 'day'),
      )

      if (foundModel) {
        updateModel(foundModel, newModel.model)
      } else {
        yearModel.weeklyTargets?.push(newModel.model)
      }
    },
    channel: 'tg-admin-channel-weekly-targets',
    cancelOnUnmounted: false,
  })

  const getYearlyTarget = async (
    year: number,
  ): Promise<YearlyTarget | false | undefined> => {
    try {
      const result = await api.post<ApiResponse<YearlyTarget>>(
        'admin/national-targets/yearly',
        {
          year,
        },
      )
      return result.data.data
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        return undefined
      }

      return false
    }
  }

  const createYearlyTarget = async (params: YearlyTargetParams): Promise<boolean> => {
    try {
      const result = await api.put<ApiResponse<YearlyTarget | undefined>>(
        'admin/national-targets/yearly',
        params,
      )
      toast.success('Jaarlijks doel aangemaakt')
      if (result.data.data) {
        internallyUpdateYearlyTarget(result.data.data)
        return true
      } else {
        return false
      }
    } catch {
      toast.error('Jaarlijks doel aanmaken mislukt')
      return false
    }
  }

  const updateYearlyTarget = async (
    params: YearlyTargetWithWeeklyTargetsParams,
  ): Promise<boolean> => {
    try {
      const result = await api.put(
        `admin/national-targets/yearly/${params.id}/update`,
        params,
      )
      toast.success('Jaarlijks doel aangepast')
      return !!result
    } catch {
      toast.error('Jaarlijks doel aanpassen mislukt')
      return false
    }
  }

  const calculateRatio = (
    weeklyTarget: WeeklyTarget | undefined,
    daysInWeek: number,
    daysInYear: number,
  ): number => {
    if (weeklyTarget) {
      return weeklyTarget.targetRatio / weeklyTarget.integerScaleFactor
    } else {
      console.log('Unknown week detected')
      return daysInWeek / daysInYear
    }
  }

  const calculateRatioBetweenDates = (
    fromDayjsRef: MaybeRefOrGetter<Dayjs | null | undefined>,
    toDayjsRef: MaybeRefOrGetter<Dayjs | null | undefined>,
  ): number => {
    const fromDayjs = toValue(fromDayjsRef)?.startOf('day')
    const toDayjs = toValue(toDayjsRef)?.startOf('day')

    if (!fromDayjs || !toDayjs || weeklyTargets.value.length === 0) {
      return 0
    }

    let totalRatio = 0

    const period = fromDayjs.period(1, 'week', toDayjs)

    period.forEach((currentDate) => {
      const year = currentDate.year()
      let daysInYear = currentDate.isLeapYear() ? 366 : 365

      const startOfWeekYear = currentDate.year()

      const endOfWeek = currentDate.clone().endOf('isoWeek')
      const endOfWeekYear = endOfWeek.year()

      const validWeekDays = currentDate
        .startOf('isoWeek')
        .period(1, 'day', endOfWeek)
        .filter((d) => d.year() === year)
      let startOfWeek = validWeekDays[0]

      let validDays = validWeekDays.length

      // If the current week is not in the same year, we have to calculate two target ratios
      if (fromDayjs.isSameOrBefore(startOfWeek) && startOfWeekYear !== endOfWeekYear) {
        const previousYearTarget = weeklyTargets.value.find(
          (t) =>
            t.weekStartDate.format('YYYY-MM-DD') === startOfWeek.format('YYYY-MM-DD'),
        )

        totalRatio += calculateRatio(previousYearTarget, validDays, daysInYear)

        startOfWeek = startOfWeek.add(1, 'year').startOf('year')
        daysInYear = startOfWeek.isLeapYear() ? 366 : 365
        validDays = 7 - validDays

        if (startOfWeek.isAfter(toDayjs)) {
          return
        }
      }

      const currentWeeklyTarget = weeklyTargets.value.find(
        (t) => t.weekStartDate.format('YYYY-MM-DD') === startOfWeek.format('YYYY-MM-DD'),
      )

      totalRatio += calculateRatio(currentWeeklyTarget, validDays, daysInYear)
    })

    return totalRatio
  }

  /**
   * @param fromDayjsRef
   * @param toDayjsRef
   */
  const getTargetsInRange = (
    fromDayjsRef: MaybeRefOrGetter<Dayjs>,
    toDayjsRef: MaybeRefOrGetter<Dayjs>,
  ): NormalisedYearlyTargetValues => {
    if (!yearlyTargets.value) {
      return {
        goldValue: 0,
        silverValue: 0,
        bronzeValue: 0,
      }
    }

    const fromDayjs = toValue(fromDayjsRef)
    const toDayjs = toValue(toDayjsRef)

    if (fromDayjs.year() !== toDayjs.year()) {
      return {
        goldValue: 0,
        silverValue: 0,
        bronzeValue: 0,
      }
    }

    const yearlyTarget = yearlyTargets.value.find((t) => t.year === fromDayjs.year())
    if (!yearlyTarget) {
      return {
        goldValue: 0,
        silverValue: 0,
        bronzeValue: 0,
      }
    }

    const targetRatio = calculateRatioBetweenDates(fromDayjsRef, toDayjsRef)

    return {
      goldValue: yearlyTarget.goldValue * targetRatio,
      silverValue: yearlyTarget.silverValue * targetRatio,
      bronzeValue: yearlyTarget.bronzeValue * targetRatio,
    }
  }

  const getSurveyRatingTargetsInRange = (
    startOfWeekRef: MaybeRefOrGetter<Dayjs | undefined | null>,
  ): NationalTargetSurveyRatings | null | undefined => {
    const startOfWeek = toValue(startOfWeekRef)?.startOf('isoWeek')
    if (!startOfWeek) {
      return undefined
    }

    const weeklyTarget = weeklyTargets.value.find(
      (t) => t.weekStartDate.format('YYYY-MM-DD') === startOfWeek.format('YYYY-MM-DD'),
    )

    if (weeklyTarget?.surveyRatings) {
      return weeklyTarget?.surveyRatings
    }

    const year = startOfWeek.year()
    const yearlyTarget = yearlyTargets.value.find((t) => t.year === year)

    if (!yearlyTarget) {
      return undefined
    }

    return yearlyTarget.surveyRatings
  }

  return {
    yearlyTargets,
    weeklyTargets,

    getYearlyTarget,
    createYearlyTarget,
    updateYearlyTarget,

    calculateRatioBetweenDates,
    getTargetsInRange,
    getSurveyRatingTargetsInRange,
  }
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNationalTargetsStore, import.meta.hot))
}
