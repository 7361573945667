import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type { ApiResponse, NullableBoolean } from '/@src/types/utils'
import { toast } from 'vue-sonner'
import type {
  CookId,
  CookSearchParams,
  CookUpdateVOGParams,
  DetailCook,
  SearchCook,
  UpdateAutomaticCookField,
  UpdateCookAdditionalInformation,
  UpdateCookParams,
  CookVogData,
} from '/@src/types/cooks'
import { cloneDeep } from 'lodash'
import { allowReadOnly } from '/@src/utils/axios-utils'
import { rejectWebSocketUpdate, resolveWebSocketUpdate } from '/@src/utils/webSockets'
import { Dayjs } from 'dayjs'
import type { MatchRequestProblemAreaAddress } from '/@src/types/matchRequests'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'

const api = useApi()

const defaultParams = {
  query: '',
  addressQuery: {
    municipality: [],
    city: [],
    borough: [],
    neighborhood: [],
    district: [],
  },
  limit: 30,
  maxDaysOld: null,
  vogFilter: null,
  openBalance: false,
  tags: [],
}

interface State {
  searchResults: SearchCook[]
  searchParams: CookSearchParams
  VOGs: CookVogData
  reportIds: CookId[]
  specificCook?: DetailCook
}

export const useCooksStore = defineStore('cooks', {
  state: (): State => ({
    searchResults: [],
    searchParams: cloneDeep(defaultParams),
    VOGs: {
      new: [],
      requested: [],
      received: [],
    },
    reportIds: [],
    specificCook: undefined,
  }),
  persist: usePersist<State>({
    pick: ['reportIds'],
  }),
  logout: {
    pick: ['searchParams'],
  },
  actions: {
    resetFilter() {
      this.searchParams = cloneDeep(defaultParams)
    },

    async fetchCook(cookId: CookId) {
      return new Promise<boolean>((resolve) => {
        api.get<ApiResponse<DetailCook>>(`admin/cooks/${cookId}`).then(
          (response) => {
            const json = response.data
            this.specificCook = json.data
            resolve(true)
          },
          () => {
            toast.error('Kok ophalen mislukt.')
            resolve(false)
          },
        )
      })
    },

    async fetchUpdateCook(cookId: CookId) {
      return new Promise<false | UpdateCookAdditionalInformation>((resolve) => {
        api
          .get<ApiResponse<UpdateCookAdditionalInformation>>(
            `admin/cooks/${cookId}/update`,
          )
          .then((res) => {
            resolve(res.data.data)
          })

          .catch(() => {
            toast.error('Fout bij ophalen gegevens')
            resolve(false)
          })
      })
    },

    async getVOGStatus() {
      return new Promise<boolean>((resolve) => {
        api
          .get<ApiResponse<CookVogData>>('admin/cooks/vog-status')
          .then((response) => {
            this.VOGs = response.data.data
            resolve(true)
          })
          .catch(() => {
            toast.error('VOG status ophalen mislukt')
            resolve(false)
          })
      })
    },

    async updateVOGStatus(vogParams: CookUpdateVOGParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put<ApiResponse<CookVogData>>('admin/cooks/vog', vogParams)
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            toast.error('VOG status ophalen mislukt')
            resolve(false)
          })
      })
    },

    async updateCook(cookId: CookId, user: UpdateCookParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/cooks/${cookId}/update`, user)
          .then(resolveWebSocketUpdate(resolve, 'Updaten gegevens is gelukt!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten gegevens'))
      })
    },

    async updateMatchComment(cookId: CookId, matchComment: string) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/cooks/${cookId}/update/match-comments`, {
            argument: matchComment,
          })
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten kok'))
      })
    },

    async updateMatchableCookMatchAvailability(
      cookId: CookId,
      availableForMatch: NullableBoolean,
      unavailableForMatchesUntil?: Dayjs,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/cooks/${cookId}/update/available-for-matches`, {
            availableForMatch: availableForMatch,
            unavailableForMatchesUntil: unavailableForMatchesUntil,
          })
          .then(resolveWebSocketUpdate(resolve, 'Match beschikbaarheid is aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten kok'))
      })
    },

    async get(cookIds: CookId[]) {
      return new Promise<SearchCook[] | false>((resolve) => {
        api
          .post<ApiResponse<SearchCook[]>>(
            `admin/cooks/get`,
            {
              ids: cookIds,
            },
            allowReadOnly(),
          )
          .then(
            (response) => {
              const json = response.data
              resolve(json.data)
            },
            () => {
              toast.error('Koks ophalen mislukt.')
              resolve(false)
            },
          )
      })
    },

    async searchCooks() {
      return new Promise<boolean>((resolve) => {
        api
          .post<ApiResponse<SearchCook[]>>(
            'admin/cooks/search',
            this.searchParams,
            allowReadOnly(),
          )
          .then((res) => {
            this.searchResults = res.data.data
            resolve(true)
          })
          .catch(() => {
            toast.error('Fout bij zoeken naar koks')
            resolve(false)
          })
      })
    },

    async updateCookFieldAutomatically(cookId: CookId, field: UpdateAutomaticCookField) {
      return new Promise<boolean>((resolve) => {
        api
          .post(`admin/cooks/${cookId}/update/automatic`, {
            field: field,
          })
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten kok'))
      })
    },

    async exportCooksInAreaToCsv(params: MatchRequestProblemAreaAddress) {
      return new Promise<Blob | false>((resolve) => {
        api
          .post('admin/cooks/export-cooks-in-area', params, {
            responseType: 'blob',
          })
          .then((response) => {
            resolve(new Blob([response.data]))
          })
          .catch(() => {
            toast.error('Fout bij ophalen gegevens')
            resolve(false)
          })
      })
    },
  },
  getters: {
    cook: (state): DetailCook => state.specificCook!,
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCooksStore, import.meta.hot))
}
