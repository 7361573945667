<script setup lang="ts">
import type { ComponentSize } from '/@src/types/elements-ui'
import { getPlaceholderImage } from '/@src/utils/helpers'
import { BlockedUserIcon } from '/@src/models/standardIcons'

export interface VAvatarProps {
  picture?: string | null
  size?: ComponentSize
  forceAnimal?: boolean
  blocked?: boolean
}

const props = withDefaults(defineProps<VAvatarProps>(), {
  picture: undefined,
  size: 'normal',
  color: undefined,
})

const isValidPicture = computed(
  () =>
    props.picture &&
    !props.picture.includes('foodie_') &&
    !props.picture.includes('cook_'),
)

const pictureSrc = computed<string>(() => {
  if (props.forceAnimal) {
    return getPlaceholderImage()
  } else if (isValidPicture.value) {
    return props.picture!
  } else {
    return getPlaceholderImage()
  }
})
</script>

<template>
  <div class="tw-avatar" :class="size && `is-${size}`">
    <slot name="avatar">
      <VIconBox
        v-if="blocked"
        :icon="BlockedUserIcon"
        rounded
        size="large"
        color="warning"
      />
      <img
        v-else-if="picture || forceAnimal"
        class="tw-avatar-image"
        :src="pictureSrc"
        alt=""
      />
    </slot>
  </div>
</template>

<style scoped></style>
