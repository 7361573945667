<script setup lang="ts">
import { computed } from 'vue'
import { useVFieldInjection } from '/@src/composable/useVFieldInjection'
import type { VButtonProps } from '/@src/types/elements-ui'
import { twMerge } from '/@src/styling/ts-merge'

const props = withDefaults(defineProps<VButtonProps>(), {
  color: undefined,
  size: 'normal',
  label: undefined,
  outlined: false,
  rounded: false,
  loading: false,
  width: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  iconOnly: undefined,
})

const $field = useVFieldInjection()

const computedColor = computed(() => {
  let color = 'default'
  if ($field.color?.value && !props.overrideColor) {
    color = $field.color.value
  }

  if (props.color) {
    color = props.color
  }

  let styles = `tw-bg-${color} tw-text-${color}-text tw-border hover:tw-brightness-90`
  if (color === 'default') {
    styles = twMerge(styles, 'hover:tw-border-border-dark-hover hover:tw-brightness-100')
  }

  if (props.outlined) {
    styles = twMerge(
      styles,
      `tw-bg-transparent tw-text-${color} hover:tw-bg-${color} hover:tw-text-${color}-text tw-border-${color}`,
    )
  }

  return `is-${color} ${styles}`
})

const computedStyle = computed(() => {
  if (!props.width || props.width === 'full') {
    return
  }
  return { width: `${props.width}%`, maxWidth: `${props.width}%` }
})
</script>

<template>
  <button
    class="tw-button"
    :class="[
      computedColor,
      size && `is-${size}`,
      rounded && 'tw-rounded-full',
      loading && 'tw-is-loading',
      outlined && 'is-outlined',
      width === 'full' && 'tw-w-full',
      !!iconOnly && 'has-only-icon',
      disabled && 'tw-opacity-50 hover:!tw-brightness-100',
    ]"
    :style="computedStyle"
    :disabled="disabled ?? false"
  >
    <slot name="icon-left">
      <VIcon v-if="iconLeft" :icon="iconLeft" />
    </slot>
    <slot name="icon-only">
      <VIcon v-if="iconOnly" :icon="iconOnly" :size />
    </slot>
    <span v-if="!iconOnly" class="button-label">
      <slot> {{ label }} </slot>
    </span>
    <slot name="icon-right">
      <VIcon v-if="iconRight" :icon="iconRight" />
    </slot>
  </button>
</template>

<style scoped></style>
