import type { OptionsIconMap, VTabsItem } from '/@src/types/elements-ui'
import { FailIcon, SuccessIcon } from '/@src/models/standardIcons'

export const booleanRadioIconOptions: OptionsIconMap<0 | 1>[] = [
  {
    id: 0,
    name: 'Nee',
    icon: FailIcon,
  },
  {
    id: 1,
    name: 'Ja',
    icon: SuccessIcon,
  },
]

export const standardRatings: VTabsItem<number>[] = [
  {
    value: 1,
    label: '1',
    color: 'danger',
  },
  {
    value: 2,
    label: '2',
    color: 'danger',
  },
  {
    value: 3,
    label: '3',
    color: 'danger',
  },
  {
    value: 4,
    label: '4',
    color: 'danger',
  },
  {
    value: 5,
    label: '5',
    color: 'warning',
  },
  {
    value: 6,
    label: '6',
    color: 'warning',
  },
  {
    value: 7,
    label: '7',
    color: 'warning',
  },
  {
    value: 8,
    label: '8',
    color: 'success',
  },
  {
    value: 9,
    label: '9',
    color: 'success',
  },
  {
    value: 10,
    label: '10',
    color: 'success',
  },
]
