import { definePlugin } from '/@src/utils/app-helper'
import dayjs, { Dayjs, type OpUnitType, type ManipulateType } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

// Named this 'dayjs-plugin' to stop it from clashing with the regular Dayjs import

// Load default plugins for dayjs
dayjs.extend(utc)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

declare module 'dayjs' {
  interface Dayjs {
    endOfUTC(unit: OpUnitType): Dayjs
    period(interval: number, unit: ManipulateType, endDate: Dayjs): Dayjs[]
  }
}

export default definePlugin(() => {
  dayjs.extend((option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.endOfUTC = function (unit: OpUnitType) {
      return this.endOf(unit).startOf('day')
    }

    dayjsClass.prototype.period = function (interval, unit, endDate) {
      let currentDate = this.clone()
      let result: Dayjs[] = [currentDate.clone()]

      while (currentDate.isBefore(endDate, unit)) {
        currentDate = currentDate.add(interval, unit)
        result.push(currentDate.clone())
      }

      return result
    }
  })
})
