import {
  AbstractPaymentInfo,
  type NeedsAnyPaymentInfoCheck,
  type PriceFromMatchObject,
  type PriceFromMealObject,
  type RolePaymentInfoCheck,
} from '/@src/utils/payment-info/AbstractPaymentInfo'
import type { UserTypeEnum } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import {
  calculateMealPriceFromMatch,
  calculateTotalPrice,
  calculateTotalPriceFromMeal,
} from '/@src/utils/payments'
import { CollectionIcon } from '/@src/models/standardIcons'
import type { FAIconName, TailwindColor } from '/@src/types/elements-ui'
import { type BasePaymentMethod, PaymentMethodEnum } from '/@src/types/meals'

export class CollectionPaymentInfo extends AbstractPaymentInfo {
  static readonly name = 'Via betaalsysteem'
  static readonly icon = CollectionIcon as FAIconName
  static readonly color = 'info' as TailwindColor

  readonly showCommissionInfo = false

  getName(commissionType: UserTypeEnum, noSuffix: boolean = false): string {
    return 'Via betaalsysteem'
  }
  getIcon(): FAIconName {
    return CollectionIcon as FAIconName
  }
  getColor(): TailwindColor {
    return 'info'
  }

  getBasePaymentMethod(): BasePaymentMethod {
    return PaymentMethodEnum.Collection
  }

  isDonation(): boolean {
    return false
  }

  getCommissionType(commissionType: UserTypeEnum, hasCommission: boolean): string {
    return ''
  }

  roleNeedsPaymentInfo(params: RolePaymentInfoCheck): boolean {
    if (params.price === 0) {
      return false
    } else if (params.role === UserRoleEnum.Cook) {
      return true
    }

    return !params.isPrepaid
  }

  needsAnyPaymentInfo(params: NeedsAnyPaymentInfoCheck): boolean {
    return params.price !== 0
  }

  getPricePerPortionFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number {
    return calculateTotalPriceFromMeal(meal.price, role === UserRoleEnum.Foodie)
  }

  getPriceFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number {
    return calculateTotalPrice(
      meal.price,
      meal.portions,
      meal.amountOfPeople,
      role === UserRoleEnum.Foodie && meal.hasCommission,
    )
  }

  getActualPriceFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number {
    const price = this.getPriceFromMeal(role, meal)

    if (role === UserRoleEnum.Foodie) {
      return -price
    } else {
      return price
    }
  }

  getBalanceMutationFromMatch(role: UserTypeEnum, match: PriceFromMatchObject): number {
    const mealPrice = calculateMealPriceFromMatch(match.price)

    return this.getActualPriceFromMeal(role, {
      price: mealPrice,
      portions: match.portions,
      amountOfPeople: match.amountOfPeople,
      commissionType: match.commissionType,
      hasCommission: match.hasCommission,
    })
  }
}
