<script lang="ts" setup>
import {
  MatchRequestStatus,
  MatchRequestStatusCamelCase,
} from '/@src/types/matchRequests'
import { matchRequestStatusMap } from '/@src/mapping/match-requests'
import { kebabCase } from 'lodash'
import { useStopReasonsStore } from '/@src/stores/stopReasons'
import {
  type StopReason,
  StopReasonCategory,
  type StopReasonId,
  StopReasonNoContactAfterNAEmailed,
  StopReasonOther,
  type StopReasonParams,
  type UpdateStopReasonOrderDisplay,
} from '/@src/types/stop-reason'
import type { OptionsMap, VTabsItem } from '/@src/types/elements-ui'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { z } from 'zod'
import { SaveIcon } from '/@src/models/standardIcons'
import { removeKeysForResetForm } from '/@src/utils/zodUtils'
import { useRouteTabs } from '/@src/composable/useRouteTabs'
import { stopReasonCategoryMap } from '/@src/mapping/stop-reasons'
import { useAutoAnimate } from '@formkit/auto-animate/vue'

definePage({
  meta: {
    roles: 'lead-matchmaker',
  },
})

const stopReasonStore = useStopReasonsStore()

const [autoAnimateParent] = useAutoAnimate()

const isLoading = ref(false)

const selectedStatus = ref<MatchRequestStatusCamelCase>()

const activeStopReasonId = ref<StopReasonId>()
const newStopReasonMode = ref(true)

const updateStopReasonOrderParams = ref<UpdateStopReasonOrderDisplay[]>([])

const stopReasons = computed(() => stopReasonStore.data)

const selectedStopReason = computed(() => {
  if (!stopReasons.value || !selectedStatus.value) {
    return
  }

  return stopReasons.value[selectedStatus.value].find(
    (reason) => reason.id === activeStopReasonId.value,
  )
})

const disableStopReason = computed(
  () =>
    !!selectedStopReason.value?.reason &&
    [StopReasonOther, StopReasonNoContactAfterNAEmailed].includes(
      selectedStopReason.value.reason,
    ),
)

const matchRequestStatus = computed<string | undefined>(() => {
  if (!selectedStatus.value) {
    return
  }

  const kebabCaseStatus = kebabCase(selectedStatus.value) as MatchRequestStatus

  return matchRequestStatusMap[kebabCaseStatus]
})

const visibleStopReasons = computed<OptionsMap[]>(() => {
  if (!stopReasons.value || !selectedStatus.value) {
    return []
  }

  return Object.values(stopReasons.value[selectedStatus.value])
    .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
    .map<OptionsMap>((reason) => ({
      id: reason.id,
      name: reason.isActive ? reason.reason : `${reason.reason} <Non-actief>`,
    }))
})

const labelVisibleStopReasons = computed(() => {
  if (!matchRequestStatus.value) {
    return 'Stopredenen opties zichtbaar'
  }
  return `Stopredenen opties zichtbaar voor status ${matchRequestStatus.value} (${visibleStopReasons.value.length})`
})

const statusList = computed(() => {
  if (stopReasons.value) {
    return Object.keys(stopReasons.value).map((reason) => ({
      id: reason,
      name: matchRequestStatusMap[kebabCase(reason) as MatchRequestStatus],
    }))
  }

  return []
})

const {
  formProps,
  meta,
  handleReset: handleFormReset,
  resetForm,
  handleSubmit,
} = useTypedForm<StopReasonParams>({
  id: 'settings-stop-reason',
  schema: {
    reason: z
      .string()
      .refine(
        (val) =>
          !newStopReasonMode.value ||
          !visibleStopReasons.value?.find(
            (reason) => reason.name.toLowerCase() === val.toLowerCase(),
          ),
        { message: 'Deze stopreden bestaat al' },
      ),
    isActive: z.boolean(),
    category: z.nativeEnum(StopReasonCategory).nullable(),
  },
  initialValues: {
    isActive: true,
  },
})

watch(activeStopReasonId, (newValue) => {
  const signupReference = selectedStopReason.value

  if (newValue && signupReference) {
    newStopReasonMode.value = false
    if (signupReference) {
      resetForm(removeKeysForResetForm(signupReference, ['id']))
    }
  }
})
watch(newStopReasonMode, (newValue) => {
  if (newValue) {
    activeStopReasonId.value = undefined
    resetForm({
      values: {
        reason: undefined,
        isActive: true,
      },
    })
  }
})

watch(selectedStatus, (newValue) => {
  if (newValue && stopReasons.value) {
    activeStopReasonId.value = undefined
    resetStopReasonOrder(newValue)
  }
})
watchEffect(() => {
  // Initialize order
  if (updateStopReasonOrderParams.value.every((s) => s.order === 0)) {
    updateStopReasonOrderParams.value.forEach((s, index) => (s.order = index))
  } else {
    updateStopReasonOrderParams.value.sort((a, b) => (a.order > b.order ? 1 : -1))
  }
})

watch(selectedStopReason, (newValue: StopReason | undefined) => {
  resetForm({
    values: {
      reason: newValue ? newValue.reason : undefined,
      isActive: newValue ? newValue.isActive : true,
    },
  })
})

const onSubmit = handleSubmit(async (values) => {
  let modeLabel = newStopReasonMode.value ? 'toevoegen' : 'aanpassen'
  if (!confirm(`Stopreden '${values.reason}' ${modeLabel}?`)) {
    return
  }

  isLoading.value = true

  let result = false

  if (newStopReasonMode.value) {
    if (!selectedStatus.value) {
      return
    }
    result = await stopReasonStore.createStopReason({
      status: kebabCase(selectedStatus.value) as MatchRequestStatus,
      reason: values.reason,
      isActive: values.isActive,
      category: values.category,
    })
  } else {
    if (!activeStopReasonId.value) {
      return
    }
    result = await stopReasonStore.updateStopReason(activeStopReasonId.value, values)
  }

  isLoading.value = false

  if (result) {
    resetForm({
      values: values,
    })
  }
})

const resetStopReasonOrder = async (status?: MatchRequestStatusCamelCase) => {
  if (!stopReasons.value || !status) {
    return
  }

  updateStopReasonOrderParams.value =
    stopReasons.value[status]?.map<UpdateStopReasonOrderDisplay>((s) => {
      return {
        id: s.id,
        reason: s.reason,
        isActive: s.isActive,
        order: s.sortOrder,
      }
    }) ?? []
}

const updateStopReasonOrder = async () => {
  isLoading.value = true
  await stopReasonStore.updateStopReasonOrder(updateStopReasonOrderParams.value)
  isLoading.value = false
}

const incrementStopReasonOrder = (stopReason: UpdateStopReasonOrderDisplay) => {
  if (stopReason.order >= updateStopReasonOrderParams.value.length - 1) {
    return
  }

  const nextStopReason = updateStopReasonOrderParams.value[stopReason.order + 1]
  stopReason.order++
  nextStopReason.order--
}

const decrementStopReasonOrder = (stopReason: UpdateStopReasonOrderDisplay) => {
  if (stopReason.order === 0) {
    return
  }

  const previousStopReason = updateStopReasonOrderParams.value[stopReason.order - 1]
  stopReason.order--
  previousStopReason.order++
}

type TabKey = 'form' | 'order'
const tabs: VTabsItem<TabKey>[] = [
  {
    value: 'form',
    label: 'Aanmaken of aanpassen',
  },
  {
    value: 'order',
    label: 'Volgorde aanpassen',
  },
]
const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <VCard title="Stopredenen">
    <template #content>
      <div class="columns is-multiline is-align-items-flex-end">
        <div class="column is-one-third is-offset-2">
          <VField label="Kies een status">
            <VMultiselect
              v-model="selectedStatus"
              :loading="isLoading"
              :options="statusList"
              searchable
            />
          </VField>
        </div>

        <div class="column is-one-third">
          <VField :label="labelVisibleStopReasons">
            <VMultiselect :loading="isLoading" :options="visibleStopReasons" searchable />
          </VField>
        </div>
      </div>

      <VTabs v-model:selected="activeTab" :tabs="tabs" align="center" />

      <template v-if="activeTab === 'form'">
        <div class="columns is-multiline is-align-items-flex-end">
          <div class="column is-one-third is-offset-2 is-flex is-justify-content-center">
            <VAnimatedButton
              icon="fa-plus"
              icon-right="fa-plus"
              :color="newStopReasonMode ? 'success' : 'info'"
              override-color
              width="full"
              min-width="90%"
              :time="0.4"
              secondary-color="success"
              :disabled="newStopReasonMode"
              @click="newStopReasonMode = true"
            >
              Nieuwe stopreden aanmaken
            </VAnimatedButton>
          </div>
          <div class="column is-one-third">
            <VField label="Bestaande stopreden aanpassen">
              <VMultiselect
                v-model="activeStopReasonId"
                :options="visibleStopReasons"
                :loading="isLoading"
                allow-empty
                searchable
              />
            </VField>
          </div>

          <VDivider class="column is-full" />
        </div>

        <div v-if="disableStopReason" class="columns is-multiline">
          <div class="column is-full has-text-centered">
            <span class="is-size-3 has-text-weight-semibold has-text-warning">
              Deze stopreden mag niet aangepast worden
            </span>
          </div>
        </div>

        <FormWrapper
          v-else
          v-bind="formProps"
          class="columns is-multiline"
          error-column-class="column is-full"
          v-slot="{ k }"
        >
          <div class="column is-one-third is-offset-2">
            <FormInput :name="k.reason" label="Reden" />
          </div>

          <div class="column is-one-third is-flex is-justify-content-center">
            <FormSwitchBlock
              :name="k.isActive"
              label="Stopreden kan geselecteerd worden?"
              left-label="Nee"
              right-label="Ja"
              size="medium"
              centered
            />
          </div>

          <div class="column is-offset-one-third is-one-third">
            <FormMultiselect
              :name="k.category"
              label="Categorie"
              :options="stopReasonCategoryMap"
              allow-empty
              clear-to-null
            />
          </div>

          <VDivider class="column is-full" />

          <div class="column is-offset-2 is-one-third is-flex is-justify-content-center">
            <ResetButton
              :disabled="!meta.dirty"
              :loading="isLoading"
              @reset="handleFormReset"
            >
              Reset
            </ResetButton>
          </div>
          <div class="column is-one-third is-flex is-justify-content-center">
            <VButton
              :icon-right="SaveIcon"
              color="success"
              width="full"
              :disabled="!meta.dirty"
              :loading="isLoading"
              @click.prevent="onSubmit"
            >
              Opslaan
            </VButton>
          </div>
        </FormWrapper>
      </template>

      <template v-else-if="activeTab === 'order'">
        <div
          class="columns is-multiline"
          :class="updateStopReasonOrderParams.length === 0 && 'is-justify-content-center'"
        >
          <span
            v-if="updateStopReasonOrderParams.length === 0"
            class="is-size-3 has-text-weight-semibold has-text-warning"
          >
            Deze status heeft geen stopredenen
          </span>

          <template v-else>
            <div
              class="column is-offset-2 is-one-third is-flex is-justify-content-center"
            >
              <ResetButton
                :loading="isLoading"
                @reset="resetStopReasonOrder(selectedStatus)"
              >
                Reset
              </ResetButton>
            </div>
            <div class="column is-one-third is-flex is-justify-content-center">
              <VButton
                :icon-right="SaveIcon"
                color="success"
                width="full"
                :loading="isLoading"
                @click.prevent="updateStopReasonOrder"
              >
                Opslaan
              </VButton>
            </div>

            <div class="column is-offset-2 is-two-thirds">
              <ul ref="autoAnimateParent">
                <li
                  v-for="stopReason in updateStopReasonOrderParams"
                  :key="`order-${stopReason.id}`"
                  class="is-flex is-justify-content-space-between"
                >
                  <span class="has-text-weight-semibold">
                    {{ stopReason.reason }}
                    <template v-if="!stopReason.isActive"> {niet actief} </template>
                  </span>
                  <div class="is-flex">
                    <VIconBox
                      icon="fa-chevron-up"
                      :disabled="stopReason.order === 0"
                      size="tiny"
                      color="info"
                      @click="decrementStopReasonOrder(stopReason)"
                    />
                    <VIconBox
                      icon="fa-chevron-down"
                      :disabled="
                        stopReason.order === updateStopReasonOrderParams.length - 1
                      "
                      size="tiny"
                      color="info"
                      @click="incrementStopReasonOrder(stopReason)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </template>
    </template>
  </VCard>
</template>

<style lang="scss" scoped>
.label {
  font-family: var(--font);
  font-size: 0.9rem;
}
</style>
