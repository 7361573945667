import type { FocusMunicipalityTargetStatus } from '/@src/types/focus-municipalities'
import {
  FocusMunicipalityTargetStatusEnum,
  FocusMunicipalityType,
} from '/@src/types/focus-municipalities'
import type { FAIconName, TailwindColor } from '/@src/types/elements-ui'
import type { MaybeRefOrGetter } from 'vue'
import type { NormalisedYearlyTargetValues } from '/@src/types/national-targets'
import { match } from 'ts-pattern'
import { calculateNormalisedTarget } from '/@src/utils/reporting'
import dayjs, { Dayjs } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

interface FocusMunicipalityNameParam {
  name: string
  belongsToMunicipality: string | null
}

export function getMunicipalityId(
  municipalityRef: MaybeRefOrGetter<FocusMunicipalityNameParam>,
): string {
  const municipality = toValue(municipalityRef)
  if (municipality.belongsToMunicipality) {
    return `${municipality.belongsToMunicipality.toLowerCase()}-${municipality.name.toLowerCase()}`
  } else {
    return municipality.name.toLowerCase()
  }
}

export function getMunicipalityName(
  municipalityRef: MaybeRefOrGetter<FocusMunicipalityNameParam>,
): string {
  const municipality = toValue(municipalityRef)
  if (municipality.belongsToMunicipality) {
    return `${municipality.belongsToMunicipality}-${municipality.name}`
  } else {
    return municipality.name
  }
}

export const focusMunicipalityTypeIcons: Record<FocusMunicipalityType, FAIconName> = {
  [FocusMunicipalityType.Municipality]: 'fa-city',
  [FocusMunicipalityType.Borough]: 'fa-tree-city',
  [FocusMunicipalityType.Neighborhood]: 'fa-school',
  [FocusMunicipalityType.District]: 'fa-shop',
}

export function getFocusMunicipalityTargetStatus(
  value: number,
  target: NormalisedYearlyTargetValues | number,
  allowExtra: boolean = false,
): FocusMunicipalityTargetStatusEnum {
  if (typeof target === 'number') {
    target = {
      goldValue: target * 1.5,
      silverValue: target * 1.25,
      bronzeValue: target,
    } satisfies NormalisedYearlyTargetValues
  }

  if (value === 0) {
    return FocusMunicipalityTargetStatusEnum.Red
  } else if (value >= target.goldValue && allowExtra) {
    return FocusMunicipalityTargetStatusEnum.Gold
  } else if (value >= target.silverValue && allowExtra) {
    return FocusMunicipalityTargetStatusEnum.Silver
  } else if (value >= target.bronzeValue) {
    return FocusMunicipalityTargetStatusEnum.Bronze
  } else if (
    value < target.bronzeValue &&
    value >= Math.min(target.bronzeValue * 0.9, target.bronzeValue - 3)
  ) {
    return FocusMunicipalityTargetStatusEnum.Orange
  } else {
    return FocusMunicipalityTargetStatusEnum.Red
  }
}

export function getFocusMunicipalityTargetTailwindColor(
  valueRef: MaybeRefOrGetter<number | undefined>,
  targetRef: MaybeRefOrGetter<NormalisedYearlyTargetValues | number | undefined>,
): TailwindColor | undefined {
  const value = toValue(valueRef)
  const target = toValue(targetRef)

  if (!target) {
    return undefined
  }

  if (!value) {
    return 'danger'
  }

  const targetStatus = getFocusMunicipalityTargetStatus(value, target)

  return match(targetStatus)
    .returnType<TailwindColor>()
    .with(
      FocusMunicipalityTargetStatusEnum.Gold,
      FocusMunicipalityTargetStatusEnum.Silver,
      FocusMunicipalityTargetStatusEnum.Bronze,
      () => 'success',
    )
    .with(FocusMunicipalityTargetStatusEnum.Orange, () => 'warning')
    .with(FocusMunicipalityTargetStatusEnum.Red, () => 'danger')
    .otherwise(() => 'info')
}

export function getFocusMunicipalityTargetColor(
  valueRef: MaybeRefOrGetter<number | undefined>,
  targetRef: MaybeRefOrGetter<NormalisedYearlyTargetValues | number | undefined>,
) {
  const value = toValue(valueRef)
  const target = toValue(targetRef)

  if (!target) {
    return undefined
  }

  if (value == null) {
    return 'has-background-danger-light'
  }

  const bulmaColor = getFocusMunicipalityTargetTailwindColor(valueRef, targetRef)

  return `has-background-${bulmaColor}`
}

export function getFocusMunicipalityTargetMedalColor(
  valueRef: MaybeRefOrGetter<number | undefined>,
  targetRef: MaybeRefOrGetter<NormalisedYearlyTargetValues | number | undefined>,
) {
  const value = toValue(valueRef)
  const target = toValue(targetRef)

  if (!target) {
    return undefined
  }

  if (!value) {
    return 'has-background-danger'
  }

  const targetStatus = getFocusMunicipalityTargetStatus(value, target, true)

  return match(targetStatus)
    .with(FocusMunicipalityTargetStatusEnum.Gold, () => 'has-background-gold')
    .with(FocusMunicipalityTargetStatusEnum.Silver, () => 'has-background-silver')
    .with(FocusMunicipalityTargetStatusEnum.Bronze, () => 'has-background-bronze')
    .with(FocusMunicipalityTargetStatusEnum.Orange, () => 'has-background-warning')
    .with(FocusMunicipalityTargetStatusEnum.Red, () => 'has-background-danger')
    .otherwise(() => 'has-background-info')
}

function getNormalisedTarget(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
): number | undefined {
  if (!targetStatus?.totalTarget) {
    return undefined
  }

  let endDate: Dayjs
  if (targetStatus.activeUntil.isBefore(dayjs(), 'day')) {
    endDate = targetStatus.activeUntil
  } else {
    endDate = dayjs()
  }

  return calculateNormalisedTarget(
    targetStatus.totalTarget,
    targetStatus.activeFrom,
    endDate,
  )
}

export function getTargetStatus(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
): FocusMunicipalityTargetStatusEnum | undefined {
  if (targetStatus?.normalisedTarget) {
    return getFocusMunicipalityTargetStatus(
      targetStatus.matchExpected,
      targetStatus.normalisedTarget,
    )
  }

  const target = getNormalisedTarget(targetStatus)

  if (targetStatus && target) {
    return getFocusMunicipalityTargetStatus(targetStatus.matchExpected, target)
  }

  return undefined
}

export function getTargetStatusTailwindColor(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
) {
  if (targetStatus?.normalisedTarget) {
    return getFocusMunicipalityTargetTailwindColor(
      targetStatus.matchExpected,
      targetStatus.normalisedTarget,
    )
  }

  const target = getNormalisedTarget(targetStatus)

  if (targetStatus && target) {
    return getFocusMunicipalityTargetTailwindColor(targetStatus.matchExpected, target)
  }

  return undefined
}

export function getTargetStatusColorClass(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
) {
  if (targetStatus?.normalisedTarget) {
    return getFocusMunicipalityTargetColor(
      targetStatus.matchExpected,
      targetStatus.normalisedTarget,
    )
  }

  const target = getNormalisedTarget(targetStatus)

  if (targetStatus && target) {
    return getFocusMunicipalityTargetColor(targetStatus.matchExpected, target)
  }

  return undefined
}

export function getTargetStatusCellClass(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
) {
  if (
    targetStatus?.matchExpected &&
    targetStatus?.totalTarget &&
    targetStatus.matchExpected >= targetStatus.totalTarget
  ) {
    return 'has-background-purple px-1 has-border-radius'
  }

  if (targetStatus?.normalisedTarget) {
    const colorClass = getFocusMunicipalityTargetColor(
      targetStatus.matchExpected,
      targetStatus.normalisedTarget,
    )
    return `${colorClass} px-1 has-border-radius`
  }

  const target = getNormalisedTarget(targetStatus)

  if (targetStatus && target) {
    const colorClass = getFocusMunicipalityTargetColor(targetStatus.matchExpected, target)
    return `${colorClass} px-1 has-border-radius`
  }
}

export const focusMunicipalityTargetStatusMapping: Record<
  FocusMunicipalityTargetStatusEnum,
  string
> = {
  [FocusMunicipalityTargetStatusEnum.Gold]: 'Gouden',
  [FocusMunicipalityTargetStatusEnum.Silver]: 'Zilveren',
  [FocusMunicipalityTargetStatusEnum.Bronze]: 'Groene', // Bronze doubles as Green in this case
  [FocusMunicipalityTargetStatusEnum.Orange]: 'Oranje',
  [FocusMunicipalityTargetStatusEnum.Red]: 'Rode',
}
