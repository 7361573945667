<script setup lang="ts">
import { capitalize, getRole, toCapitalizedWords } from '/@src/utils/helpers'
import { useAuthStore } from '/@src/stores/auth'
import { useReportingStore } from '/@src/stores/reporting'
import { useHead } from '@unhead/vue'
import { UserRoleEnum } from '/@src/types/users'
import { municipalitiesAreEqualForStatistics } from '/@src/utils/reporting'
import { computed } from 'vue'

definePage({
  meta: {
    requiresMunicipalityRole: true,
  },
})

const authStore = useAuthStore()
const reportingStore = useReportingStore()

const route = useRoute('/reporting/municipalities/[municipality]')
const router = useRouter()

const municipalityLabel = computed(() => {
  const municipality = route.params.municipality
  if (!municipality) {
    return ''
  } else if (municipality === 'all-focus') {
    return 'Lokaal'
  } else if (municipality === 'all-non-focus') {
    return 'Landelijk'
  } else {
    return `Gemeente ${capitalize(municipality)}`
  }
})

useHead({
  title: `Rapportage ${municipalityLabel.value} | Thuisgekookt Admin`,
})

const reportingUserParam = computed(() => {
  const reportingRole = getRole(authStore.user, UserRoleEnum.Reporting)

  if (!reportingRole) {
    return undefined
  }

  return reportingRole.param
})

const getMunicipalityStatistics = async () => {
  if (
    reportingUserParam.value &&
    reportingUserParam.value.toLowerCase() !== route.params.municipality
  ) {
    await router.push({
      name: '/reporting/municipalities/[municipality]',
      params: { municipality: reportingUserParam.value.toLowerCase() },
    })
  } else if (route.params.municipality) {
    const capitalizedMunicipality = toCapitalizedWords(route.params.municipality, '-')
    await reportingStore.getMunicipalityStatistics(capitalizedMunicipality)
  }
}

watch(
  () => route.fullPath,
  () => {
    if (
      route.params.municipality &&
      !municipalitiesAreEqualForStatistics(
        route.params.municipality,
        reportingStore.municipalityStatistics?.municipality,
      )
    ) {
      getMunicipalityStatistics()
    }
  },
)

onMounted(async () => {
  await getMunicipalityStatistics()

  if (route.name === '/reporting/municipalities/[municipality]') {
    await router.push({
      name: '/reporting/municipalities/[municipality]/match-requests',
    })
  }
})
</script>

<template>
  <RouterView v-slot="{ Component }">
    <Transition name="fade-fast" mode="out-in">
      <Component :is="Component" />
    </Transition>
  </RouterView>
</template>

<style scoped lang="scss"></style>
