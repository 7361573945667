<script setup lang="ts">
import { computed } from 'vue'
import type {
  ComponentClass,
  ComponentSize,
  ExtendedTailwindColor,
  FAFontSize,
  FAIconName,
  FAIconPack,
  TailwindColor,
} from '/@src/types/elements-ui'
import { twMerge } from '/@src/styling/ts-merge'

interface IconProps {
  icon: FAIconName
  iconPack?: FAIconPack
  iconClass?: ComponentClass
  size?: ComponentSize | 'tiny'
  fontAwesomeIconSize?: FAFontSize
  color?: ExtendedTailwindColor
  backgroundColor?: TailwindColor
  text?: string
  disableIconClass?: boolean
  overrideClickable?: boolean
  forceClickable?: boolean
  forceColor?: boolean
}

const props = withDefaults(defineProps<IconProps>(), {
  iconPack: 'fas',
  size: undefined,
  fontAwesomeIconSize: undefined,
  color: undefined,
  text: undefined,
  backgroundColor: undefined,
})

const computedIconSize = computed(() => {
  if (props.size) {
    return `is-${props.size}`
  }
  return 'is-normal'
})

const computedFontAwesomeIconSize = computed(() => {
  if (props.fontAwesomeIconSize) {
    return `fa-${props.fontAwesomeIconSize}`
  } else if (props.size == 'large') {
    return 'fa-2x'
  } else if (props.size == 'medium') {
    return 'fa-lg'
  }
  return undefined
})

const computedIconColor = computed(() => {
  if (props.color) {
    if (props.forceColor) {
      return `!tw-text-${props.color}`
    } else {
      return `tw-text-${props.color}`
    }
  }
  return undefined
})

const computedBackgroundColor = computed(() => {
  if (props.backgroundColor) {
    return twMerge(
      `tw-bg-${props.backgroundColor} tw-text-${props.backgroundColor}-text`,
      props.color ? `tw-text-${props.color}` : '',
    )
  }
  return undefined
})

const attrs = useAttrs()
const computedClickable = computed(
  () =>
    ((!!attrs.onClick && !props.overrideClickable) || props.forceClickable) &&
    'tw-cursor-pointer',
)
</script>

<template>
  <div v-if="text || $slots['text']" class="tw-icon-text" :class="computedClickable">
    <span
      :class="[!disableIconClass && 'tw-icon', computedIconSize, computedBackgroundColor]"
    >
      <i
        :class="[
          iconPack,
          icon,
          iconClass,
          color && computedIconColor,
          computedFontAwesomeIconSize,
          computedClickable,
        ]"
      ></i>
    </span>
    <slot name="text">
      <span class="tw-ml-1">
        {{ text }}
      </span>
    </slot>
  </div>
  <span
    v-else
    :class="[
      !disableIconClass && 'tw-icon',
      computedIconSize,
      computedClickable,
      computedBackgroundColor,
    ]"
  >
    <i
      :class="[
        iconPack,
        icon,
        iconClass,
        color && computedIconColor,
        computedFontAwesomeIconSize,
        computedClickable,
      ]"
    ></i>
  </span>
</template>

<style scoped></style>
