<script setup lang="ts">
import { useWebSocket } from '/@src/composable/useWebSocket'
import { toast } from 'vue-sonner'

type LockdownEmits = {
  close: []
}

interface LockdownProps {
  reason?: string
}

const emits = defineEmits<LockdownEmits>()
const props = defineProps<LockdownProps>()

const isOpen = defineModel<boolean>('open', { required: true })

const { start, stop } = useTimeoutFn(() => {
  stop()
  closeModal()
}, 30000)

useWebSocket({
  event: 'LockDownAdmin',
  callback: () => {
    stop()
    start()
  },
  cancelOnUnmounted: false,
})

const closeModal = () => {
  toast.success(
    'De problemen zijn weer opgelost :). Het kan zijn dat je de admin moet herladen, houd de berichten in Slack in de gaten!',
    {
      duration: 9999,
    },
  )
  stop()
  emits('close')
  isOpen.value = false
}

const fakeCloseModal = () => {}
</script>

<template>
  <VModal v-model:open="isOpen" title="Er is iets stuk" @close="fakeCloseModal">
    <template #content>
      <section class="tw-prose tw-max-w-none">
        <p>
          Hoi! Er is helaas iets heel erg stuk, daarom hebben we de admin tijdelijk even
          uitgezet.
        </p>
        <p v-if="reason">Dit is de reden: {{ reason }}</p>
        <p>
          Volg de berichten op Slack in #bugs of #general voor updates. Zie je nog geen
          berichten? Dan is het probleem zo erg dat we het eerst moeten fixen. We proberen
          het zo snel mogelijk op te lossen! 🥲
        </p>
      </section>
    </template>
    <template #cancel>
      <span></span>
    </template>
    <template #cross>
      <span></span>
    </template>
  </VModal>
</template>

<style scoped></style>
