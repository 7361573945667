<script setup lang="ts">
import type {
  DashboardSlot,
  DashboardWidgetKey,
  DashboardWidgetProps,
  DashboardWidgetTranslationDirection,
} from '/@src/types/dashboard'
import {
  dashboardWidgetMap,
  dashboardWidgetSelectorMap,
  getDashboardWidgetDefaultSettings,
} from '/@src/mapping/dashboard'
import { useDashboardStore } from '/@src/stores/dashboard'
import { match } from 'ts-pattern'
import type { UserId } from '/@src/types/users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { userRolePermissionsMap } from '/@src/utils/entity-checks'

type DashboardGridItemEmits = {
  move: [direction: DashboardWidgetTranslationDirection]
  scale: [direction: DashboardWidgetTranslationDirection]
  select: []
  close: []
}

interface DashboardGridItemProps {
  slot: DashboardSlot
  componentKey: DashboardWidgetKey | undefined | null
  editing: boolean
  forceHidden?: boolean
  isOverlay: boolean
  userId: UserId | null
}

const emits = defineEmits<DashboardGridItemEmits>()
const props = defineProps<DashboardGridItemProps>()

const dashboardStore = useDashboardStore()
const adminUserStore = useAdminUsersStore()

const activeUserId = computed(() => props.userId ?? adminUserStore.adminUser?.userId)

const defaultWidget = computed<DashboardWidgetProps>(() =>
  getDashboardWidgetDefaultSettings(props.componentKey),
)

const componentIsNotAllowed = computed(() => {
  if (
    !props.componentKey ||
    props.componentKey === 'empty' ||
    props.componentKey === 'hidden-empty'
  ) {
    return false
  }

  let categories = dashboardWidgetSelectorMap[props.componentKey].category
  let allowedFromCategories = true
  if (categories) {
    if (!Array.isArray(categories)) {
      categories = [categories]
    }

    allowedFromCategories = categories.some(
      (c) => toValue(userRolePermissionsMap[c]) ?? true,
    )
  }

  return (
    !allowedFromCategories ||
    toValue(dashboardWidgetSelectorMap[props.componentKey].disabled)
  )
})

watch(
  () => props.componentKey,
  (newValue) => {
    dashboardStore.getOrCreateWidgetSettings(newValue)
  },
  { immediate: true },
)

const directionArray = ['right', 'left', 'down', 'up'] as const

const acceptableMoves = computed(() => {
  const loadout = dashboardStore.currentLoadout
  if (!loadout) {
    return []
  }

  return match(props.slot)
    .returnType<DashboardWidgetTranslationDirection[]>()
    .with('slotA', () => {
      let rightMoveAcceptable = true
      let bottomMoveAcceptable = true
      if (widget.value.size === 'horizontal') {
        rightMoveAcceptable = false
      } else if (widget.value.size === 'vertical') {
        bottomMoveAcceptable = false
      }

      const rightWidget = loadout.loadout.position['slotB']
      const rightSettings = dashboardStore.getOrCreateWidgetSettings(rightWidget)
      if (rightWidget === 'hidden-empty' || rightSettings?.hidden) {
        rightMoveAcceptable = false
      }

      const bottomWidget = loadout.loadout.position['slotC']
      const bottomSettings = dashboardStore.getOrCreateWidgetSettings(bottomWidget)
      if (bottomWidget === 'hidden-empty' || bottomSettings?.hidden) {
        bottomMoveAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (rightMoveAcceptable) {
        result.push('right')
      }
      if (bottomMoveAcceptable) {
        result.push('down')
      }

      return result
    })
    .with('slotB', () => {
      let leftMoveAcceptable = true
      let bottomMoveAcceptable = true
      if (widget.value.size === 'horizontal') {
        leftMoveAcceptable = false
      } else if (widget.value.size === 'vertical') {
        bottomMoveAcceptable = false
      }

      const leftWidget = loadout.loadout.position['slotA']
      const leftSettings = dashboardStore.getOrCreateWidgetSettings(leftWidget)
      if (leftWidget === 'hidden-empty' || leftSettings?.hidden) {
        leftMoveAcceptable = false
      }

      const bottomWidget = loadout.loadout.position['slotD']
      const bottomSettings = dashboardStore.getOrCreateWidgetSettings(bottomWidget)
      if (bottomWidget === 'hidden-empty' || bottomSettings?.hidden) {
        bottomMoveAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (leftMoveAcceptable) {
        result.push('left')
      }
      if (bottomMoveAcceptable) {
        result.push('down')
      }

      return result
    })
    .with('slotC', () => {
      let rightMoveAcceptable = true
      let upMoveAcceptable = true
      if (widget.value.size === 'horizontal') {
        rightMoveAcceptable = false
      } else if (widget.value.size === 'vertical') {
        upMoveAcceptable = false
      }

      const rightWidget = loadout.loadout.position['slotD']
      const rightSettings = dashboardStore.getOrCreateWidgetSettings(rightWidget)
      if (rightWidget === 'hidden-empty' || rightSettings?.hidden) {
        rightMoveAcceptable = false
      }

      const topWidget = loadout.loadout.position['slotA']
      const topSettings = dashboardStore.getOrCreateWidgetSettings(topWidget)
      if (topWidget === 'hidden-empty' || topSettings?.hidden) {
        upMoveAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (rightMoveAcceptable) {
        result.push('right')
      }
      if (upMoveAcceptable) {
        result.push('up')
      }

      return result
    })
    .with('slotD', () => {
      let leftMoveAcceptable = true
      let upMoveAcceptable = true
      if (widget.value.size === 'horizontal') {
        leftMoveAcceptable = false
      } else if (widget.value.size === 'vertical') {
        upMoveAcceptable = false
      }

      const leftWidget = loadout.loadout.position['slotC']
      const leftSettings = dashboardStore.getOrCreateWidgetSettings(leftWidget)
      if (leftWidget === 'hidden-empty' || leftSettings?.hidden) {
        leftMoveAcceptable = false
      }

      const topWidget = loadout.loadout.position['slotB']
      const topSettings = dashboardStore.getOrCreateWidgetSettings(topWidget)
      if (topWidget === 'hidden-empty' || topSettings?.hidden) {
        upMoveAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (leftMoveAcceptable) {
        result.push('left')
      }
      if (upMoveAcceptable) {
        result.push('up')
      }

      return result
    })
    .exhaustive()
})

const acceptableScales = computed(() => {
  const loadout = dashboardStore.currentLoadout
  if (!loadout) {
    return []
  }

  return match(props.slot)
    .returnType<DashboardWidgetTranslationDirection[]>()
    .with('slotA', () => {
      let rightScaleAcceptable = true
      let downScaleAcceptable = true

      if (widget.value.size === 'horizontal') {
        downScaleAcceptable = false
      } else if (widget.value.size === 'vertical') {
        rightScaleAcceptable = false
      }

      const rightWidget = loadout.loadout.position['slotB']
      const rightSettings = dashboardStore.getOrCreateWidgetSettings(rightWidget)
      if (
        ((rightWidget === 'hidden-empty' || rightSettings?.hidden) &&
          widget.value?.size !== 'horizontal') ||
        (rightSettings && rightSettings.size !== 'single')
      ) {
        rightScaleAcceptable = false
      }

      const bottomWidget = loadout.loadout.position['slotC']
      const bottomSettings = dashboardStore.getOrCreateWidgetSettings(bottomWidget)
      if (
        ((bottomWidget === 'hidden-empty' || bottomSettings?.hidden) &&
          widget.value?.size !== 'vertical') ||
        (bottomSettings && bottomSettings.size !== 'single')
      ) {
        downScaleAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (rightScaleAcceptable) {
        if (widget.value.size === 'horizontal') {
          result.push('left')
        } else {
          result.push('right')
        }
      }
      if (downScaleAcceptable) {
        if (widget.value.size === 'vertical') {
          result.push('up')
        } else {
          result.push('down')
        }
      }

      return result
    })
    .with('slotB', () => {
      let leftScaleAcceptable = true
      let downScaleAcceptable = true

      if (widget.value.size === 'horizontal') {
        downScaleAcceptable = false
      } else if (widget.value.size === 'vertical') {
        leftScaleAcceptable = false
      }

      const leftWidget = loadout.loadout.position['slotA']
      const leftSettings = dashboardStore.getOrCreateWidgetSettings(leftWidget)
      if (
        ((leftWidget === 'hidden-empty' || leftSettings?.hidden) &&
          widget.value?.size !== 'horizontal') ||
        (leftSettings && leftSettings.size !== 'single')
      ) {
        leftScaleAcceptable = false
      }

      const bottomWidget = loadout.loadout.position['slotD']
      const bottomSettings = dashboardStore.getOrCreateWidgetSettings(bottomWidget)
      if (
        ((bottomWidget === 'hidden-empty' || bottomSettings?.hidden) &&
          widget.value?.size !== 'vertical') ||
        (bottomSettings && bottomSettings.size !== 'single')
      ) {
        downScaleAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (leftScaleAcceptable) {
        result.push('left')
      }
      if (downScaleAcceptable) {
        if (widget.value.size === 'vertical') {
          result.push('up')
        } else {
          result.push('down')
        }
      }

      return result
    })
    .with('slotC', () => {
      let rightScaleAcceptable = true
      let upScaleAcceptable = true

      if (widget.value.size === 'horizontal') {
        upScaleAcceptable = false
      } else if (widget.value.size === 'vertical') {
        rightScaleAcceptable = false
      }

      const rightWidget = loadout.loadout.position['slotD']
      const rightSettings = dashboardStore.getOrCreateWidgetSettings(rightWidget)
      if (
        ((rightWidget === 'hidden-empty' || rightSettings?.hidden) &&
          widget.value?.size !== 'horizontal') ||
        (rightSettings && rightSettings.size !== 'single')
      ) {
        rightScaleAcceptable = false
      }

      const topWidget = loadout.loadout.position['slotA']
      const topSettings = dashboardStore.getOrCreateWidgetSettings(topWidget)
      if (
        ((topWidget === 'hidden-empty' || topSettings?.hidden) &&
          widget.value?.size !== 'vertical') ||
        (topSettings && topSettings.size !== 'single')
      ) {
        upScaleAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (rightScaleAcceptable) {
        if (widget.value.size === 'horizontal') {
          result.push('left')
        } else {
          result.push('right')
        }
      }
      if (upScaleAcceptable) {
        result.push('up')
      }

      return result
    })
    .with('slotD', () => {
      let leftScaleAcceptable = true
      let upScaleAcceptable = true

      if (widget.value.size === 'horizontal') {
        upScaleAcceptable = false
      } else if (widget.value.size === 'vertical') {
        leftScaleAcceptable = false
      }

      const leftWidget = loadout.loadout.position['slotC']
      const leftSettings = dashboardStore.getOrCreateWidgetSettings(leftWidget)
      if (
        ((leftWidget === 'hidden-empty' || leftSettings?.hidden) &&
          widget.value?.size !== 'horizontal') ||
        (leftSettings && leftSettings.size !== 'single')
      ) {
        leftScaleAcceptable = false
      }

      const topWidget = loadout.loadout.position['slotB']
      const topSettings = dashboardStore.getOrCreateWidgetSettings(topWidget)
      if (
        ((topWidget === 'hidden-empty' || topSettings?.hidden) &&
          widget.value?.size !== 'vertical') ||
        (topSettings && topSettings.size !== 'single')
      ) {
        upScaleAcceptable = false
      }

      const result: DashboardWidgetTranslationDirection[] = []
      if (leftScaleAcceptable) {
        result.push('left')
      }
      if (upScaleAcceptable) {
        result.push('up')
      }

      return result
    })
    .exhaustive()
})

const widget = computed<DashboardWidgetProps>(() => {
  const loadout = dashboardStore.currentLoadout?.loadout
  if (!loadout || !props.componentKey || props.componentKey === 'empty') {
    return defaultWidget.value
  }

  if (props.componentKey === 'hidden-empty') {
    return {
      ...defaultWidget.value,
      hidden: true,
    }
  }

  return (
    loadout.settings.find((w) => w.componentKey === props.componentKey) ??
    defaultWidget.value
  )
})

const move = (direction: DashboardWidgetTranslationDirection) => {
  emits('move', direction)
}

const scale = (direction: DashboardWidgetTranslationDirection) => {
  emits('scale', direction)
}
</script>

<template>
  <div
    v-if="componentKey && !widget.hidden"
    :id="`slot-${slot}${isOverlay ? '-overlay' : ''}`"
    class="grid-item"
    :class="[
      slot,
      widget.size,
      editing && 'is-editing',
      componentIsNotAllowed && 'is-not-allowed',
    ]"
  >
    <template v-if="editing">
      <div
        v-if="componentKey !== 'empty' && componentKey !== 'hidden-empty'"
        class="cover"
      />
      <div class="header is-flex is-flex-direction-row">
        <template v-if="componentKey !== 'empty' && componentKey !== 'hidden-empty'">
          <template v-for="direction in directionArray">
            <VIcon
              v-if="acceptableMoves.includes(direction)"
              :id="`slot-${slot}-move-${direction}`"
              class="move-icon"
              :class="direction"
              :icon="`fa-arrow-${direction}`"
              size="large"
              @click="move(direction)"
            />
          </template>
          <template v-for="direction in directionArray">
            <div
              v-if="acceptableScales.includes(direction)"
              class="scale-box"
              :class="direction"
            >
              <div class="before" />
              <VIcon
                :id="`slot-${slot}-scale-${direction}`"
                class="scale-icon"
                :icon="`fa-angles-${direction}`"
                size="small"
                color="info"
                @click="scale(direction)"
              />
              <div class="after" />
            </div>
          </template>

          <div
            :id="`slot-${slot}-add`"
            class="widget-button change"
            @click="emits('select')"
          >
            <div class="path" />
          </div>

          <div
            :id="`slot-${slot}-close`"
            class="widget-button close"
            @click="emits('close')"
          >
            <div class="path" />
          </div>
        </template>
        <template v-else>
          <div
            :id="`slot-${slot}-add`"
            class="widget-button change empty"
            @click="emits('select')"
          >
            <div class="path" />
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="componentIsNotAllowed">
      <div class="cover" />

      <div class="header is-flex is-flex-direction-row">
        <VIcon class="not-allowed-icon" icon="fa-ban" size="small" color="danger" />
        <span class="not-allowed-text">
          Deze widget is niet toegestaan voor jouw rol, kies een andere.
        </span>

        <VButton color="info" class="not-allowed-button" @click="emits('select')">
          Andere widget kiezen
        </VButton>
      </div>
    </template>
    <div class="content">
      <Transition name="fade" mode="out-in">
        <component
          :is="dashboardWidgetMap[widget.componentKey]"
          :size="widget.size"
          :hidden="widget.hidden"
          :editing
          :cell-slot="slot"
          :user-id="activeUserId"
        />
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
