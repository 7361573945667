import { CollectionPaymentInfo } from '/@src/utils/payment-info/CollectionPaymentInfo'
import type { UserTypeEnum } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import type { FAIconName, TailwindColor } from '/@src/types/elements-ui'
import { DonationIcon } from '/@src/models/standardIcons'
import type {
  NeedsAnyPaymentInfoCheck,
  PriceFromMatchObject,
  RolePaymentInfoCheck,
} from '/@src/utils/payment-info/AbstractPaymentInfo'
import type { IsoDay } from '/@src/types/utils'
import { calculateMealPriceFromMatch, calculateTotalPrice } from '/@src/utils/payments'

interface MatchFreeParams {
  role: UserTypeEnum
  price: number
  portions: number
  amountOfPeople: number
  daysOfWeek: IsoDay[]
}

export class DonationCollectionPaymentInfo extends CollectionPaymentInfo {
  private matchIsFreeForRole(params: MatchFreeParams): boolean {
    const matchPrice = calculateTotalPrice(
      params.price,
      params.portions,
      params.amountOfPeople,
      true,
    )

    return (
      (params.daysOfWeek.length === 1 &&
        params.role === UserRoleEnum.Foodie &&
        params.portions === 1 &&
        matchPrice <= 6.6) ||
      params.role === UserRoleEnum.Cook
    )
  }

  getName(commissionType: UserTypeEnum, noSuffix: boolean = false): string {
    return 'Donatie (via betaalsysteem)'
  }

  getIcon(): FAIconName {
    return DonationIcon as FAIconName
  }

  getColor(): TailwindColor {
    return 'info'
  }

  isDonation(): boolean {
    return true
  }

  roleNeedsPaymentInfo(params: RolePaymentInfoCheck): boolean {
    if (this.matchIsFreeForRole(params)) {
      return false
    }
    return super.roleNeedsPaymentInfo(params)
  }

  needsAnyPaymentInfo(params: NeedsAnyPaymentInfoCheck): boolean {
    if (
      this.matchIsFreeForRole({
        ...params,
        role: UserRoleEnum.Cook,
      }) &&
      this.matchIsFreeForRole({
        ...params,
        role: UserRoleEnum.Foodie,
      })
    ) {
      return false
    }
    return super.needsAnyPaymentInfo(params)
  }

  getBalanceMutationFromMatch(role: UserTypeEnum, match: PriceFromMatchObject): number {
    if (role === UserRoleEnum.Cook) {
      return 0
    }

    const mealPrice = calculateMealPriceFromMatch(match.price)

    const portionPrice = this.getActualPriceFromMeal(role, {
      price: mealPrice,
      portions: 1,
      amountOfPeople: match.amountOfPeople,
      commissionType: match.commissionType,
      hasCommission: match.hasCommission,
    })

    const donationAmount = Math.min(portionPrice, 6.6)

    const amount = this.getActualPriceFromMeal(role, {
      price: mealPrice,
      portions: match.portions,
      amountOfPeople: match.amountOfPeople,
      commissionType: match.commissionType,
      hasCommission: match.hasCommission,
    })

    return amount - donationAmount
  }
}
