<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import type {
  ComponentSize,
  ExtendedTailwindColor,
  FAFontSize,
  FAIconName,
  FAIconPack,
  TailwindColor,
} from '/@src/types/elements-ui'
import { LoadingSpinner } from '/@src/models/standardIcons'
import { twMerge } from '/@src/styling/ts-merge'
import { useDarkMode } from '/@src/stores/darkmode'

interface IconProps {
  icon: FAIconName
  hoverIcon?: FAIconName
  iconPack?: FAIconPack
  size?: ComponentSize | 'tiny'
  fontAwesomeIconSize?: FAFontSize
  color?: TailwindColor
  textColor?: TailwindColor
  hoverColor?: TailwindColor
  text?: string
  margin?: string // Previously this component had a hardcoded mx-2 margin
  rounded?: boolean
  bordered?: boolean
  outlined?: boolean
  loading?: boolean
  disabled?: boolean
  overrideClickable?: boolean
}

const props = withDefaults(defineProps<IconProps>(), {
  iconPack: 'fas',
  size: 'normal',
  fontAwesomeIconSize: undefined,
  color: 'gray',
  textColor: undefined,
  text: undefined,
  margin: 'tw-mx-1',
})

const darkModeStore = useDarkMode()

const iconRef = useTemplateRef('iconRef')
const isHovered = useElementHover(iconRef)

const computedColor = computed(() => {
  let color = 'default'
  if (props.hoverColor && isHovered.value) {
    color = props.hoverColor
  } else if (props.color) {
    color = props.color
  }

  const isDark = darkModeStore.isDark

  let styles = `tw-bg-${color}-200`
  if (isDark) {
    styles = `tw-bg-${color}`
  }
  if (props.outlined && isDark) {
    styles = twMerge(styles, `tw-bg-background-darker tw-border tw-border-${color}`)
  }

  if ((props.bordered || props.outlined) && !isDark) {
    styles = twMerge(styles, `tw-border tw-border-${color}-800`)
  }

  return styles
})

const computedIcon = computed(() => {
  if (props.loading) {
    return LoadingSpinner
  }
  if (!props.hoverIcon) {
    return props.icon
  }

  return isHovered.value ? props.hoverIcon : props.icon
})

const computedTextColor = computed<ExtendedTailwindColor | undefined>(() => {
  if (
    props.outlined &&
    !props.textColor &&
    !props.color &&
    !(props.hoverColor && isHovered.value)
  ) {
    const isDarkMode = darkModeStore.isDark
    return isDarkMode ? 'white' : 'black'
  } else if (props.textColor) {
    return props.textColor
  }

  let color: TailwindColor | undefined
  if (props.hoverColor && isHovered.value) {
    color = props.hoverColor
  } else {
    color = props.color
  }

  if (color) {
    if (props.outlined && darkModeStore.isDark) {
      return 'background-dark-text'
    } else if (darkModeStore.isDark) {
      return `${color}-text` as ExtendedTailwindColor
    } else {
      return `${color}-950` as ExtendedTailwindColor
    }
  }
  return undefined
})

const attrs = useAttrs()
const computedClickable = computed(
  () => !!attrs.onClick && !props.overrideClickable && 'tw-cursor-pointer',
)
</script>

<template>
  <div
    ref="iconRef"
    :class="[
      'tw-icon-box',
      margin,
      computedColor,
      `is-${size}`,
      rounded && 'is-rounded tw-rounded-full',
      disabled && 'is-disabled',
      computedClickable,
    ]"
  >
    <VIcon
      :icon="computedIcon"
      :icon-pack
      :size
      :color="computedTextColor"
      :override-clickable
      :text
    />
  </div>
</template>

<style scoped></style>
