<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

useHead({
  title: 'Vragenlijsten | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
  },
})

const tabs: VTabsItem[] = [
  {
    label: 'Vragenlijsten',
    value: 'surveys',
    to: {
      name: '/surveys/',
    },
  },
  {
    label: 'Analyse',
    value: 'analyze',
    to: {
      name: '/surveys/analysis',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" size="medium" />

    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<style lang="scss" scoped></style>
