<script setup lang="ts">
import { computed } from 'vue'
import type { AuthUser } from '/@src/types/auth'
import { useAuthStore } from '/@src/stores/auth'
import { toast } from 'vue-sonner'
import VDropdownExtended from '/@src/components/atoms/dropdown/VDropdownExtended.vue'
import type { DropdownElement, VTabsItem } from '/@src/types/elements-ui'
import { UserRoleEnum } from '/@src/types/users'
import {
  enableFestiveOrnaments,
  isDeveloper,
  operatingSystem,
} from '/@src/utils/entity-checks'
import { useModal } from '/@src/composable/useModal'
import { useGlobalsStore } from '/@src/stores/global'

type AdminUserDropdownEmits = {
  error: []
  train: []
  search: []
  enps: []
}

interface AdminUserDropdownProps {
  hideOptions: boolean
}

const emits = defineEmits<AdminUserDropdownEmits>()
const props = defineProps<AdminUserDropdownProps>()

const authStore = useAuthStore()
const globalStore = useGlobalsStore()

const route = useRoute()
const router = useRouter()

const selectedTab = ref<TabsKeys>('dropdown')

const authUser = computed<AuthUser | undefined>(() => authStore.user)
const isMatchmaker = computed(() => authStore.hasRole(UserRoleEnum.Matchmaker))

const isDev = import.meta.env.DEV

const localHostRoute = computed(() => {
  const fullPath = route.fullPath

  return `https://localhost:3000${fullPath}`
})

const productionRoute = computed(() => {
  const fullPath = route.fullPath

  return `https://admin.thuisgekookt.nl${fullPath}`
})

const handleLogout = async () => {
  const isAdminMode = !!authStore.adminUser

  await authStore.logOut()

  toast.success('Uitgelogd.')

  if (!isAdminMode) {
    await router.push({
      name: '/login',
      query: { redirect: route.fullPath },
    })
  }
}

type DropdownElementKeys = 'error' | 'train' | 'search' | 'enps' | 'ornaments'

const dropdownElements: DropdownElement<DropdownElementKeys>[] = [
  {
    key: 'error',
    title: 'Bug report maken',
    icon: 'fa-car-side',
    hidden: props.hideOptions,
    onClick: () => emits('error'),
  },
  {
    key: 'train',
    title: 'BTW Rekentool',
    icon: 'fa-calculator',
    hidden: props.hideOptions,
    onClick: () => emits('train'),
  },
  {
    key: 'search',
    title: 'Zoeken',
    icon: 'fa-search',
    hidden: props.hideOptions,
    onClick: () => emits('search'),
  },
  {
    key: 'enps',
    title: 'e-NPS',
    icon: 'fa-square-poll-vertical',
    hidden: props.hideOptions,
    onClick: () => emits('enps'),
  },
  {
    key: 'ornaments',
    title: () =>
      globalStore.showFestiveOrnaments ? 'Versiering uitzetten' : 'Versiering aanzetten',
    icon: 'fa-holly-berry',
    hidden: props.hideOptions || !enableFestiveOrnaments.value,
    onClick: () => {
      globalStore.showFestiveOrnaments = !globalStore.showFestiveOrnaments
    },
  },
]

type TabsKeys = 'dropdown' | 'matchmaker'
const tabs: VTabsItem<TabsKeys>[] = [
  {
    value: 'dropdown',
    label: 'Links',
  },
  {
    value: 'matchmaker',
    label: 'Matchmakers',
  },
]
</script>
<template>
  <VDropdownExtended
    :elements="selectedTab === 'dropdown' ? dropdownElements : []"
    :skidding="-128"
  >
    <VAvatar :picture="authUser?.avatar" />

    <template #pre-popper>
      <div class="tw-dropdown-head">
        <VAvatar size="large" :picture="authUser?.avatar" />

        <div class="tw-title-subtitle">
          <span>{{ authUser?.firstName ?? 'Onbekend' }}</span>
        </div>
      </div>
      <VTabs
        v-if="isMatchmaker || isDeveloper"
        v-model:selected="selectedTab"
        :tabs="tabs"
        align="center"
      />
    </template>

    <template #popper>
      <template v-if="selectedTab === 'dropdown'">
        <template v-if="isDeveloper">
          <a
            v-if="isDev"
            class="tw-dropdown-item"
            :href="productionRoute"
            target="_blank"
          >
            <VIcon icon="fa-laptop" />
            <div class="tw-title-subtitle">
              <span>Ga naar productie</span>
            </div>
          </a>
          <a v-else class="tw-dropdown-item" :href="localHostRoute" target="_blank">
            <VIcon icon="fa-laptop" />
            <div class="tw-title-subtitle">
              <span>Open in localhost</span>
            </div>
          </a>
        </template>

        <hr class="tw-dropdown-divider" />

        <div class="tw-dropdown-item tw-no-hover">
          <VButton color="primary" width="full" @click.prevent="handleLogout">
            {{ !!authStore.adminUser ? 'Teruggaan' : 'Uitloggen' }}

            <template #icon-right>
              <VIcon
                icon="fa-arrow-right-from-bracket"
                color="primary-text"
                force-color
              />
            </template>
          </VButton>
        </div>
      </template>

      <MatchmakerProfileDropdown
        v-if="selectedTab === 'matchmaker' && (isMatchmaker || isDeveloper)"
      />
    </template>
  </VDropdownExtended>
</template>

<style scoped></style>
