<script setup lang="ts">
import type {
  NationalTargetAndValueRecord,
  NormalisedYearlyTargetValues,
} from '/@src/types/national-targets'
import type { TailwindColor } from '/@src/types/elements-ui'

interface GlobalTargetBarProps {
  targets: NormalisedYearlyTargetValues
  value: number
  color: TailwindColor
  disableTargets?: Partial<NationalTargetAndValueRecord<boolean>>
  texts: NationalTargetAndValueRecord<string>
  bottom: 0 | `${number}rem`
}

const props = defineProps<GlobalTargetBarProps>()

const fullBarAmount = computed<number>(() => {
  if (!props.disableTargets?.gold) {
    return props.targets.goldValue * 1.1
  } else if (!props.disableTargets?.silver) {
    return props.targets.silverValue * 1.1
  } else if (!props.disableTargets?.bronze) {
    return props.targets.bronzeValue * 1.1
  } else {
    return props.value
  }
})

const valueRatio = computed(() => props.value / fullBarAmount.value)

const bronzeRatio = computed(() => {
  if (props.targets.bronzeValue === 0) {
    return 0.7
  } else {
    return props.targets.bronzeValue / fullBarAmount.value
  }
})
const silverRatio = computed(() => {
  if (props.targets.silverValue === 0) {
    return 0.7 * 1.19
  } else {
    return props.targets.silverValue / fullBarAmount.value
  }
})
const goldRatio = computed(() => {
  if (props.targets.goldValue === 0) {
    return 0.7 * 1.33
  } else {
    return props.targets.goldValue / fullBarAmount.value
  }
})

const bronzeBarColour = computed(() => {
  if (props.targets.bronzeValue === 0 || props.value < props.targets.bronzeValue) {
    return 'tw-bg-gray'
  } else {
    return 'tw-bg-bronze'
  }
})
</script>

<template>
  <div class="global-bar tw-bg-gray" :style="{ bottom: bottom }">
    <div
      v-if="!disableTargets?.bronze"
      v-tooltip.bottom-end="
        texts.bronze.replace('{value}', targets.bronzeValue.toFixed(0))
      "
      class="bronze-bar"
      :class="bronzeBarColour"
      :style="{ width: `${bronzeRatio * 100}%` }"
    />
    <div
      v-if="!disableTargets?.silver"
      v-tooltip.bottom-end="
        texts.silver.replace('{value}', targets.silverValue.toFixed(0))
      "
      class="silver-bar"
      :style="{
        width: `${(silverRatio - bronzeRatio) * 100}%`,
        left: `${bronzeRatio * 100}%`,
      }"
    />
    <div
      v-if="!disableTargets?.gold"
      v-tooltip.bottom-end="texts.gold.replace('{value}', targets.goldValue.toFixed(0))"
      class="gold-bar"
      :style="{
        width: `${(goldRatio - silverRatio) * 100}%`,
        left: `${silverRatio * 100}%`,
      }"
    />

    <div
      v-tooltip.bottom-end="texts.value.replace('{value}', value.toFixed(0))"
      class="value-bar"
      :class="`tw-bg-${color}`"
      :style="{ width: `${valueRatio * 100}%` }"
    />
  </div>
</template>

<style scoped>
.global-bar {
  @apply tw-z-[1] tw-w-[100vw];
  @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-[0.2rem] tw-outline tw-outline-[0.05rem] tw-outline-border-dark;

  .bronze-bar {
    @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-[0.2rem] tw-outline tw-outline-[0.05rem] tw-outline-border-dark;
    @apply tw-border-r-2 tw-border-solid tw-border-black;

    @apply tw-z-[6];
  }

  .silver-bar {
    @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-[0.2rem] tw-outline tw-outline-[0.05rem] tw-outline-border-dark;
    @apply tw-border-r-2 tw-border-solid tw-border-black;

    @apply tw-z-[4];
  }

  .gold-bar {
    @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-[0.2rem] tw-outline tw-outline-[0.05rem] tw-outline-border-dark;
    @apply tw-border-r-2 tw-border-solid tw-border-black;

    @apply tw-z-[2];
  }

  .value-bar {
    @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-[0.2rem] tw-outline tw-outline-[0.05rem] tw-outline-border-dark;
    @apply tw-border-r-2 tw-border-solid tw-border-black;

    @apply tw-z-[7];
  }
}
</style>
