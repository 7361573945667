<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import { useField } from 'vee-validate'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { dayjsUTC, parseBareBonesDayjs } from '/@src/utils/date-formatter'
import type { BareBonesDayjs } from '/@src/types/utils'
import type { ComponentClass } from '/@src/types/elements-ui'

dayjs.extend(timezone)

interface DateProps {
  name: string
  label: string
  type?: 'date' | 'datetime'
  placeholder?: string
  minDate?: Dayjs
  maxDate?: Dayjs
  disabled?: boolean
  fieldClass?: ComponentClass
  oldStyle?: boolean
}

const props = withDefaults(defineProps<DateProps>(), {
  type: 'date',
  placeholder: '',
  minDate: undefined,
  maxDate: undefined,
  disabled: false,
  fieldClass: undefined,
})

const datePicker = useTemplateRef('datePicker')

const formatDate = 'YYYY-MM-DD'
const formatTime = 'HH:mm'

const formattedMinDate = computed(() => props.minDate?.format(formatDate))
const formattedMaxDate = computed(() => props.maxDate?.format(formatDate))

const {
  value: dateProp,
  errors,
  resetField,
} = useField<BareBonesDayjs | Dayjs | null | undefined>(props.name)

const dateInput = computed<string | undefined>({
  get() {
    if (dayjs.isDayjs(dateProp.value)) {
      const convertedDate = dateProp.value.clone().tz('Europe/Amsterdam')
      return convertedDate.format(formatDate)
    }

    return parseBareBonesDayjs(dateProp.value)?.format(formatDate)
  },
  set(newValue) {
    if (newValue) {
      const [year, month, day] = newValue.split('-')

      if (!dayjs.isDayjs(dateProp.value)) {
        dateProp.value = parseBareBonesDayjs(dateProp.value)

        //If still not valid, set to today
        //This is fine, since we'll be setting the date right after anyway
        if (!dayjs.isDayjs(dateProp.value)) {
          dateProp.value = dayjsUTC().hour(12).second(0).millisecond(0)
        }
      }

      let yearNumber = Number(year)

      if (yearNumber < 100) {
        if (yearNumber + 2000 < dayjs().year()) {
          yearNumber = yearNumber + 2000
        } else {
          yearNumber = yearNumber + 1900
        }
      }

      // Always make sure that date-only pickers are set to noon
      // This is to ensure no timezone fuckery happens
      if (props.type === 'date') {
        dateProp.value = (dateProp.value as Dayjs).hour(12)
      }

      dateProp.value = (dateProp.value as Dayjs)
        .year(yearNumber)
        .month(Number(month) - 1) //Months are zero indexed for some reason
        .date(Number(day))
    } else {
      dateProp.value = null

      if (datePicker.value) {
        datePicker.value.value = ''
      }
    }
  },
})

const timeInput = computed<string | undefined>({
  get() {
    if (dayjs.isDayjs(dateProp.value)) {
      const convertedDate = dateProp.value.clone().tz('Europe/Amsterdam')
      return convertedDate.format(formatTime)
    }

    return parseBareBonesDayjs(dateProp.value)?.format(formatTime)
  },
  set(newValue) {
    if (props.type === 'date') {
      return
    }

    if (newValue) {
      const [hours, minutes] = newValue.split(':')

      if (!dayjs.isDayjs(dateProp.value)) {
        dateProp.value = parseBareBonesDayjs(dateProp.value)

        //If still not valid, set to today
        //This is fine, since we'll be setting the date right after anyway
        if (!dayjs.isDayjs(dateProp.value)) {
          dateProp.value = dayjsUTC().second(0).millisecond(0)
        }
      }

      const newDate = dayjs({
        hour: Number(hours),
        minute: Number(minutes),
      }).tz('Etc/UTC')

      dateProp.value = (dateProp.value as Dayjs)
        .hour(newDate.hour())
        .minute(newDate.minute())
    } else {
      dateProp.value = null
    }
  },
})

if (dayjs.isDayjs(dateProp.value)) {
  if (props.type === 'date') {
    dateProp.value = dateProp.value.hour(12)
    resetField({
      value: dateProp.value,
    })
  }
  dateInput.value = dateProp.value.clone().format(formatDate)
  timeInput.value = dateProp.value.clone().tz('Europe/Amsterdam').format(formatTime)
} else if (typeof (dateProp.value as any) === 'string') {
  // Very rarely dateProp can be a string here
  let parsedDateProp = dayjs(dateProp.value as unknown as string)
  if (props.type === 'date') {
    parsedDateProp = parsedDateProp.hour(12)
  }
  dateInput.value = parsedDateProp?.format(formatDate)
  timeInput.value = parsedDateProp?.tz('Europe/Amsterdam').format(formatTime)

  resetField({
    value: parsedDateProp,
  })
} else {
  let parsedDateProp = parseBareBonesDayjs(dateProp.value)
  if (props.type === 'date' && parsedDateProp) {
    parsedDateProp = parsedDateProp.hour(12)
  }
  dateInput.value = parsedDateProp?.format(formatDate)
  timeInput.value = parsedDateProp?.tz('Europe/Amsterdam').format(formatTime)

  resetField({
    value: parsedDateProp,
  })
}

watch(dateProp, (newValue) => {
  if (props.type === 'date' && dayjs.isDayjs(newValue) && newValue.hour() !== 12) {
    dateProp.value = newValue.hour(12)
    resetField({
      value: dateProp.value,
    })
  }
})

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'danger'
  } else if (dateProp.value) {
    return 'success'
  }
  return undefined
})
</script>

<template>
  <VField
    :class="fieldClass"
    :label="label"
    :color="color"
    :message="errors.join('\n')"
    :old-style
  >
    <template v-if="type === 'date'">
      <input
        ref="datePicker"
        v-model="dateInput"
        class="tw-input tw-date-input"
        :type="type"
        :disabled="disabled"
        :min="formattedMinDate"
        :max="formattedMaxDate"
      />
    </template>
    <div v-else class="tw-columns tw-flex">
      <div class="tw-column tw-is-two-thirds !tw-pr-1">
        <input
          ref="datePicker"
          v-model="dateInput"
          class="tw-input tw-date-input"
          type="date"
          :disabled="disabled"
          :min="formattedMinDate"
          :max="formattedMaxDate"
        />
      </div>

      <div class="tw-column tw-is-one-third !tw-pl-1">
        <VInput v-model="timeInput" type="time" />
      </div>
    </div>
  </VField>
</template>

<style scoped lang="scss"></style>
