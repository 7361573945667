<script setup lang="ts">
import type { ComponentSize, TailwindColor } from '/@src/types/elements-ui'
import { computed } from 'vue'
import type { Color } from '/@src/types/utils'
import { DeleteIcon } from '/@src/models/standardIcons'
import { useDarkMode } from '/@src/stores/darkmode'

interface VTagProps {
  label?: string | number
  color?: TailwindColor
  size?: ComponentSize
  rounded?: boolean
  isDelete?: boolean
  stringColor?: Color
  textColor?: Color
  overrideClickable?: boolean
}

const props = withDefaults(defineProps<VTagProps>(), {
  label: undefined,
  color: undefined,
  size: undefined,
  rounded: false,
  isDelete: false,
  stringColor: undefined,
  textColor: undefined,
})

const darkMode = useDarkMode()

const computedColor = computed(() => {
  let color = props.color
  if (!color) {
    color = darkMode.isDark ? 'gray-darker' : 'white'
  }

  return `tw-bg-${color} tw-text-${color}-text`
})

const computedSize = computed(() => {
  if (props.size) {
    return `is-${props.size}`
  }
  return 'is-normal'
})

const attrs = useAttrs()
const computedClickable = computed(
  () => !!attrs.onClick && !props.overrideClickable && 'tw-cursor-pointer',
)
</script>

<template>
  <span
    :style="{ backgroundColor: stringColor, color: textColor }"
    class="tw-tag"
    :class="[
      computedColor,
      computedSize,
      computedClickable,
      color === 'white' && 'tw-border tw-border-solid tw-border-border',
      rounded && 'tw-rounded-full',
      isDelete && 'tw-hover:tw-brightness-90 !tw-px-0',
    ]"
  >
    <VIcon v-if="isDelete" :icon="DeleteIcon" />
    <slot v-else>{{ label }}</slot>
  </span>
</template>

<style scoped></style>
