<script setup lang="ts">
import { devIsInProdEnvironment } from '/@src/utils/entity-checks'
import { useDarkMode } from '/@src/stores/darkmode'
import { useAuthStore } from '/@src/stores/auth'

const darkMode = useDarkMode()

const authStore = useAuthStore()

const panColor = computed(() => {
  if (!!authStore.adminUser) {
    return '#FFF'
  } else if (devIsInProdEnvironment.value) {
    return '#000000FF'
  } else if (darkMode.isDark) {
    return '#ABABAB'
  } else {
    return '#151515'
  }
})
</script>

<template>
  <RouterLink :to="{ name: '/' }" class="tw-cursor-pointer">
    <svg
      id="Layer_1"
      viewBox="0 0 954.4 800.3"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      class="tw-min-h-20 tw-min-w-20"
      overflow="visible"
      xml:space="preserve"
    >
      <g>
        <path
          :fill="panColor"
          d="M316.8,501.6c0.6-24.6,42.4-45.7,61-52.5c22.4-8.1,46.5-15,70.8-16.3c30-1.5,59.6-9.8,89.5-10
          c28.9-0.2,54.1,3.9,80.7,14.9c3.6,1.5,8.7,3.8,12.5,4c7.8,0.4,8.9-2.6,15.5-8.5c22.1-19.4,50.2-31.5,77.8-40.9
          c27.3-9.2,54.7-14.7,81.9-24.3c12-4.2,30.9-0.9,17.1,14.8c-16.7,18.9-41.8,21.4-64.8,26.1c-28.2,5.7-48.9,22.6-72.7,37.8
          c-9.6,6.1-16.9,13.2-24.8,21.2c-7,7-11.2,5.5-19.5,7.1c-11.4,2.2-18.3,5.7-22.5,16.2c-4.7,11.5-9.9,21.3-18.8,30.1
          c-22.1,21.9-58.4,31.2-87.3,40.3c-26.2,8.3-53.8,6-80.4,5.9c-20.2-0.1-48.8,0.1-66-11.4c-15.7-10.5-24.3-28.2-37.8-40.4
        C325.7,513,320.7,511.2,316.8,501.6z"
        />
        <g>
          <path :fill="panColor" d="M73.1,641H44.8v-18.8h80.6V641H97.1v80.3h-24V641z" />
          <path
            :fill="panColor"
            d="M133.1,621.1h22.4v41.5c3.7-8.5,10.6-13.3,20.7-13.3c15.4,0,24.6,10.1,24.6,29.5v42.4h-22.7v-40.9
			c0-8.7-3.1-13.1-10.7-13.1c-7.5,0-11.9,6-11.9,17v37h-22.4V621.1z"
          />
          <path
            :fill="panColor"
            d="M210.7,693.2v-42.4h22.5v40.9c0,8.4,3.6,13.1,10.9,13.1c8.2,0,11.6-6.7,11.6-17v-37h22.7v70.4h-22.5v-10
			c-3.9,7.3-10.4,11.5-19.7,11.5C220.4,722.7,210.7,711.8,210.7,693.2z"
          />
          <path
            :fill="panColor"
            d="M290.6,622.3h23.3v19.4h-23.3V622.3z M290.9,650.8h22.7v70.4h-22.7V650.8z"
          />
          <path
            :fill="panColor"
            d="M321.5,709.7l13.9-10.7c4.6,5.4,11.5,9,18.9,9c5.8,0,9.4-2.2,9.4-6.3c0-4.3-7-5.4-16.4-7.5
			c-11.8-2.5-24.8-7.5-24.8-21.9c0-13.4,12.5-23.3,29.2-23.3c14.9,0,25.7,5.5,32.8,13.9c0,0.3-13.4,10.1-13.4,10.1
			c-4.8-5.4-11.2-8.7-18.2-8.7c-5.7,0-9.1,2.2-9.1,6c0,4.3,4,5.1,14.8,7.3c12.1,2.4,26.6,7,26.6,21.9c0,13.9-12.7,23.9-29.8,23.9
			C340,723.5,328.9,717.8,321.5,709.7z"
          />
          <path
            :fill="panColor"
            d="M388.2,736.4c0-6.9,4.5-11.9,12.7-14.9c-6.9-1.8-10.9-6.3-10.9-12.2s4.5-10.7,12.4-13.3
			c-6.7-4.6-10.6-11.8-10.6-20.1c0-15.4,13.9-26.7,33-26.7c11.9,0,21.6,4.6,27,12.1c2.5-6.6,7.3-10.4,14.5-10.4v19.7
			c-3.1-1.3-7.2-1.5-10.3,0.9c0.3,1.3,0.4,2.7,0.4,4.2c0,16.3-12.7,26.6-32.5,26.6c-4.2,0-8.1-0.4-11.6-1.5
			c-3.1,0.3-5.2,1.8-5.2,3.7c0,2.4,3.1,4,8.2,4h18.3c21.6,0,33.3,7.9,33.3,22.4c0,16.1-14.3,25.1-40.4,25.1
			C401,755.8,388.2,749.1,388.2,736.4z M448.3,734.2c0-5.2-4.9-7.5-17.2-7.5h-13.7c-6.9,0.6-11.5,3.3-11.5,7.9
			c0,4.9,6.9,7.8,20.9,7.8C440.7,742.4,448.3,739.4,448.3,734.2z M436.4,676v-0.7c0-7-4.9-11.8-12.1-11.8c-7,0-11.9,4.9-11.9,11.8
			v0.9c0,7,4.6,11.5,11.9,11.5C431.7,687.7,436.4,683.3,436.4,676z"
          />
          <path
            :fill="panColor"
            d="M469,686.5c0-22.2,14.6-37.1,36.7-37.1c22.5,0,35.7,14,35.7,37.3v5.5h-50.6c1,9.5,7.2,14.5,16.3,14.5
			s13.6-4.5,15.7-10.3l18.3,5.5c-4.8,12.8-15.2,20.9-34.9,20.9C483,722.7,469,708.7,469,686.5z M519.1,679c-0.6-9.4-5.1-14-13.7-14
			c-7.9,0-13.6,5.1-14.6,14H519.1z"
          />
          <path
            :fill="panColor"
            d="M550.3,621.1h22.2v59.1l22.8-29.4H620l-23.7,26.1l24.9,44.3h-24l-16-28.9l-8.7,10.4v18.5h-22.2V621.1z"
          />
          <path
            :fill="panColor"
            d="M618.8,686.2c0-22.1,14.9-36.8,38-36.8c23.1,0,37.9,14.8,37.9,36.8c0,21.8-15.1,36.5-38.3,36.5
			C633.3,722.7,618.8,708.1,618.8,686.2z M671.6,686.8v-1c0-13-5.4-20-14.9-20c-9.4,0-14.8,7-14.8,20v1c0,12.8,5.2,19.5,14.6,19.5
			C666.1,706.3,671.6,699.5,671.6,686.8z"
          />
          <path
            :fill="panColor"
            d="M701.3,686.2c0-22.1,14.9-36.8,38-36.8c23.1,0,37.9,14.8,37.9,36.8c0,21.8-15.1,36.5-38.3,36.5
			C715.8,722.7,701.3,708.1,701.3,686.2z M754.1,686.8v-1c0-13-5.4-20-14.9-20c-9.4,0-14.8,7-14.8,20v1c0,12.8,5.2,19.5,14.6,19.5
			C748.6,706.3,754.1,699.5,754.1,686.8z"
          />
          <path
            :fill="panColor"
            d="M786.6,621.1h22.2v59.1l22.8-29.4h24.6l-23.7,26.1l24.9,44.3h-24l-16-28.9l-8.7,10.4v18.5h-22.2V621.1z"
          />
          <path
            :fill="panColor"
            d="M868.7,700.9v-34.2h-9.4v-16h9.4v-13.7l22.2-10.9v24.6h12.7v16h-12.8V700c0,3.1,1.5,4.5,4.6,4.6h7.8v16.6
			h-16.7C871.8,720.2,869,713.5,868.7,700.9z"
          />
        </g>
        <g>
          <path
            :fill="panColor"
            d="M42.9,570.7h9.4c1.1,6.2,4.4,8.6,11.2,8.6c5.7,0,9.1-2.5,9.1-6.8c0-4.2-5.3-5.2-11.9-6.5
			c-7.9-1.6-16.5-4.2-16.5-13.6c0-8.7,7.5-14.9,17.7-14.9c11.3,0,18.5,5.7,19.6,16.1h-9.3c-0.8-5.8-4.2-8.5-10.4-8.5
			c-5.3,0-8.5,2.6-8.5,6.6c0,4.5,6,5.2,12.5,6.5c7.8,1.5,15.9,4,15.9,13.6c0,9.1-6.8,15.2-17.8,15.2
			C51.3,586.9,44.5,580.8,42.9,570.7z"
          />
          <path
            :fill="panColor"
            d="M89.3,577.1v-17.7h-4.8v-7.2h4.8v-6.4l8.7-4.9v11.3h6.7v7.2H98v16.9c0,1.7,0.8,2.4,2.4,2.4h4v7.4h-6.9
			C90.9,585.8,89.5,582.7,89.3,577.1z"
          />
          <path
            :fill="panColor"
            d="M109.7,538.6h9.3v8.7h-9.3V538.6z M109.9,552.2h9v33.9h-9V552.2z"
          />
          <path
            :fill="panColor"
            d="M123.9,569.1c0-10.2,7-17.5,17.1-17.5c8.2,0,13.1,4.3,15.4,10.8L148,565c-1-3.7-2.9-6.2-7-6.2
			c-5,0-8.1,4.2-8.1,10.2v0.3c0,6,2.9,10.2,7.8,10.2c4.8,0,6.7-2.4,7.7-6.5l8.4,2.4c-2.4,7-7.4,11.4-15.9,11.4
			C130.3,586.8,123.9,579.6,123.9,569.1z"
          />
          <path
            :fill="panColor"
            d="M160.8,537.8h8.7v20.5c1.7-4.2,5-6.7,9.9-6.7c7.4,0,11.8,4.9,11.8,14.2v20.3h-8.9v-19.8
			c0-4.7-1.8-7.2-6-7.2s-6.8,3.4-6.8,9.8v17.2h-8.7V537.8z"
          />
          <path
            :fill="panColor"
            d="M199,577.1v-17.7h-4.8v-7.2h4.8v-6.4l8.7-4.9v11.3h6.7v7.2h-6.8v16.9c0,1.7,0.8,2.4,2.4,2.4h4v7.4h-6.9
			C200.6,585.8,199.2,582.7,199,577.1z"
          />
          <path
            :fill="panColor"
            d="M219.4,538.6h9.3v8.7h-9.3V538.6z M219.6,552.2h9v33.9h-9V552.2z"
          />
          <path
            :fill="panColor"
            d="M235.2,552.2h8.9v5.5c1.8-4,5.1-6.2,9.9-6.2c7.4,0,11.8,5,11.8,14.3v20.3h-8.9v-19.8c0-4.7-1.8-7.2-6-7.2
			c-4.2,0-6.8,3.4-6.8,9.8v17.2h-8.9V552.2z"
          />
          <path
            :fill="panColor"
            d="M269.4,593.6c0-3.2,2.2-5.9,6-7.5c-3.2-0.8-5.4-3-5.4-5.7c0-2.9,2.2-5.1,6-6.2c-3-2.3-4.9-5.7-4.9-9.6
			c0-7.5,6.3-13.1,14.9-13.1c5.3,0,9.7,2.2,12.2,5.7c1.3-3.2,3.6-5.1,7.1-5.1v8.7c-1.6-0.6-3.7-0.6-5.2,0.7c0.2,0.9,0.4,1.9,0.4,2.9
			c0,7.8-5.8,12.9-14.8,12.9c-1.7,0-3.4-0.2-4.9-0.7h-0.3c-1.7,0-3.1,0.8-3.1,1.9c0,1.3,1.8,2.2,4.2,2.2h7.9
			c10.6,0,16.4,3.9,16.4,11c0,7.3-7,11.8-18.8,11.8C275.7,603.5,269.4,599.9,269.4,593.6z M298.2,592.5c0-2.9-2.9-4.2-9.1-4.2h-6.8
			c-3.1,0.4-5.4,1.9-5.4,4.5c0,2.7,3.7,4.4,10.5,4.4C293.9,597.2,298.2,595.4,298.2,592.5z M292.6,564.7v-0.4c0-3.6-2.9-6.2-6.8-6.2
			c-3.8,0-6.6,2.7-6.6,6.3v0.4c0,3.6,2.7,6.2,6.6,6.2C290,570.9,292.6,568.4,292.6,564.7z"
          />
        </g>
        <path
          fill="#08634B"
          d="M502,44.2c-0.7-1-0.9-5.5,0-6.4c1.5-1.6,2.6,3.1,3.5,4.2c2.5-0.5,3.9-1.9,6.6-1.3c4.4,1,9,3.1,13.2,4.7
		c4.9,1.8,10.1,3.5,14.8,5.7c2.4,1.1,4.5,2.8,6.9,3.9c2.5,1.2,4.8,2,7,3.6c2.5,1.8,5.3,3.4,7.7,5.3c2.3,1.8,4.3,4,6.6,5.7
		c2.5,1.8,5.6,2.4,8,4.3c2.1,1.6,3,2.7,5.4,3.9c5.6,2.8,9.8,8,15,11.4c5.1,3.4,10.2,7,15.1,10.8c3.2,2.5,7.3,4.8,10.1,7.6
		c0.9,1,1.5,2.2,2.5,3.2c1.7,1.6,3.7,2.7,5.5,4c2.4,1.7,3.1,4.1,5.1,6.2c1.8,1.9,4.3,3.5,6.3,5.4c1.5,1.4,3.1,2.8,4.6,4.2
		c5.7,5.6,10.8,11.5,11.2,19.8c0.2,3,1.7,9-0.7,11.4c-3.9,3.9-9.9,1.2-14.1-0.2c-8-2.7-13.7-9.2-21.4-12.6c-2.7-1.2-3.5-3.5-5.7-5.2
		c-3.2-2.3-7.3-3.3-10.3-5.9c-9.3-7.9-18.3-16.4-28.8-22.7c-4.1-2.5-8-5.1-12.1-7.6c-2.9-1.8-5.8-3.4-8.6-5.5
		c-2.9-2.2-5.3-4.9-8.4-6.8c-2.3-1.4-5-2.2-7.4-3.5c-2.5-1.3-4.4-3.1-6.7-4.7c-2.3-1.6-4.9-3.1-6.9-5.1c-2-2-3.5-4.5-5.5-6.6
		c-3.7-3.9-7.5-7.4-11.6-10.9c-2.6-2.2-4.2-5-6.2-7.6c-2.1-2.7-3.4-5.4-2.5-8.8C500.3,47,500.7,46,502,44.2z"
        />
        <path
          fill="#D4AE5A"
          d="M576,167.5c10.8-4.7,10.6,18.1,18.2,22.1c3,1.6,5.8,1.1,8.4,3.6c0.9,0.9,0.6,3.3,1.5,4.2
		c2.4,2.6,5.2,3.5,8.3,5.2c8.1,4.6,12.2,13.6,9.2,23.2c-3.8,12-18,3.4-26.6,6.8c-3.2,6.7,8.2,6.5,12.2,7.7
		c14.8,4.2,29.8,7.1,43.9,14.7c5.1,2.7,13.3,8.2,13.8,14.8c0.1,1.6-2.6,2.1-2.6,3.9c0.1,2.4,2.6,4.5,2.6,6.6
		c-0.5,21.2-42.9-10.8-49.3-14.3c-4-2.2-23.3-12.1-25.4-4c-1.9,7.3,16.9,15.2,21,19.4c9,8.9,22.5,12.3,29,23.5
		c6.4,11.1,5.3,20-8.4,13.9c-1.6,4.6,2.4,8.2,3.5,12.4c1.9,7.5,0.2,5.6-4.7,7.7c-4.9,2.1-5.6-1.6-5.7,5.8
		c-0.1,7.2,6.4,12.3,7.4,19.3c1.7,10.7-6.2,19.5-13.4,26.4c-9.6,9.2-18.2,13-31.1,5.7c-3.8-2.2-8.8-6.8-9.9-11.3
		c-1.8-7.6,5.1-11.5,8.7-16.9c6.7-9.8,2.8-20.6,2.3-31.6c-0.2-3.7-2.1-16.4-8.6-16c-4.4,0.2-3.5,5.3-3.5,8.2
		c-0.1,6.4,0.2,7.3-2.6,13.2c-3.5,7.2-3.6,19.8-13.4,20.6c-16.5,1.4-21.2-19.2-18.3-31.1c3.1-12.8,15.3-17.6,19.1-29.8
		c1.9-6.1,5.7-26-4.1-28c-6.5-1.3-8.7,7.1-15,1c-7.9-7.7,1.3-10.4,4.6-16c3.5-5.9,3.5-13.6,10.3-18.2c2.6-1.8,9-1.5,10.9-3.6
		c3-3.3,0.3-10.3,0.2-14.1c-0.1-3.8,0.6-6.6-1.7-9.2c-2.8-3.1-8-1.3-10.2-6c-3.6-8,6.5-14,9-20.4c2.3-6,0.3-10.6,5-16.1
		C573.2,167.9,577.1,164.2,576,167.5z"
        />
        <path
          fill="#C33D3A"
          d="M553.9,160.2c-0.3-4-1.2-8.1-3.9-11.1c-2.7-2.9-7.5-4.2-10.7-1.9c-1.2,0.9-2.5,2.2-3.9,1.6
		c-0.6-0.3-1-0.9-1.4-1.4c-2.5-3.7-6.8-6.7-11.2-6.1c-3.1,0.5-5.9,2.6-8.6,4.3c0.7-4.9,1.3-9.9,2-14.8c0.2-1.8,0.8-3.9,2.5-4.5
		c2.3-0.7,5,1.9,6.9,0.4c0.9-0.7,1-2,0.7-3.1c-0.7-3.5-4.2-6.2-7.8-5.9c-2.7,0.2-5.1,1.9-6.7,4s-2.6,4.7-3.4,7.2
		c-1.1,3.4-4,11-4.4,17.1c-2.5-1.6-4.6-4-7.5-4.8c-6.1-1.8-12.2,4.3-18.4,3.2c-3-0.5-5.7-2.7-8.7-2.3c-4.1,0.5-6.1,5.2-7,9.2
		c-6.6,29.3,3.5,61.9,19.3,86.7c2.8,4.4,6.4,8.9,11.5,9.6c6.7,0.9,12.6-5.2,19.4-5.1c5,0.1,9.7,3.7,14.7,3
		c5.4-0.8,8.8-6.2,10.4-11.4c1.7-6,1.9-12.3,3-18.5C543.8,197,555.1,179.3,553.9,160.2z"
        />
        <path
          fill="#DE6852"
          d="M446.5,320.9c-10.2-3.2-20.6-5.4-30.7-9.2c-10.5-4-22.1-12.9-33.8-11.2c-10,1.4-19.5,0-26.7,7.9
		c-9.4,10.4-17.2,29.2-20.8,42.9c-2.7,10,0.9,17.5,6.2,25.5c4,6,5.1,14,8.9,19.5c4.9,7,14,6.4,21.3,6.1c15.3-0.5,30.5,1.4,46.1,0.6
		c26.4-1.2,52.6-0.7,78.7-1.9c16.8-0.8,26.5-9.1,36.2-20.6c3.4-4,5.9-6.2,5.2-11.5c-0.5-3.4-2.7-9.1-3.6-12.8
		c-2.7-11.2-15.6-14.3-25.7-17.9C489.1,331.8,466.3,330.7,446.5,320.9z"
        />
        <path
          fill="#F5C455"
          d="M499.4,78.8c1.8,4.5-3.9,7.1-4.9,10.9c-0.5,2.1,0.3,4.7,0.2,6.9c-0.1,4.1-1.2,8-3,11.7
		c-3.8,7.9-11.1,13.4-18.2,17.7c-9.2,5.6-19.4,6.2-29.5,3c-4-1.3-7.6-3.8-11.4-5.1c-1.9-0.6-4-0.1-5.7-1.2
		c-4.6-2.9-5.1-7.6-4.2-12.5c0.3-1.8,1.4-3,2-4.6c0.9-2.2,0.7-4.7,1.1-7c0.7-5,3.6-10.2,6.9-13.9c6.5-7.1,14.5-11.7,24-13.4
		c7.4-1.3,15.7-0.9,22.9,1.7c2.4,0.9,4.5,2.5,6.9,3.2c2.6,0.7,5.6,0.5,8.3,0.9C495.9,77.2,496.9,77.5,499.4,78.8z"
        />
        <path
          fill="#EFD7A0"
          d="M456.2,295.2c-3.4-5.1-6.8-10.2-8.8-15.8c-2.8-7.6,1.2-10.8,4.4-17.4c3.8-7.8,10.3-4.2,17.4-2.6
		c8.8,2,18,3.2,26.9,3.1c4.5,0,9-0.5,13.5,0.1c4.4,0.6,8.6,2.8,12.7,4.6c7,3.1,11.7,6.4,16.4,12.5c1.6,2.1,4.7,4.7,5.6,7.1
		c1.2,3-1.3,4.7-2.8,7.6c-4.8,9.1-14.7,15.7-24.2,19.4C496.6,321.8,470.7,316.3,456.2,295.2z"
        />
        <path
          fill="#EFD7A0"
          d="M656.6,229.1c-2.7,3-5.4,6-8.7,8.2c-4.4,3-7.1,1-11.8,0c-5.6-1.2-4.3-5.9-4.4-10.7c-0.1-6-0.9-12-2.4-17.7
		c-0.8-2.9-1.8-5.7-2.1-8.7c-0.3-2.9,0.4-6,0.9-8.8c0.8-5,2.2-8.5,5.3-12.5c1.1-1.4,2.3-3.8,3.6-4.8c1.7-1.3,3.2,0.1,5.3,0.6
		c6.6,1.6,12.4,6.9,16.3,12.3C667.1,199,667.8,216.5,656.6,229.1z"
        />
        <path
          fill="#EFD7A0"
          d="M360.1,236.9c3.9-1.3,7.9-2.6,11.9-3c5.5-0.5,6.8,2.7,10.6,6c4.4,3.9,0.9,7.4-1.4,11.8
		c-2.9,5.4-5.3,11.2-6.8,17c-0.8,2.9-1.3,6-2.5,8.8c-1.2,2.8-3.3,5.1-5.2,7.5c-3.3,4-6.2,6.5-11.1,8.5c-1.7,0.7-3.9,2.2-5.6,2.4
		c-2.2,0.2-2.8-1.7-4.4-3.2c-5.1-4.8-7.7-12.4-8.4-19.3C335.5,258.5,343.7,242.6,360.1,236.9z"
        />
        <path
          fill="#108063"
          d="M415.3,266.8c-1.5-1.9-4-4.2-4.9-6.4c-0.9-2.4-0.4-4.7-1.8-7.1c-1.1-2-3.5-3.1-4.9-5.3
		c-1.4-2.3-1.8-4.9-3-7.2c-5-9.9-10.5-18.2-21.1-20.3c-3.7-0.7-7.8-3.1-11.4-3.2c-3.3-0.1-8,3.8-10.1,2.3c-4.8,1.7-5.7,6-11.7,4.6
		c-5.7-1.4-10.6-6.8-13.6-11.4c-4-6-4.8-9.5-3.4-16.8c1.3-6.4-0.8-12.4,0.8-18.6c1.8-7,5-11.2,8.5-17.2c1.7-2.9,2-6.7,3.5-9.7
		c2.1-3.9,6.1-7.6,9.7-10.3c7.8-6.1,15.2-8.3,24.8-7.4c5.1,0.5,9.1-1.2,14.1-2c4.2-0.7,10.2-2.4,14.6-1.2c4.7,1.2,7.9,6.7,12.2,7.9
		c5.1,1.4,8.9-1.6,14.2,1.6c4,2.5,7.5,5.7,9.9,9.8c2.9,4.9,4.7,10.6,4.9,16.3c0.3,6.7-2.1,8.5-6.5,13c-4.1,4.2-5.5,7.4-12.1,6.9
		c-2.5-0.2-4.3-2.1-6.9-0.6c-3.3,1.9-4.9,7.8-4.6,10.9c0.6,5.9,4,11.8,6.2,17.1c2.1,5.3,3.3,11.1,5.1,16.5c1.7,4.9,2.9,8.3,6.4,12.2
		c2.3,2.6,6,5.1,6.9,8.6c1.8,6.7-10.9,9.5-15.5,13.2C423.3,265,416.9,271.2,415.3,266.8z"
        />
      </g>
    </svg>
  </RouterLink>
</template>

<style scoped></style>
