import { Dayjs } from 'dayjs'
import { z } from 'zod'
import type { UserId, UserTypeEnum } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import type { VCalendarAttribute, VCalendarDay } from '/@src/shims-vue'
import type { AbstractId } from '/@src/types/utils'
import { type TransportType } from '/@src/types/shared'

export type MatchMealId = AbstractId<'match-meal'>

export enum MatchMealStatus {
  Active = 'active',
  Cancelled = 'cancelled',
}

export enum PaymentMethodEnum {
  Collection = 'collection',
  DonationCollection = 'donation-collection',

  Mutual = 'mutual',
  DonationMutual = 'donation-mutual',
}
export const PaymentMethodOptions = z.nativeEnum(PaymentMethodEnum)
export type PaymentMethod = z.infer<typeof PaymentMethodOptions>

export type BasePaymentMethod = PaymentMethodEnum.Mutual | PaymentMethodEnum.Collection

export interface MatchMeal {
  id: MatchMealId
  createdAt: Dayjs
  updatedAt: Dayjs
  cookingDate: Dayjs
  status: MatchMealStatus
  transport: TransportType
  isScheduled: boolean

  mealDescription: string | null
  portions: number
  amountOfPeople: number

  price: number
  commission: number
  noCommission: boolean

  foodieName: string
  cookName: string

  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod

  canBeUpdated: boolean
  addedToBalanceCook: boolean
  addedToBalanceFoodie: boolean
  isFreeMeal: boolean
}

export interface CalendarMatchMeal extends MatchMeal {
  commissionType: UserTypeEnum
  foodieName: string
  cookName: string
  createdByFullName: string | null
}

export interface UpdateMatchMeal {
  cookingDate: Dayjs
  transport: TransportType
  price: number
  portions: number
  amountOfPeople: number
  mealDescription: string | null
  noCommission: boolean
}

export interface CancelProcessedMealParams {
  comment: string
}

export enum MealStatus {
  Active = 'active',
  Cancelled = 'cancelled',
}

export interface PreprocessCancelMeal {
  id: MatchMealId

  cookingDate: Dayjs

  portions: number
  amountOfPeople: number

  price: number
  commission: number
  hasCommission: boolean
  commissionType: UserTypeEnum

  userWalletBalance: {
    [UserRoleEnum.Foodie]: number
    [UserRoleEnum.Cook]: number
  }

  isScheduled: boolean
  status: MatchMealStatus
  shouldBeCorrected: boolean

  paymentMethodCook: PaymentMethodEnum
  paymentMethodFoodie: PaymentMethodEnum
}

export interface ExtendedCalendarDay extends VCalendarDay {
  attributes: VCalendarAttribute[]
}

export interface FinancialReportMatchMeal {
  id: MatchMealId
  foodieUserId: UserId
  cookUserId: UserId

  status: MatchMealStatus
  userWalletCreatedAt: Dayjs

  cookingDate: Dayjs

  portions: number
  amountOfPeople: number

  price: number
  commission: number
  noCommission: boolean
  commissionType: UserTypeEnum

  totalCommission: number
  previousCommission: number
  nextCommission: number

  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod

  comments: string
  matchmakerComments: string
}

export interface FinancialReportMealCommission {
  id: MatchMealId
  foodieUserId: UserId
  cookUserId: UserId

  cookingDate: Dayjs

  cookPrice: number
  foodiePrice: number

  previousCommission: number
  totalCommission: number
  nextCommission: number
}
