import { MutualPaymentInfo } from '/@src/utils/payment-info/MutualPaymentInfo'
import type { IsoDay } from '/@src/types/utils'
import type { UserTypeEnum } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import type {
  NeedsAnyPaymentInfoCheck,
  PriceFromMatchObject,
  RolePaymentInfoCheck,
} from '/@src/utils/payment-info/AbstractPaymentInfo'
import type { FAIconName, TailwindColor } from '/@src/types/elements-ui'
import { DonationIcon } from '/@src/models/standardIcons'
import {
  calculateMealPriceFromMatch,
  calculateTotalCommissionFromMatch,
} from '/@src/utils/payments'

interface MatchFreeParams {
  commissionType: UserTypeEnum
  role: UserTypeEnum
  price: number
  portions: number
  amountOfPeople: number
  hasCommission: boolean
  daysOfWeek: IsoDay[]
}

export class DonationMutualPaymentInfo extends MutualPaymentInfo {
  private matchIsFreeForRole(params: MatchFreeParams): boolean {
    if (params.commissionType !== params.role) {
      return true
    }

    const commission = calculateTotalCommissionFromMatch(
      params.price,
      params.portions,
      params.amountOfPeople,
      params.hasCommission,
    )

    return (
      params.daysOfWeek.length === 1 &&
      params.role === params.commissionType &&
      params.portions === 1 &&
      commission <= 6.6
    )
  }

  getName(commissionType: UserTypeEnum, noSuffix: boolean = false): string {
    if (noSuffix) {
      return 'Donatie (onderling)'
    }
    const userString =
      commissionType === UserRoleEnum.Cook ? 'thuiskok' : 'maaltijdzoeker'
    return `Donatie (onderling / ${userString})`
  }

  getIcon(): FAIconName {
    return DonationIcon as FAIconName
  }

  getColor(): TailwindColor {
    return 'info'
  }

  isDonation(): boolean {
    return true
  }

  roleNeedsPaymentInfo(params: RolePaymentInfoCheck): boolean {
    if (
      this.matchIsFreeForRole({
        ...params,
        hasCommission: !params.noCommission,
      })
    ) {
      return false
    }
    return super.roleNeedsPaymentInfo(params)
  }

  needsAnyPaymentInfo(params: NeedsAnyPaymentInfoCheck): boolean {
    if (
      this.matchIsFreeForRole({
        ...params,
        commissionType: UserRoleEnum.Foodie,
        role: UserRoleEnum.Foodie,
      }) &&
      this.matchIsFreeForRole({
        ...params,
        commissionType: UserRoleEnum.Cook,
        role: UserRoleEnum.Cook,
      })
    ) {
      return false
    }
    return super.needsAnyPaymentInfo(params)
  }

  getBalanceMutationFromMatch(role: UserTypeEnum, match: PriceFromMatchObject): number {
    const mealPrice = calculateMealPriceFromMatch(match.price)

    const portionPrice = this.getActualPriceFromMeal(role, {
      price: mealPrice,
      portions: 1,
      amountOfPeople: match.amountOfPeople,
      commissionType: match.commissionType,
      hasCommission: match.hasCommission,
    })

    const donationAmount = Math.min(portionPrice, 6.6)

    const amount = this.getActualPriceFromMeal(role, {
      price: mealPrice,
      portions: match.portions,
      amountOfPeople: match.amountOfPeople,
      commissionType: match.commissionType,
      hasCommission: match.hasCommission,
    })

    return amount - donationAmount
  }
}
