import type { FAIconName, OptionsIconMap, TailwindColor } from '/@src/types/elements-ui'
import {
  type AdminTask,
  AdminTaskCategoryList,
  AdminTaskPriority,
  AdminTaskStatus,
  TaskableType,
} from '/@src/types/admin-tasks'
import {
  EventsNavbarIcon,
  MatchesNavbarIcon,
  MatchIcon,
  MatchRequestIcon,
  MatchRequestNavbarIcon,
  UserIcon,
} from '/@src/models/standardIcons'

export const FullCalendarEventColor: Record<TaskableType, string> = {
  [TaskableType.User]: 'hsl(171, 100%, 29%)',
  [TaskableType.Match]: 'hsl(141, 53%, 31%)',
  [TaskableType.MatchRequest]: 'hsl(217, 71%, 45%)',
  [TaskableType.EventRequest]: 'hsl(48, 100%, 29%)',
}

export const TaskableTypeMapping = {
  [TaskableType.User]: 'Gebruiker',
  [TaskableType.Match]: 'Match',
  [TaskableType.MatchRequest]: 'Aanvraag',
  [TaskableType.EventRequest]: 'Evenement',
} as const

export const TaskStatusText: Record<AdminTaskStatus, string> = {
  [AdminTaskStatus.Active]: 'Actief',
  [AdminTaskStatus.Completed]: 'Afgerond',
  [AdminTaskStatus.Postponed]: 'Uitgesteld',
  [AdminTaskStatus.Cancelled]: 'Geannuleerd',
}

export const TaskStatusColor: Record<AdminTaskStatus, TailwindColor> = {
  [AdminTaskStatus.Active]: 'info',
  [AdminTaskStatus.Completed]: 'success',
  [AdminTaskStatus.Postponed]: 'info',
  [AdminTaskStatus.Cancelled]: 'danger',
}

export const TaskStatusIcon: Record<AdminTaskStatus, FAIconName> = {
  [AdminTaskStatus.Active]: 'fa-tag',
  [AdminTaskStatus.Completed]: 'fa-check',
  [AdminTaskStatus.Postponed]: 'fa-circle-chevron-down',
  [AdminTaskStatus.Cancelled]: 'fa-circle-xmark',
}

export const TaskPriorityText: Record<AdminTaskPriority, string> = {
  [AdminTaskPriority.Normal]: 'Normaal',
  [AdminTaskPriority.High]: 'Hoog',
}

export const TaskPriorityColor: Record<AdminTaskPriority, TailwindColor> = {
  [AdminTaskPriority.Normal]: 'info',
  [AdminTaskPriority.High]: 'danger',
}

export const TaskPriorityIcon: Record<AdminTaskPriority, FAIconName> = {
  [AdminTaskPriority.Normal]: 'fa-gauge',
  [AdminTaskPriority.High]: 'fa-gauge-high',
}

export const TaskableIcon: Record<TaskableType, FAIconName> = {
  [TaskableType.User]: UserIcon,
  [TaskableType.MatchRequest]: MatchRequestNavbarIcon,
  [TaskableType.Match]: MatchesNavbarIcon,
  [TaskableType.EventRequest]: EventsNavbarIcon,
}

export const taskableTypeOptions: OptionsIconMap<TaskableType>[] = [
  {
    id: TaskableType.User,
    name: 'Gebruiker',
    icon: UserIcon,
  },
  {
    id: TaskableType.Match,
    name: 'Match',
    icon: MatchIcon,
  },
  {
    id: TaskableType.MatchRequest,
    name: 'Aanvraag',
    icon: MatchRequestIcon,
  },
  {
    id: TaskableType.EventRequest,
    name: 'Evenement',
    icon: EventsNavbarIcon,
  },
]

export const priorityOptions: OptionsIconMap<AdminTaskPriority>[] = [
  {
    id: AdminTaskPriority.Normal,
    name: TaskPriorityText[AdminTaskPriority.Normal],
    icon: TaskPriorityIcon[AdminTaskPriority.Normal],
  },
  {
    id: AdminTaskPriority.High,
    name: TaskPriorityText[AdminTaskPriority.High],
    icon: TaskPriorityIcon[AdminTaskPriority.High],
  },
]

export const categoryListIcon: Record<AdminTaskCategoryList, FAIconName> = {
  [AdminTaskCategoryList.MatchRequestSuggestedFollowUp]: 'fa-handshake-angle',
  [AdminTaskCategoryList.MatchFollowUp]: MatchIcon,
  [AdminTaskCategoryList.Balance]: 'fa-money-bills',
  [AdminTaskCategoryList.NotAutomatic]: 'fa-user-secret',
  [AdminTaskCategoryList.Null]: 'fa-file',
  [AdminTaskCategoryList.Finished]: 'fa-flag-checkered',
}

export const repeatActionRegex = /^repeat:(\d+) (months|days|weeks)$/

export function getTaskCategory(task: AdminTask): AdminTaskCategoryList {
  if (task.status !== AdminTaskStatus.Active) {
    return AdminTaskCategoryList.Finished
  } else if (!task.isAutomatic) {
    return AdminTaskCategoryList.NotAutomatic
  } else if (!task.category) {
    return AdminTaskCategoryList.Null
  } else if ((Object.values(AdminTaskCategoryList) as string[]).includes(task.category)) {
    return task.category as AdminTaskCategoryList
  }

  return AdminTaskCategoryList.Null
}
