<script setup lang="ts">
import { sample } from 'lodash'
import { useApi } from '/@src/composable/useApi'

definePage({
  meta: {
    requiresAdmin: true,
  },
})

interface ChristmasResults {
  newMatches: number
  ratings: {
    foodie: number
    cook: number
  }
}

const data = ref<ChristmasResults>()
const showLemons = ref(false)

const { width: viewPortWidth, height: viewPortHeight } = useWindowSize()

const colors = [
  '#751717',
  '#BA0C0C',
  '#CD5C08',
  '#FFEBEB',
  '#ECFFEB',
  '#27A300',
  '#2A850E',
  '#2D661B',
  '#6A9C89',
]

const maxHeight = computed(() => viewPortHeight.value / 10)

const minOrnaments = 15
const maxOrnaments = 20
const numberOfChristmasOrnaments =
  Math.ceil(Math.random() * (maxOrnaments - minOrnaments)) + minOrnaments

const ornamentDistance = computed(() => viewPortWidth.value / numberOfChristmasOrnaments)

const generateRandomColor = () => {
  return sample(colors)
}

const ornaments = computed(() => {
  return Array.from({ length: numberOfChristmasOrnaments }, (_, index) => {
    const radius = Math.floor(Math.random() * 10) + 5
    return {
      x:
        Math.floor((Math.random() * 6 * viewPortWidth.value) / ornamentDistance.value) +
        ornamentDistance.value * index,
      height: Math.floor(Math.random() * (maxHeight.value / 2)) + 20,
      radius: radius,
      color: generateRandomColor(),
      animationDuration: (radius / 5).toFixed(1),
    }
  })
})

const lemonTexts = computed(() => [
  `Jij hebt dit jaar voor ${data.value?.newMatches ?? 322} maaltijdzoekers een thuiskok gevonden`,
  `Maaltijdzoekers geven jou gemiddeld een ${data.value?.ratings.foodie.toFixed(1) ?? 9.0}`,
  `Thuiskoks geven jou gemiddeld een ${data.value?.ratings.cook.toFixed(1) ?? 8.7}`,
  "Minstens 20 collega's jaloers gemaakt op jouw werklocatie",
  'Tenminste 200 dagen opgefleurd (inclusief de onze!)',
])

const lemons = computed(() => {
  return Array.from({ length: 5 }, (_, index) => {
    const angle = (2 * Math.PI * index) / 5 // Evenly spaced angles
    let xFactor = 30
    let yFactor = 40
    if (angle > 0.5 * Math.PI && angle < 1.5 * Math.PI) {
      xFactor = 40
    }
    if (index == 1) {
      yFactor = 23
    }

    return {
      id: index,
      x: `${50 + xFactor * Math.cos(angle)}%`,
      y: `${50 + yFactor * Math.sin(angle)}%`,
      text: lemonTexts.value[index],
    }
  })
})

const api = useApi()
const getData = async () => {
  try {
    const result = await api.get<ChristmasResults>('admin/admin-users/bloem-christmas')
    showLemons.value = true
    data.value = result.data
  } catch {
    showLemons.value = false
  }
}
onMounted(getData)
</script>

<template>
  <div>
    <div class="christmas-ornaments">
      <svg :width="viewPortWidth" :height="maxHeight">
        <g v-for="(ornament, index) in ornaments" :key="index">
          <!-- Hanging line -->
          <path
            :d="`M${ornament.x},0 L${ornament.x},${ornament.height}`"
            stroke="black"
            stroke-width="2"
          />
          <!-- Ornament -->
          <circle
            :cx="ornament.x"
            :cy="ornament.height"
            :r="ornament.radius"
            :fill="ornament.color"
            :style="{
              '--glow-color': ornament.color,
              '--glow-radius': `${ornament.radius * 1.5}px`,
              '--glow-speed': `${ornament.animationDuration}s`,
            }"
          />
        </g>
      </svg>
    </div>

    <div class="lemon-tree-wrapper">
      <p class="top-title">
        <span class="title">
          Deze citroenboom groeit alvast om het afgelopen jaar te vieren!
        </span>
        <br />
        <span class="subtitle">
          Hover over de citroenen om ze te zien groeien en leuke feitjes te zien :-)
        </span>
      </p>

      <div
        v-if="showLemons"
        v-for="lemon in lemons"
        :key="`lemon-${lemon.id}`"
        class="lemon"
        :style="{
          '--x': lemon.x,
          '--y': lemon.y,
        }"
      >
        <svg
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          preserveAspectRatio="xMidYMid meet"
        >
          <g>
            <path
              fill="#5C913B"
              d="M11.405 3.339c6.48-1.275 8.453 1.265 11.655.084c3.202-1.181.093 2.82-.745 3.508c-.84.688-8.141 4.809-11.307 3.298c-3.166-1.511-3.182-6.186.397-6.89z"
            ></path>
            <path
              fill="#77B255"
              d="M15.001 16a.998.998 0 0 1-.801-.4c-.687-.916-1.308-1.955-1.965-3.056C9.967 8.749 7.396 4.446.783 2.976a1 1 0 1 1 .434-1.952C8.671 2.68 11.599 7.581 13.952 11.519c.63 1.054 1.224 2.049 1.848 2.881a1 1 0 0 1-.799 1.6z"
            ></path>
            <path
              fill="#FFCC4D"
              d="M34.3 31.534c.002-.017-.003-.028-.003-.043c2.774-5.335 2.647-15.113-3.346-21.107c-5.801-5.8-13.68-5.821-18.767-4.067c-1.579.614-2.917.066-3.815.965c-.881.881-.351 2.719-.714 3.819c-3.169 5.202-3.405 13.025 2.688 19.117c4.962 4.962 10.438 6.842 19.98 4.853c.002-.002.005-.001.008-.002c1.148-.218 2.95.523 3.566-.094c1.085-1.085.309-2.358.403-3.441z"
            ></path>
            <path
              fill="#77B255"
              d="M8.208 6.583s-4.27-.59-6.857 4.599c-2.587 5.188.582 9.125.29 12.653c-.293 3.53 1.566 1.265 2.621-.445s4.23-4.895 4.938-9.269c.707-4.376-.07-6.458-.992-7.538z"
            ></path>
          </g>
        </svg>
        <span class="lemon-text">
          {{ lemon.text }}
        </span>
      </div>

      <svg class="lemon-tree" viewBox="0 0 447.329 447.329" xml:space="preserve">
        <g>
          <path
            style="fill: #fc6"
            d="M167.69,183.229c-1.015-0.414-2.102-0.625-3.228-0.625c-5.279,0-10.785,4.461-13.391,10.846
          c-3.222,7.9-1.057,16.568,4.727,18.928c1.017,0.414,2.104,0.625,3.23,0.625c5.278,0,10.785-4.461,13.391-10.848
          c1.667-4.084,1.982-8.385,0.889-12.109C172.334,186.731,170.339,184.309,167.69,183.229z"
          />
          <path
            style="fill: #fc6"
            d="M223.664,127.395c-6.248,0-11.527,7.211-11.527,15.744c0,8.533,5.279,15.74,11.527,15.74
          s11.527-7.207,11.527-15.74C235.192,134.606,229.912,127.395,223.664,127.395z"
          />
          <path
            style="fill: #fc6"
            d="M142.965,143.311c2.77-0.721,5.067-2.855,6.472-6.014c1.578-3.545,1.836-7.852,0.727-12.121
          c-1.898-7.303-7.367-12.604-13.003-12.604c-0.714,0-1.424,0.09-2.111,0.27c-2.771,0.719-5.069,2.854-6.474,6.01
          c-1.577,3.547-1.835,7.852-0.725,12.123c1.899,7.305,7.366,12.604,13.003,12.604C141.568,143.579,142.278,143.489,142.965,143.311z
          "
          />
          <rect
            x="162.875"
            y="351.51"
            style="fill: #f66"
            width="121.578"
            height="14.758"
          />
          <polygon
            style="fill: #c63"
            points="186.117,437.329 261.211,437.329 270.27,376.268 177.059,376.268 	"
          />
          <path
            style="fill: #c93"
            d="M227.946,264.903l-0.019-0.012c-0.406,0.674-0.958,1.273-1.68,1.709
          c-0.809,0.488-1.699,0.721-2.578,0.721c-1.694,0-3.348-0.861-4.286-2.418l-13.592-22.531c-2.758,1.514-5.269,3.289-7.524,5.184
          l19.148,30.512c0.5,0.797,0.765,1.719,0.765,2.658v60.785h10.971v-60.785c0-0.94,0.265-1.861,0.765-2.658l19.129-30.481
          c-2.258-1.896-4.768-3.674-7.521-5.191L227.946,264.903z"
          />
          <path
            style="fill: #fc6"
            d="M190.494,50.489c-1.015-0.414-2.101-0.623-3.228-0.623c-5.279,0-10.786,4.459-13.391,10.846
          c-3.221,7.9-1.056,16.568,4.728,18.928c1.016,0.414,2.102,0.625,3.229,0.625c5.279,0,10.785-4.461,13.39-10.846
          c1.668-4.086,1.983-8.387,0.891-12.111C195.139,53.991,193.144,51.571,190.494,50.489z"
          />
          <path
            style="fill: #693"
            d="M348.426,113.032c-1.304-5.408-0.468-11.119,0.341-16.643c0.834-5.693,1.621-11.07-0.192-14.615
          c-1.843-3.6-6.685-6.127-11.81-8.801c-4.941-2.576-10.05-5.242-13.621-9.459c-3.565-4.211-5.341-9.693-7.057-14.994
          c-1.781-5.502-3.464-10.699-6.716-13.148c-3.161-2.379-8.559-2.572-14.272-2.775c-5.643-0.201-11.477-0.41-16.599-2.738
          c-4.989-2.27-8.904-6.475-12.691-10.541c-3.958-4.25-7.696-8.264-11.745-9.131c-3.773-0.809-8.698,1.24-13.912,3.41
          c-5.281,2.197-10.742,4.471-16.487,4.471s-11.206-2.272-16.487-4.471c-5.215-2.17-10.139-4.217-13.911-3.41
          c-4.051,0.867-7.789,4.881-11.747,9.131c-3.787,4.066-7.702,8.271-12.69,10.539c-5.122,2.33-10.956,2.539-16.599,2.74
          c-5.714,0.203-11.111,0.396-14.273,2.775c-3.251,2.449-4.934,7.646-6.715,13.148c-1.716,5.301-3.491,10.783-7.057,14.994
          c-3.571,4.217-8.681,6.883-13.621,9.459c-5.126,2.674-9.967,5.201-11.81,8.801c-1.814,3.547-1.027,8.924-0.193,14.615
          c0.81,5.523,1.646,11.234,0.341,16.645c-1.259,5.223-4.548,9.818-7.73,14.262c-3.417,4.771-6.644,9.279-6.644,13.49
          c0,4.213,3.227,8.721,6.644,13.492c3.182,4.443,6.471,9.039,7.731,14.264c1.304,5.406,0.468,11.117-0.342,16.641
          c-0.834,5.693-1.621,11.07,0.193,14.617c1.843,3.6,6.685,6.125,11.812,8.801c4.939,2.576,10.048,5.242,13.618,9.455
          c3.567,4.213,5.342,9.695,7.058,14.998c1.176,3.631,2.308,7.127,3.876,9.791c4.121-3.723,11.163-8.75,20.314-10.234
          c1.875-0.295,3.754,0.477,4.861,2.018c0.08,0.111,7.184,9.945,17.645,21.07c6.39-9.814,21.514-28.035,45.727-28.035
          c24.212,0,39.337,18.221,45.727,28.037c10.461-11.127,17.564-20.961,17.645-21.072c1.107-1.541,2.989-2.312,4.861-2.018
          c9.151,1.484,16.193,6.512,20.314,10.234c1.568-2.664,2.7-6.16,3.876-9.791c1.716-5.303,3.49-10.785,7.057-14.996
          c3.571-4.215,8.68-6.881,13.619-9.457c5.126-2.676,9.969-5.201,11.812-8.803c1.812-3.543,1.025-8.922,0.191-14.615
          c-0.809-5.523-1.645-11.234-0.34-16.643c1.259-5.223,4.548-9.818,7.729-14.262c3.417-4.771,6.645-9.279,6.645-13.492
          c0-4.211-3.228-8.719-6.645-13.49C352.975,122.852,349.686,118.256,348.426,113.032z M118.173,133.491
          c-1.678-6.453-1.229-13.096,1.266-18.703c1.393-3.131,3.329-5.746,5.681-7.75l-2.115-4.742c-1.125-2.521,0.008-5.479,2.529-6.604
          c2.523-1.123,5.479,0.008,6.604,2.529l2.052,4.602c0.982-0.16,1.975-0.25,2.972-0.25c10.28,0,19.607,8.26,22.681,20.088
          c1.678,6.453,1.227,13.096-1.267,18.701c-2.668,5.998-7.318,10.127-13.095,11.627c-1.508,0.393-3.064,0.59-4.626,0.59
          C130.574,153.579,121.246,145.319,118.173,133.491z M181.679,205.932c-4.166,10.211-13.269,17.07-22.65,17.07
          c-2.43,0-4.787-0.461-7.008-1.367c-10.986-4.48-15.567-18.818-10.209-31.963c4.166-10.209,13.268-17.068,22.65-17.068
          c1.883,0,3.72,0.283,5.486,0.83l2.748-3.592c1.679-2.193,4.816-2.611,7.01-0.934c2.193,1.678,2.611,4.816,0.934,7.01l-2.264,2.959
          c2.027,2.283,3.573,5.105,4.526,8.352C184.632,193.118,184.197,199.76,181.679,205.932z M181.832,90.264
          c-2.429,0-4.786-0.461-7.006-1.365c-10.987-4.482-15.567-18.82-10.21-31.963c4.164-10.211,13.267-17.07,22.65-17.07
          c1.509,0,2.984,0.197,4.423,0.549l2.742-4.145c1.523-2.303,4.625-2.934,6.929-1.412c2.303,1.523,2.935,4.625,1.411,6.93
          l-2.347,3.547c2.402,2.412,4.214,5.516,5.282,9.156c1.729,5.889,1.293,12.531-1.226,18.705
          C200.318,83.405,191.215,90.264,181.832,90.264z M223.664,168.879c-11.87,0-21.527-11.547-21.527-25.74
          c0-12.137,7.061-22.332,16.527-25.035v-5.568c0-2.762,2.238-5,5-5s5,2.238,5,5v5.568c9.466,2.703,16.527,12.898,16.527,25.035
          C245.192,157.333,235.534,168.879,223.664,168.879z M242.845,73.196c-2.519-6.174-2.954-12.816-1.226-18.705
          c1.068-3.641,2.88-6.744,5.282-9.156l-2.347-3.547c-1.523-2.305-0.892-5.406,1.411-6.93c2.305-1.523,5.405-0.893,6.929,1.412
          l2.742,4.145c1.438-0.352,2.914-0.549,4.423-0.549c9.384,0,18.486,6.859,22.65,17.068c5.357,13.144,0.777,27.482-10.21,31.965
          c-2.22,0.904-4.577,1.365-7.006,1.365C256.112,90.264,247.009,83.405,242.845,73.196z M295.306,221.637
          c-2.219,0.904-4.576,1.365-7.005,1.365c0,0,0,0-0.001,0c-9.382-0.002-18.484-6.861-22.65-17.07
          c-2.518-6.172-2.953-12.814-1.224-18.703c0.953-3.246,2.499-6.068,4.526-8.352l-2.264-2.959c-1.678-2.193-1.26-5.332,0.934-7.01
          c2.195-1.678,5.332-1.26,7.01,0.934l2.748,3.592c1.767-0.547,3.603-0.83,5.487-0.83c9.383,0,18.485,6.859,22.649,17.068
          C310.875,202.817,306.294,217.155,295.306,221.637z M329.156,133.491c-3.073,11.828-12.4,20.088-22.681,20.088
          c-1.561,0-3.118-0.197-4.627-0.59c-5.776-1.5-10.427-5.629-13.094-11.627c-2.494-5.605-2.944-12.248-1.267-18.701
          c3.073-11.828,12.4-20.088,22.683-20.088c0.995,0,1.988,0.09,2.97,0.25l2.052-4.602c1.126-2.521,4.081-3.652,6.604-2.529
          c2.521,1.125,3.654,4.082,2.529,6.604l-2.115,4.742c2.352,2.004,4.289,4.619,5.682,7.75
          C330.384,120.395,330.833,127.038,329.156,133.491z"
          />
          <path
            style="fill: #fc6"
            d="M282.867,182.604c-1.127,0-2.214,0.211-3.229,0.625c-2.649,1.08-4.645,3.502-5.618,6.816
          c-1.094,3.725-0.778,8.025,0.889,12.109c2.605,6.387,8.112,10.846,13.392,10.848h0.001c1.126,0,2.212-0.211,3.227-0.625
          c5.785-2.359,7.95-11.027,4.728-18.93C293.653,187.063,288.147,182.604,282.867,182.604z"
          />
          <path
            style="fill: #fc6"
            d="M312.284,112.842c-0.69-0.18-1.4-0.27-2.114-0.27c-5.638,0-11.107,5.301-13.005,12.604
          c-1.109,4.271-0.852,8.576,0.726,12.123c1.404,3.156,3.703,5.291,6.472,6.01c0.689,0.18,1.398,0.27,2.112,0.27
          c5.637,0,11.104-5.299,13.003-12.604c1.11-4.271,0.854-8.576-0.725-12.123C317.35,115.696,315.052,113.561,312.284,112.842z"
          />
          <path
            style="fill: #fc6"
            d="M268.723,79.639c5.783-2.359,7.948-11.027,4.727-18.928c-2.604-6.387-8.111-10.846-13.391-10.846
          c-1.127,0-2.213,0.209-3.227,0.623c-2.649,1.082-4.645,3.502-5.618,6.818c-1.093,3.725-0.777,8.025,0.891,12.111
          c2.605,6.385,8.11,10.846,13.39,10.846C266.621,80.264,267.707,80.053,268.723,79.639z"
          />
          <path
            style="fill: #633"
            d="M228.664,118.104v-5.568c0-2.762-2.238-5-5-5s-5,2.238-5,5v5.568
          c-9.466,2.703-16.527,12.898-16.527,25.035c0,14.193,9.657,25.74,21.527,25.74s21.527-11.547,21.527-25.74
          C245.192,131.002,238.13,120.807,228.664,118.104z M223.664,158.879c-6.248,0-11.527-7.207-11.527-15.74
          c0-8.533,5.279-15.744,11.527-15.744s11.527,7.211,11.527,15.744C235.192,151.672,229.912,158.879,223.664,158.879z"
          />
          <path
            style="fill: #633"
            d="M145.48,152.989c5.776-1.5,10.427-5.629,13.095-11.627c2.494-5.605,2.944-12.248,1.267-18.701
          c-3.073-11.828-12.4-20.088-22.681-20.088c-0.997,0-1.99,0.09-2.972,0.25l-2.052-4.602c-1.125-2.521-4.08-3.652-6.604-2.529
          c-2.521,1.125-3.654,4.082-2.529,6.604l2.115,4.742c-2.352,2.004-4.288,4.619-5.681,7.75c-2.494,5.607-2.943,12.25-1.266,18.703
          c3.073,11.828,12.4,20.088,22.681,20.088C142.415,153.579,143.972,153.381,145.48,152.989z M127.851,130.975
          c-1.11-4.271-0.853-8.576,0.725-12.123c1.404-3.156,3.702-5.291,6.474-6.01c0.688-0.18,1.397-0.27,2.111-0.27
          c5.636,0,11.105,5.301,13.003,12.604c1.109,4.27,0.852,8.576-0.727,12.121c-1.404,3.158-3.702,5.293-6.472,6.014
          c-0.688,0.178-1.397,0.268-2.111,0.268C135.217,143.579,129.749,138.28,127.851,130.975z"
          />
          <path
            style="fill: #633"
            d="M322.209,107.038l2.115-4.742c1.125-2.521-0.008-5.479-2.529-6.604
          c-2.522-1.123-5.478,0.008-6.604,2.529l-2.052,4.602c-0.982-0.16-1.975-0.25-2.97-0.25c-10.282,0-19.609,8.26-22.683,20.088
          c-1.678,6.453-1.228,13.096,1.267,18.701c2.667,5.998,7.317,10.127,13.094,11.627c1.509,0.393,3.065,0.59,4.627,0.59
          c10.28,0,19.608-8.26,22.681-20.088c1.678-6.453,1.229-13.096-1.265-18.703C326.498,111.657,324.561,109.042,322.209,107.038z
           M319.478,130.975c-1.898,7.305-7.366,12.604-13.003,12.604c-0.714,0-1.424-0.09-2.112-0.27c-2.768-0.719-5.067-2.854-6.472-6.01
          c-1.577-3.547-1.835-7.852-0.726-12.123c1.898-7.303,7.367-12.604,13.005-12.604c0.714,0,1.424,0.09,2.114,0.27
          c2.768,0.719,5.065,2.854,6.469,6.01C320.331,122.399,320.588,126.704,319.478,130.975z"
          />
          <path
            style="fill: #633"
            d="M205.707,54.491c-1.068-3.641-2.88-6.744-5.282-9.156l2.347-3.547
          c1.523-2.305,0.892-5.406-1.411-6.93c-2.304-1.521-5.405-0.891-6.929,1.412l-2.742,4.145c-1.439-0.352-2.914-0.549-4.423-0.549
          c-9.384,0-18.486,6.859-22.65,17.07c-5.357,13.143-0.777,27.48,10.21,31.963c2.22,0.904,4.577,1.365,7.006,1.365
          c9.383,0,18.485-6.859,22.649-17.068C207,67.022,207.436,60.379,205.707,54.491z M195.222,69.419
          c-2.605,6.385-8.11,10.846-13.39,10.846c-1.127,0-2.213-0.211-3.229-0.625c-5.783-2.359-7.948-11.027-4.728-18.928
          c2.605-6.387,8.111-10.846,13.391-10.846c1.127,0,2.213,0.209,3.228,0.623c2.649,1.082,4.645,3.502,5.618,6.818
          C197.205,61.032,196.89,65.333,195.222,69.419z"
          />
          <path
            style="fill: #633"
            d="M272.5,88.899c10.987-4.482,15.567-18.82,10.21-31.965c-4.164-10.209-13.267-17.068-22.65-17.068
          c-1.509,0-2.984,0.197-4.423,0.549l-2.742-4.145c-1.523-2.305-4.624-2.936-6.929-1.412c-2.303,1.523-2.935,4.625-1.411,6.93
          l2.347,3.547c-2.402,2.412-4.214,5.516-5.282,9.156c-1.728,5.889-1.293,12.531,1.226,18.705
          c4.164,10.209,13.267,17.068,22.649,17.068C267.923,90.264,270.281,89.803,272.5,88.899z M252.105,69.419
          c-1.668-4.086-1.983-8.387-0.891-12.111c0.974-3.316,2.969-5.736,5.618-6.818c1.015-0.414,2.101-0.623,3.227-0.623
          c5.279,0,10.786,4.459,13.391,10.846c3.221,7.9,1.056,16.568-4.727,18.928c-1.016,0.414-2.102,0.625-3.229,0.625
          C260.215,80.264,254.709,75.803,252.105,69.419z"
          />
          <path
            style="fill: #633"
            d="M178.376,178.877l2.264-2.959c1.678-2.193,1.26-5.332-0.934-7.01
          c-2.194-1.678-5.331-1.26-7.01,0.934l-2.748,3.592c-1.767-0.547-3.604-0.83-5.486-0.83c-9.382,0-18.484,6.859-22.65,17.068
          c-5.358,13.145-0.777,27.482,10.209,31.963c2.221,0.906,4.578,1.367,7.008,1.367c9.382,0,18.484-6.859,22.65-17.07
          c2.518-6.172,2.953-12.814,1.224-18.703C181.95,183.983,180.404,181.161,178.376,178.877z M172.419,202.155
          c-2.605,6.387-8.112,10.848-13.391,10.848c-1.127,0-2.214-0.211-3.23-0.625c-5.783-2.359-7.948-11.027-4.727-18.928
          c2.605-6.385,8.111-10.846,13.391-10.846c1.126,0,2.213,0.211,3.228,0.625c2.649,1.08,4.645,3.502,5.618,6.816
          C174.402,193.77,174.086,198.071,172.419,202.155z"
          />
          <path
            style="fill: #633"
            d="M282.867,172.604c-1.884,0-3.721,0.283-5.487,0.83l-2.748-3.592
          c-1.678-2.193-4.814-2.611-7.01-0.934c-2.193,1.678-2.611,4.816-0.934,7.01l2.264,2.959c-2.027,2.283-3.573,5.105-4.526,8.352
          c-1.729,5.889-1.294,12.531,1.224,18.703c4.166,10.209,13.268,17.068,22.65,17.07c0.001,0,0.001,0,0.001,0
          c2.429,0,4.786-0.461,7.005-1.365c10.988-4.482,15.569-18.82,10.211-31.965C301.353,179.463,292.25,172.604,282.867,172.604z
           M291.529,212.377c-1.015,0.414-2.101,0.625-3.227,0.625h-0.001c-5.279-0.002-10.786-4.461-13.392-10.848
          c-1.667-4.084-1.982-8.385-0.889-12.109c0.974-3.314,2.969-5.736,5.618-6.816c1.015-0.414,2.102-0.625,3.229-0.625
          c5.279,0,10.785,4.459,13.39,10.844C299.479,201.35,297.314,210.018,291.529,212.377z"
          />
          <path
            style="fill: #633"
            d="M358.148,110.69c-0.852-3.531-0.189-8.059,0.514-12.852c1.024-6.996,2.084-14.23-1.186-20.621
          c-3.295-6.436-9.798-9.828-16.086-13.109c-4.273-2.231-8.311-4.336-10.615-7.057c-2.29-2.705-3.691-7.031-5.174-11.611
          c-2.079-6.424-4.436-13.705-10.215-18.057c-5.675-4.273-12.922-4.531-19.931-4.781c-4.884-0.174-9.496-0.338-12.814-1.848
          c-3.172-1.443-6.253-4.752-9.515-8.254c-4.612-4.953-9.842-10.568-16.968-12.092c-6.821-1.465-13.446,1.289-19.85,3.955
          c-4.575,1.904-8.897,3.703-12.645,3.703s-8.069-1.799-12.645-3.703c-6.402-2.666-13.021-5.42-19.85-3.957
          c-7.128,1.527-12.357,7.141-16.97,12.094c-3.262,3.504-6.342,6.811-9.513,8.254c-3.318,1.51-7.931,1.674-12.814,1.848
          c-7.009,0.25-14.256,0.508-19.932,4.781c-5.777,4.352-8.135,11.633-10.214,18.057c-1.482,4.58-2.884,8.906-5.174,11.611
          c-2.305,2.721-6.342,4.826-10.615,7.057c-6.289,3.281-12.792,6.674-16.087,13.111c-3.27,6.389-2.21,13.623-1.186,20.619
          c0.702,4.793,1.365,9.32,0.514,12.852c-0.802,3.33-3.395,6.949-6.139,10.783c-3.99,5.574-8.514,11.891-8.514,19.312
          c0,7.424,4.523,13.74,8.514,19.314c2.744,3.832,5.337,7.453,6.14,10.785c0.851,3.527,0.188,8.055-0.515,12.848
          c-1.024,6.996-2.084,14.232,1.187,20.623c3.294,6.436,9.798,9.828,16.088,13.109c4.273,2.23,8.31,4.336,10.613,7.055
          c2.291,2.705,3.691,7.031,5.174,11.613c2.181,6.736,4.436,13.703,10.212,18.053c2.174,1.637,5.258,1.236,6.939-0.904
          c0.064-0.082,6.099-7.484,15.281-10.15c3.472,4.557,11.276,14.221,21.243,24.32c1.578,1.6,3.531,1.508,4.373,1.395
          c1.721-0.234,2.964-1.299,3.667-2.758c0.059-0.123,2.63-5.326,7.588-11.248l17.148,27.324v59.346h-50.305c-2.762,0-5,2.238-5,5
          v24.758c0,2.762,2.238,5,5,5h9.073l9.91,66.795c0.364,2.451,2.469,4.266,4.946,4.266h83.719c2.478,0,4.582-1.814,4.946-4.266
          l9.91-66.795h9.073c2.762,0,5-2.238,5-5V346.51c0-2.762-2.238-5-5-5h-50.303v-59.346l17.13-27.295
          c4.961,5.912,7.547,11.1,7.604,11.219c0.703,1.459,2.074,2.463,3.667,2.758c1.447,0.266,3.427-0.449,4.373-1.395
          c10.032-10.033,17.772-19.766,21.244-24.322c9.222,2.672,15.225,10.084,15.28,10.152c1.683,2.139,4.766,2.539,6.939,0.904
          c5.776-4.35,8.031-11.316,10.212-18.053c1.482-4.582,2.883-8.908,5.173-11.613c2.305-2.719,6.341-4.826,10.614-7.055
          c6.289-3.281,12.792-6.674,16.089-13.111c3.269-6.389,2.209-13.623,1.185-20.619c-0.702-4.795-1.364-9.322-0.513-12.852
          c0.802-3.33,3.395-6.951,6.139-10.783c3.99-5.574,8.514-11.891,8.514-19.314c0-7.422-4.523-13.738-8.514-19.312
          C361.543,117.639,358.95,114.02,358.148,110.69z M261.211,437.329h-75.094l-9.059-61.06h93.211L261.211,437.329z M284.453,351.51
          v14.758H162.875V351.51H284.453z M229.915,278.067c-0.5,0.797-0.765,1.719-0.765,2.658v60.785H218.18v-60.785
          c0-0.94-0.265-1.861-0.765-2.658l-19.148-30.512c2.256-1.895,4.767-3.67,7.524-5.184l13.592,22.531
          c0.938,1.557,2.592,2.418,4.286,2.418c0.879,0,1.77-0.232,2.578-0.721c0.722-0.436,1.273-1.035,1.68-1.709l0.019,0.012
          l13.577-22.508c2.754,1.518,5.264,3.295,7.521,5.191L229.915,278.067z M215.243,238.678c2.649-0.641,5.451-1.016,8.421-1.016
          c2.966,0,5.767,0.379,8.417,1.023l-8.417,13.953L215.243,238.678z M356.157,154.278c-3.182,4.443-6.471,9.039-7.729,14.262
          c-1.305,5.408-0.469,11.119,0.34,16.643c0.834,5.693,1.621,11.072-0.191,14.615c-1.844,3.602-6.686,6.127-11.812,8.803
          c-4.939,2.576-10.048,5.242-13.619,9.457c-3.566,4.211-5.341,9.693-7.057,14.996c-1.176,3.631-2.308,7.127-3.876,9.791
          c-4.121-3.723-11.163-8.75-20.314-10.234c-1.872-0.295-3.754,0.477-4.861,2.018c-0.08,0.111-7.184,9.945-17.645,21.072
          c-6.39-9.816-21.515-28.037-45.727-28.037c-24.213,0-39.337,18.221-45.727,28.035c-10.461-11.125-17.564-20.959-17.645-21.07
          c-1.107-1.541-2.986-2.312-4.861-2.018c-9.151,1.484-16.193,6.512-20.314,10.234c-1.568-2.664-2.7-6.16-3.876-9.791
          c-1.716-5.303-3.49-10.785-7.058-14.998c-3.57-4.213-8.679-6.879-13.618-9.455c-5.127-2.676-9.969-5.201-11.812-8.801
          c-1.814-3.547-1.027-8.924-0.193-14.617c0.81-5.523,1.646-11.234,0.342-16.641c-1.26-5.225-4.549-9.82-7.731-14.264
          c-3.417-4.771-6.644-9.279-6.644-13.492c0-4.211,3.227-8.719,6.644-13.49c3.182-4.443,6.471-9.039,7.73-14.262
          c1.305-5.41,0.469-11.121-0.341-16.645c-0.834-5.691-1.621-11.068,0.193-14.615c1.843-3.6,6.684-6.127,11.81-8.801
          c4.94-2.576,10.05-5.242,13.621-9.459c3.565-4.211,5.341-9.693,7.057-14.994c1.781-5.502,3.464-10.699,6.715-13.148
          c3.162-2.379,8.56-2.572,14.273-2.775c5.643-0.201,11.477-0.41,16.599-2.74c4.988-2.268,8.903-6.473,12.69-10.539
          c3.958-4.25,7.696-8.264,11.747-9.131c3.772-0.807,8.696,1.24,13.911,3.41c5.281,2.199,10.742,4.471,16.487,4.471
          s11.206-2.273,16.487-4.471c5.214-2.17,10.139-4.219,13.912-3.41c4.049,0.867,7.787,4.881,11.745,9.131
          c3.787,4.066,7.702,8.271,12.691,10.541c5.122,2.328,10.956,2.537,16.599,2.738c5.714,0.203,11.111,0.396,14.272,2.775
          c3.252,2.449,4.935,7.646,6.716,13.148c1.716,5.301,3.491,10.783,7.057,14.994c3.571,4.217,8.68,6.883,13.621,9.459
          c5.125,2.674,9.967,5.201,11.81,8.801c1.813,3.545,1.026,8.922,0.192,14.615c-0.809,5.523-1.645,11.234-0.341,16.643
          c1.26,5.225,4.549,9.82,7.73,14.264c3.417,4.771,6.645,9.279,6.645,13.49C362.801,144.999,359.574,149.506,356.157,154.278z"
          />
        </g>
      </svg>

      <p class="bottom-title title">En nu kan je zelf een matchende boom groeien!</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.lemon-tree-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 5vh;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 5px 4px #000);

  .top-title {
    position: absolute;
    top: 5%;
    text-align: center;
    margin-bottom: 8vh;
  }

  .bottom-title {
    position: absolute;
    bottom: 5%;
    text-align: center;
  }

  .lemon-tree {
    width: 40%;
    height: 40%;
    max-height: 50vh;
  }

  .lemon {
    position: absolute;
    width: 5%;
    height: 5%;
    left: var(--x);
    top: var(--y);
    transition: all 0.5s ease-in-out;
    z-index: 10;

    .lemon-text {
      position: absolute;
      top: 55%;
      bottom: 35%;
      left: 30%;
      right: 10%;
      color: black;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      width: 20%;
      height: 20%;

      .lemon-text {
        opacity: 1;
        transition-delay: 0.3s;
      }
    }
  }
}
</style>
