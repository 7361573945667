import * as Sentry from '@sentry/browser'

export function sendSentryMessage(
  message: string,
  tag: string,
  data: Record<string, unknown> | null = null,
) {
  Sentry.withScope((scope) => {
    scope.setLevel('debug')
    scope.setFingerprint([tag])
    scope.setContext(tag, data)

    Sentry.captureMessage(message)
  })
}
