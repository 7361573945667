<script setup lang="ts">
import { useAuthStore } from '/@src/stores/auth'
import { devIsInProdEnvironment } from '/@src/utils/entity-checks'
import { useDarkMode } from '/@src/stores/darkmode'

const authStore = useAuthStore()
const darkModeStore = useDarkMode()

const isDark = computed(() => darkModeStore.isDark)

const backgroundColor = computed(() => {
  if (!!authStore.adminUser) {
    return 'tw-bg-danger'
  } else if (devIsInProdEnvironment.value) {
    return 'tw-bg-primary'
  } else if (isDark.value) {
    return 'tw-bg-background-navbar'
  } else {
    return 'tw-bg-white'
  }
})
</script>

<template>
  <div class="tw-navbar" :class="backgroundColor">
    <div class="tw-mr-9">
      <slot name="title" />
    </div>

    <div class="tw-overflow-y-hidden sm:tw-hidden">
      <slot name="links" />
    </div>

    <div class="tw-overflow-hidden md:tw-hidden">
      <slot name="mobile-links" />
    </div>

    <div class="tw-flex">
      <div class="sm:tw-hidden">
        <slot name="toolbar" />
      </div>
      <slot name="bottom-bar" />
    </div>
  </div>

  <slot name="subnav" />
</template>

<style scoped></style>
