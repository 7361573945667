import type {
  CompletedPaymentBatchResult,
  CompletedPaymentBatchResults,
} from '/@src/types/payments'
import type { MaybeArray, PaymentInformation } from '/@src/types/utils'
import { type PaymentMethod, PaymentMethodEnum } from '/@src/types/meals'
import { match } from 'ts-pattern'
import { CollectionPaymentInfo } from '/@src/utils/payment-info/CollectionPaymentInfo'
import { MutualPaymentInfo } from '/@src/utils/payment-info/MutualPaymentInfo'
import type { MaybeRefOrGetter } from 'vue'
import { AbstractPaymentInfo } from '/@src/utils/payment-info/AbstractPaymentInfo'
import type { UserTypeEnum } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import { DonationCollectionPaymentInfo } from '/@src/utils/payment-info/DonationCollectionPaymentInfo'
import { DonationMutualPaymentInfo } from '/@src/utils/payment-info/DonationMutualPaymentInfo'

export function calculateTaxes(balance: number, taxPercentage: number = 21) {
  return (balance / (taxPercentage + 100)) * taxPercentage
}

export function calculateBalanceWithoutTaxes(
  balance: number,
  taxPercentage: number = 21,
) {
  return (balance / (taxPercentage + 100)) * 100
}

export function calculateCommissionFromMeal(price: number) {
  return Math.round(100 * (price * 0.1)) / 100
}

export function calculateTotalPriceFromMeal(price: number, hasCommission: boolean) {
  if (hasCommission) {
    return price + calculateCommissionFromMeal(price)
  } else {
    return price
  }
}

export function calculateTotalPriceWithoutCommission(
  price: number,
  portions: number,
  amountOfPeople: number,
) {
  return calculateTotalPriceFromMeal(price, false) * portions * amountOfPeople
}

export function calculateTotalCommissionFromMeal(
  price: number,
  portions: number,
  amountOfPeople: number,
  hasCommission: boolean,
) {
  if (!hasCommission) {
    return 0
  } else {
    return calculateCommissionFromMeal(price) * portions * amountOfPeople
  }
}

export function calculateTotalPrice(
  price: number,
  portions: number,
  amountOfPeople: number,
  hasCommission: boolean,
) {
  return calculateTotalPriceFromMeal(price, hasCommission) * portions * amountOfPeople
}

export function calculateMealPriceFromMatch(price: number) {
  return (price / 110) * 100
}

export function calculateMatchPriceFromMeal(price: number) {
  return (price / 100) * 110
}

export function calculateCommissionOfMealPriceFromMatch(price: number) {
  return (price / 110) * 10
}

export function calculateTotalCommissionFromMatch(
  price: number,
  portions: number,
  amountOfPeople: number,
  hasCommission: boolean,
) {
  if (!hasCommission) {
    return 0
  }
  return calculateCommissionOfMealPriceFromMatch(price) * portions * amountOfPeople
}

export function calculateTotalMealPriceFromMatch(price: number, hasCommission: boolean) {
  if (hasCommission) {
    return price
  } else {
    return calculateMealPriceFromMatch(price)
  }
}

export function calculateMealPriceFromMatchWithoutCommission(
  price: number,
  portions: number,
  amountOfPeople: number,
) {
  return calculateTotalMealPriceFromMatch(price, false) * portions * amountOfPeople
}

export function calculateTotalPriceFromMatch(
  price: number,
  portions: number,
  amountOfPeople: number,
  hasCommission: boolean,
) {
  return (
    calculateTotalMealPriceFromMatch(price, hasCommission) * portions * amountOfPeople
  )
}

export type PaymentStatus =
  | 'recurring-complete'
  | 'recurring-incomplete'
  | 'manual-complete'
  | 'manual-partial'
  | 'manual-incomplete'
  | 'unknown'

export function getPaymentInformationStatus(
  paymentInformation: PaymentInformation | undefined,
): PaymentStatus {
  if (!paymentInformation) {
    return 'unknown'
  }

  if (paymentInformation.consentAt) {
    if (paymentInformation.recurringToken) {
      return 'recurring-complete'
    } else {
      return 'recurring-incomplete'
    }
  } else if (paymentInformation.consentOverrideAt) {
    if (paymentInformation.bankaccountName && paymentInformation.bankaccountNumber) {
      return 'manual-complete'
    } else if (
      !!paymentInformation.bankaccountName !== !!paymentInformation.bankaccountNumber
    ) {
      return 'manual-partial'
    } else {
      return 'manual-incomplete'
    }
  } else {
    return 'unknown'
  }
}

/**
 * Very cursed way of parsing the xml results from past payouts to a valid object that can be used by
 * PaymentBatchModal
 *
 * A lot of this function only works if you go by the rule of "Just trust me bro"
 * @param parsedXml
 */
export function convertParsedXmlToCompletedPaymentBatchResults(
  parsedXml: any,
): CompletedPaymentBatchResults {
  let parsedUsers: MaybeArray<any[]> =
    parsedXml.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf
  if (!Array.isArray(parsedUsers)) {
    parsedUsers = [parsedUsers]
  }

  const completed: CompletedPaymentBatchResult[] = parsedUsers.map((user) => {
    return {
      userId: user.PmtId.EndToEndId,
      name: user.Cdtr.Nm,
      balance: parseFloat(user.Amt.InstdAmt),
    }
  })

  return {
    completed: completed,
    failed: [],
  }
}

export interface PaymentMethodObject {
  paymentMethodFoodie: PaymentMethod
  paymentMethodCook: PaymentMethod
}

export function getPaymentInfo(
  paymentMethod: MaybeRefOrGetter<PaymentMethodEnum>,
): AbstractPaymentInfo {
  return match(toValue(paymentMethod))
    .with(PaymentMethodEnum.Collection, () => new CollectionPaymentInfo())
    .with(PaymentMethodEnum.DonationCollection, () => new DonationCollectionPaymentInfo())
    .with(PaymentMethodEnum.Mutual, () => new MutualPaymentInfo())
    .with(PaymentMethodEnum.DonationMutual, () => new DonationMutualPaymentInfo())
    .exhaustive()
}

interface BackwardsCompatiblePaymentInfo {
  paymentMethod?: PaymentMethodEnum
  paymentMethodCook?: PaymentMethodEnum
  paymentMethodFoodie?: PaymentMethodEnum
}
export function backwardsCompatibleGetPaymentInfo(
  modelRef: MaybeRefOrGetter<BackwardsCompatiblePaymentInfo>,
) {
  const model = toValue(modelRef)
  if (model.paymentMethod) {
    return getPaymentInfo(model.paymentMethod)
  } else if (model.paymentMethodFoodie) {
    return getPaymentInfo(model.paymentMethodFoodie)
  } else if (model.paymentMethodCook) {
    return getPaymentInfo(model.paymentMethodCook)
  } else {
    return undefined
  }
}

/**
 * Gets the specific payment info for the user from a match or meal
 * @param role
 * @param paymentMethodsObject
 */
export function getPaymentInfoForUser(
  role: UserTypeEnum,
  paymentMethodsObject: MaybeRefOrGetter<PaymentMethodObject>,
): AbstractPaymentInfo {
  const paymentMethods = toValue(paymentMethodsObject)
  if (role === UserRoleEnum.Cook) {
    return getPaymentInfo(paymentMethods.paymentMethodCook)
  } else {
    return getPaymentInfo(paymentMethods.paymentMethodFoodie)
  }
}

/**
 * Finds the dominating payment method for different components
 * @param paymentMethodsObject
 */
export function determinePaymentMethod(
  paymentMethodsObject: MaybeRefOrGetter<PaymentMethodObject>,
): PaymentMethod {
  const paymentMethods = toValue(paymentMethodsObject)

  const paymentInfoCook = getPaymentInfo(paymentMethods.paymentMethodCook)
  const paymentInfoFoodie = getPaymentInfo(paymentMethods.paymentMethodFoodie)

  return match([
    paymentInfoCook.getBasePaymentMethod(),
    paymentInfoFoodie.getBasePaymentMethod(),
  ])
    .with(
      [PaymentMethodEnum.Collection, PaymentMethodEnum.Collection],
      () => PaymentMethodEnum.Collection,
    )
    .with(
      [PaymentMethodEnum.Mutual, PaymentMethodEnum.Mutual],
      () => PaymentMethodEnum.Mutual,
    )
    .otherwise(() => PaymentMethodEnum.Collection)
}

/**
 * Finds the dominating payment method for different components
 * @param paymentMethodsObject
 */
export function determinePaymentInfo(
  paymentMethodsObject: MaybeRefOrGetter<PaymentMethodObject>,
): AbstractPaymentInfo {
  const paymentMethods = toValue(paymentMethodsObject)
  const paymentMethod = determinePaymentMethod(paymentMethods)
  return getPaymentInfo(paymentMethod)
}
