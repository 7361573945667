import {
  type FormValidationResult as VeeValidateFormValidationResult,
  type Path,
  type PathValue,
  useField as internalUseField,
  useForm as internalUseForm,
} from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import type { RecursiveUseFieldValue, UseFieldPath } from '/@src/types/utils'
import { cloneDeep, fromPairs, isEmpty, omit } from 'lodash'
import { generateRandomString } from '/@src/utils/helpers'
import { useBuggieStore } from '/@src/stores/buggie'
import {
  type FormWrapperProps,
  getZodSchemaKeys,
  implement,
  type MaybeZodEffectType,
  type ZodSchemaType,
} from '/@src/utils/zodUtils'
import type { ZodObject } from 'zod'
import type { PartialDeep } from 'type-fest'

interface UseFormParams<T extends object> {
  readonly id: string
  readonly schema: ZodSchemaType<T>
  readonly refine?: (
    schema: ZodObject<ZodSchemaType<T>>,
  ) => MaybeZodEffectType<ZodObject<ZodSchemaType<T>>>
  initialValues?: PartialDeep<T>
}

interface FormValidationResult<T extends object>
  extends VeeValidateFormValidationResult<T> {
  values: T
}

export function useTypedForm<const T extends object>(
  formParams: NoInfer<UseFormParams<T>>,
) {
  const id = `${formParams.id}-${generateRandomString(5)}`
  const buggieStore = useBuggieStore()

  let implementedSchema: MaybeZodEffectType<ZodObject<ZodSchemaType<T>>> =
    implement<T>().with({ ...formParams.schema })
  if (formParams.refine) {
    implementedSchema = formParams.refine(implementedSchema)
  }
  const tempSchema = cloneDeep(implementedSchema)

  const form = internalUseForm<T>({
    name: id,
    validationSchema: toTypedSchema(implementedSchema),
    initialValues: formParams.initialValues,
  })

  const { errors } = form
  watch(errors, (newValue) => {
    if (!isEmpty(newValue)) {
      buggieStore.putValidationError(id, newValue)
    }
  })
  onUnmounted(() => {
    buggieStore.removeValidationError(id)
  })

  function useField<K extends UseFieldPath<T>>(key: K) {
    return internalUseField<RecursiveUseFieldValue<T, K>>(key as string)
  }

  function setFieldValue<K extends UseFieldPath<T>>(
    key: K,
    value: RecursiveUseFieldValue<T, K> | undefined,
  ) {
    form.setFieldValue(key as unknown as Path<T>, value as PathValue<T, Path<T>>)
  }

  const validate = async (): Promise<FormValidationResult<T>> => {
    return (await form.validate()) as FormValidationResult<T>
  }

  const omittedForm = omit(form, ['setFieldValue', 'validate'])

  const keys = fromPairs(getZodSchemaKeys(tempSchema).map((key) => [key, key])) as Record<
    UseFieldPath<T>,
    string
  >

  const formProps: FormWrapperProps<T> = {
    id: id,
    schema: keys,
    errors: () => errors.value,
  }

  return {
    useField,
    setFieldValue,
    validate,
    formProps,
    ...omittedForm,
  }
}
