<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useAuthStore } from '/@src/stores/auth'
import { useReportingStore } from '/@src/stores/reporting'
import {
  AbsenteeRate,
  AbsenteeRateCollectTypeParam,
  AbsenteeRateCountsTypeParam,
  AbsenteeRateFiltersParam,
  AbsenteeRateGroupNameFiltersParam,
  AbsenteeRateGroupTypeParam,
  DefaultTimePeriodParam,
  type Report,
  type ReportingSortOption,
  type ReportParamOptions,
  type ReportParams,
  type StatusGrouping,
} from '/@src/types/reporting'
import { absenteeRatePresets, defaultReportTimeOptions } from '/@src/mapping/reporting'
import { UserRoleEnum } from '/@src/types/users'
import { dayJSZod } from '/@src/utils/zodUtils'
import { z } from 'zod'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { isMatch } from 'lodash'
import type { OptionsMap } from '/@src/types/elements-ui'
import ReportingTable from '/@src/components/molecules/table/ReportingTable.vue'
import { getReportingRows, setGroupedTogetherParameter } from '/@src/utils/reporting'
import AbsenteeRateModalCell from '/@src/components/molecules/reporting/actan/AbsenteeRateModalCell.vue'

useHead({
  title: 'Ziekteverzuim Rapportage | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: ['office-manager', 'general-employee'],
  },
})

const authStore = useAuthStore()
const reportingStore = useReportingStore()

const router = useRouter()
const route = useRoute('/reporting/absentee-rate')

const reportingTable = useTemplateRef('reportingTable')

const isLoading = ref(false)

const reportData = ref<Report<'absenteeRate', AbsenteeRate> | null>(null)

const nameFilter = ref('')

const rows = computed<string[]>(() => {
  if (!reportData.value) {
    return []
  }

  const rowKeys = Object.keys(reportData.value.data)

  return getReportingRows(
    rowKeys,
    reportData.value.params,
    reportingStore.sortOptions.absenteeRate,
    nameFilter.value,
    false,
    (row: string) => {
      const absenteeRateData = reportData.value!.data[row]
        ?.total as unknown as StatusGrouping<AbsenteeRate>

      if (!absenteeRateData) {
        return 0
      }

      return absenteeRateData.short + absenteeRateData.medium + absenteeRateData.long
    },
  )
})

const resetFormToRole = () => {
  const foundRole = authStore.authUser.roles.find((role) =>
    Object.keys(absenteeRatePresets).find((presetKey) => presetKey === role.role),
  )

  const newPreferences = reportingStore.resetPreferences<'absenteeRate'>(
    'absenteeRate',
    (foundRole?.role as UserRoleEnum) ?? UserRoleEnum.Administrator,
  )

  if (newPreferences) {
    resetForm({
      values: newPreferences,
    })
  }
}

const { handleSubmit, resetForm, setValues, values } = useTypedForm<
  ReportParams<'absenteeRate'>
>({
  id: 'report-absentee-rate',
  schema: {
    from: dayJSZod(),
    to: dayJSZod(),
    collectType: AbsenteeRateCollectTypeParam,
    countsType: AbsenteeRateCountsTypeParam,
    timePeriod: DefaultTimePeriodParam,
    groupType: AbsenteeRateGroupTypeParam,
    acceptableGroup: z.never().nullable(),
    targets: z.never().nullable(),
    sort: z.string().nullable(),
    filters: AbsenteeRateFiltersParam,
    groupNameFilters: AbsenteeRateGroupNameFiltersParam.array().nullable(),
    allTime: z.boolean().optional(),
    returnIds: z.boolean().optional(),
  },
  initialValues: reportingStore.preferences.absenteeRate,
})

if (!reportingStore.preferences.enps) {
  resetFormToRole()
}

const setPreset = (newRole: UserRoleEnum) => {
  if (!(newRole in absenteeRatePresets)) {
    return
  }

  setValues({
    from: values.from,
    to: values.to,
    allTime: values.allTime,
    returnIds: values.returnIds,
    ...absenteeRatePresets[newRole],
  })
}

const selectedPreset = computed<UserRoleEnum | null>(() => {
  const foundRole = Object.keys(absenteeRatePresets).find((role) => {
    const userRole = role as UserRoleEnum
    if (!absenteeRatePresets[userRole]) {
      return false
    }
    return isMatch(values, absenteeRatePresets[userRole]!)
  })

  if (foundRole) {
    return foundRole as UserRoleEnum
  }

  return null
})

const fetchReport = handleSubmit(
  async (values) => {
    isLoading.value = true

    setGroupedTogetherParameter(values)

    const result = await reportingStore.fetchReporting<'absenteeRate', AbsenteeRate>(
      'absenteeRate',
      values,
    )

    if (result) {
      reportData.value = result
      reportingTable.value?.closeSettings()
      reportingTable.value?.closePresets()

      await router.replace({ path: route.fullPath, query: { code: result.code } })
    }

    isLoading.value = false
  },
  () => reportingTable.value?.openSettings(),
)

const reportParams: ReportParamOptions<'absenteeRate'> = {
  collectType: [
    {
      id: 'absenteeRateDevelopment',
      name: 'Verloop',
    },
    {
      id: 'absenteeRateStartDate',
      name: 'Start datum',
    },
  ],
  groupType: [
    {
      id: 'team',
      name: 'Per team',
    },
    {
      id: 'age',
      name: 'Per leeftijd',
    },
    {
      id: 'gender',
      name: 'Per geslacht',
    },
  ],
  timePeriod: defaultReportTimeOptions,
}

const sortOptions: OptionsMap<ReportingSortOption>[] = [
  {
    id: 'alphabetical',
    name: 'Alfabetisch',
  },
  {
    id: 'most-to-least',
    name: 'Meest naar minst verzuim',
  },
  {
    id: 'most-to-least-reversed',
    name: 'Minst naar meest verzuim',
  },
]
</script>

<template>
  <div>
    <ReportingTable
      ref="reportingTable"
      :data="reportData"
      :rows="rows"
      :loading="isLoading"
      :param-options="reportParams"
      :selected-preset="selectedPreset"
      :hide-totals="reportData?.params.collectType === 'absenteeRateDevelopment'"
      disable-async
      @submit="fetchReport"
      @reset="resetFormToRole"
      @preset="setPreset"
    >
      <template #admin />

      <template #legend>
        <table class="table">
          <tbody>
            <tr>
              <td>Kort (<1 week)</td>
              <td>Middel (1-6 weken)</td>
              <td>Lang (>6)</td>
            </tr>
          </tbody>
        </table>
      </template>

      <template #table-filters>
        <TableFilter v-model="nameFilter" column-class="tw-is-one-fifth" />
        <div class="tw-column tw-is-one-fifth tw-mb-0 tw-py-0">
          <VMultiselect
            v-model="reportingStore.sortOptions.absenteeRate"
            :options="sortOptions"
          />
        </div>
      </template>

      <template #body-row-cell="{ data, showPercentages, click }">
        <AbsenteeRateCell
          :data="data"
          :show-percentages="showPercentages"
          @click="click"
        />
      </template>

      <template #footer-cell="{ data, showPercentages, click }">
        <AbsenteeRateCell
          :data="data"
          :show-percentages="showPercentages"
          @click="click"
        />
      </template>

      <template #cell-modal="{ data, showPercentages }">
        <AbsenteeRateModalCell
          v-if="data"
          :data="data.data"
          :show-percentages="showPercentages"
        />
      </template>
    </ReportingTable>
  </div>
</template>

<style scoped lang="scss"></style>
