import {
  computed,
  type MaybeRefOrGetter,
  onUnmounted,
  watch,
  type WritableComputedRef,
} from 'vue'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import camelcaseKeys from 'camelcase-keys'
import { clone, fromPairs, get, intersectionBy, isArray, uniqBy } from 'lodash'
import { parseDateResponse } from '/@src/utils/date-formatter'
import { type RouteMap, type RouteParams } from 'vue-router'
import { useAuthStore } from '/@src/stores/auth'
import pusherBatchAuthorizer from '/@src/overrides/pusher-auth'
import type {
  AbstractId,
  KeyOfType,
  MaybeArray,
  ModelLike,
  RelationKeys,
  RelationValue,
} from '/@src/types/utils'

export function connectEcho() {
  if (window.Echo) {
    return
  }

  const authStore = useAuthStore()

  window.Pusher = Pusher

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wssHost: import.meta.env.VITE_PUSHER_HOST,
    wssPort: null,
    wsPort: null,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws'],
    namespace: 'App.Events.WebSockets',

    // This is a terrible hack, only made possible by pusher-auth library not using typescript
    // Proceed with caution.
    auth: {
      params: {
        getToken: () => authStore.authorizationHeader,
      },
    },
    authEndpoint: `${import.meta.env.VITE_WEBSOCKET_AUTH_URL}auth/batch`,
    authDelay: 200,

    authorizer: pusherBatchAuthorizer,
  })
}

window.connectEcho = connectEcho

export async function disconnectEcho(channel: string = 'tg-admin-channel') {
  console.log('Leaving channel')
  await window.Echo.leave(channel)
}

window.disconnectEcho = disconnectEcho

type Models = AbstractId<string> | AbstractId<string>[]

type IdField<T> = KeyOfType<T, AbstractId<string> | null | undefined>

type ModelTracking<T> = {
  [key in IdField<T> | RelationKeys<T>]: Models
}

type CallbackFunction<T> = (message: any, model: Partial<ModelTracking<T>>) => void

interface RelationWebSocket<Model, Parent> {
  id: IdField<Model>
  channel: string
  event: string
  callback: CallbackFunction<Parent>
  notNested?: boolean
}

type RelationObject<T> = IdField<T> | RelationKeys<T> extends never
  ? never
  : {
      [Model in IdField<T> | RelationKeys<T>]?: Model extends IdField<T>
        ? MaybeArray<RelationWebSocket<T, T>>
        : Model extends RelationKeys<T>
          ? MaybeArray<RelationWebSocket<RelationValue<T, Model>, T>>
          : never
    }

interface RelationForEachResult {
  event: string
  channel: string
}

function startListeningInternal<const T>(
  model: ModelTracking<T>,
  channel: string,
  event: string,
  callback: CallbackFunction<T>,
  doLog: boolean = true,
) {
  window.Echo.private(channel).listen(event, (message?: any) => {
    // Parse keys to camelCase
    const parsedMessage = camelcaseKeys(message, { deep: true })
    parseDateResponse(parsedMessage)
    callback(parsedMessage, model)
  })
  if (doLog) {
    console.log(`Subscribed to ${event} on channel private-${channel}`)
  }
}

function stopListeningInternal(channel: string, event: string, doLog: boolean = true) {
  window.Echo.private(channel).stopListening(event)
  if (doLog) {
    console.log(`Stopped listening to ${event} on channel private-${channel}`)
  }
}

function relationsForEach<const T extends ModelLike>(
  relations: RelationObject<T>,
  model: ModelTracking<T>,
  lambda: (channel: string, event: string, callback: CallbackFunction<T>) => void,
): RelationForEachResult[] {
  return Object.keys(relations).flatMap((relationKey) => {
    const retrievedRelations: MaybeArray<
      RelationWebSocket<RelationValue<T, RelationKeys<T>>, T>
    > = relations[relationKey as RelationKeys<T>]!

    let relationArray: RelationWebSocket<RelationValue<T, RelationKeys<T>>, T>[]
    if (Array.isArray(retrievedRelations)) {
      relationArray = retrievedRelations
    } else {
      relationArray = [retrievedRelations]
    }

    return relationArray
      .filter((relation) => {
        let createdRelationId: string
        if (relation.notNested) {
          createdRelationId = String(relation.id)
        } else {
          createdRelationId = `${relationKey}.${String(relation.id)}`
        }
        const relationId = get(model, createdRelationId)
        return !!relationId && relationId > 0
      })
      .flatMap((relation) => {
        let createdRelationId: string
        if (relation.notNested) {
          createdRelationId = String(relation.id)
        } else {
          createdRelationId = `${relationKey}.${String(relation.id)}`
        }
        const relationId = get(model, createdRelationId)

        const callback = relation.callback

        if (Array.isArray(relationId)) {
          return relationId.map((singleRelation) => {
            const channel = `${relation?.channel}-${singleRelation}`
            lambda(channel, relation.event, callback)

            return {
              event: relation.event,
              channel: channel,
            }
          })
        } else {
          const channel = `${relation?.channel}-${relationId}`
          lambda(channel, relation.event, callback)

          return {
            event: relation.event,
            channel: channel,
          }
        }
      })
  })
}

type UseWebSocketModelEventParams<T extends ModelLike> =
  | {
      event: string
      callback?: CallbackFunction<T>
    }
  | {
      event: Record<string, CallbackFunction<T>>
      callback?: never
    }
type UseWebSocketModelParams<T extends ModelLike> = {
  baseChannel: string
  idField?: IdField<T>
  relations?: RelationObject<T>
  cancelOnUnmounted?: boolean
  debug?: boolean
} & UseWebSocketModelEventParams<T>

export function useWebSocketModel<const T extends ModelLike>(
  params: UseWebSocketModelParams<T>,
) {
  const {
    baseChannel,
    event,
    callback = undefined,
    idField = 'id' as IdField<T>,
    relations = undefined,
    cancelOnUnmounted = true,
    debug = import.meta.env.DEV,
  } = params

  const authStore = useAuthStore()

  if (!window.Echo) {
    connectEcho()
  }

  let eventMap: Record<string, CallbackFunction<T>> = {}

  if (typeof event === 'string') {
    if (!callback) {
      throw new Error('No callback specified!')
    }
    eventMap[event] = callback
  } else {
    eventMap = event // Technically eventMapping
  }

  let modelArray: ModelTracking<T>[] = []

  const setModel = (newModel: MaybeRefOrGetter<T>) => {
    setModels([toValue(newModel)])
  }

  const setModels = (newModels: MaybeRefOrGetter<MaybeArray<T>>) => {
    newModels = toValue(newModels)

    if (!isArray(newModels)) {
      newModels = [toValue(newModels)]
    }

    const workWithModels = clone(newModels).map<ModelTracking<T>>((model) => {
      let relationMapping: Record<RelationKeys<T>, Models> | undefined
      if (relations) {
        relationMapping = fromPairs(
          Object.keys(relations).flatMap((relationKey) => {
            const retrievedRelations = relations[relationKey as keyof typeof relations]!

            let relationsArray
            if (!Array.isArray(retrievedRelations)) {
              relationsArray = [retrievedRelations]
            } else {
              relationsArray = retrievedRelations
            }

            return relationsArray
              .map((relation) => {
                let createdRelationKey: string
                if (relation.notNested) {
                  createdRelationKey = String(relation.id)
                } else {
                  createdRelationKey = `${relationKey}.${String(relation.id)}`
                }

                let relationId: Models = get(model, createdRelationKey) as AbstractId<any>

                // Relation must be an array
                if (!relationId) {
                  const maybeRelationArray = get(model, relationKey) as any[] | undefined
                  if (maybeRelationArray && Array.isArray(maybeRelationArray)) {
                    relationId = maybeRelationArray.map(
                      (nestedRelation) =>
                        nestedRelation[relation.id] as AbstractId<string>,
                    )
                  } else {
                    const splitRelationKey = createdRelationKey.split('.')
                    const warnText =
                      splitRelationKey.length === 2 &&
                      splitRelationKey[0] === splitRelationKey[1]
                        ? 'Maybe you forgot to add `notNested` to the relation?'
                        : undefined
                    console.error(
                      `Path ${createdRelationKey} could not be found in model:`,
                      model,
                      warnText,
                    )
                  }
                }

                return [createdRelationKey, relationId]
              })
              .filter(([_, relationId]) => !!relationId)
          }),
        ) as Record<RelationKeys<T>, AbstractId<any>>
      }
      return {
        [idField]: model[idField],
        ...relationMapping,
      } as ModelTracking<T>
    })

    const intersectedModels = intersectionBy(modelArray, workWithModels, idField).map(
      (model) => model[idField],
    )

    const listenToNewModels = workWithModels.filter(
      (model) => !intersectedModels.includes(model[idField]),
    )
    const stopListeningTo = modelArray.filter(
      (model) => !intersectedModels.includes(model[idField]),
    )

    stopListeningSpecific(stopListeningTo)

    modelArray = workWithModels

    Object.keys(eventMap).forEach((event) => {
      const callback = eventMap[event]

      const uniqueModels = uniqBy(listenToNewModels, idField)
      uniqueModels.forEach((model) => {
        const channel = `${baseChannel}-${model[idField]}`

        startListeningInternal(
          model,
          channel,
          event,
          callback,
          debug && listenToNewModels.length === 1,
        )
      })

      if (debug && uniqueModels.length > 1) {
        const joinedChannels = uniqueModels.map((model) => model[idField]).join(', ')

        console.log(
          `Subscribed to ${event} on channel private-${baseChannel}: ${joinedChannels}`,
        )
      }
    })

    const relationResult: RelationForEachResult[] = []
    listenToNewModels.forEach((model) => {
      if (relations) {
        const result = relationsForEach(relations, model, (channel, event, callback) =>
          startListeningInternal(model, channel, event, callback, false),
        )

        relationResult.push(...result)
      }
    })
    if (debug && relationResult.length > 0) {
      console.table(relationResult.map((result) => ({ ...result, type: 'started' })))
    }
  }

  const getModel = (): ModelTracking<T> | undefined => {
    if (modelArray.length > 1) {
      throw new Error('Models is Array, use getModels')
    }
    if (modelArray.length === 0) {
      return undefined
    }

    return modelArray[0]
  }

  const getModels = (): ModelTracking<T>[] | undefined => {
    if (modelArray.length === 1) {
      throw new Error('Models is not an Array, use getModel')
    }
    if (modelArray.length === 0) {
      return undefined
    }
    return modelArray
  }

  const stopListening = () => {
    stopListeningSpecific(modelArray)
  }

  const stopListeningSpecific = (models: ModelTracking<T>[]) => {
    Object.keys(eventMap).forEach((event) => {
      const uniqueModels = uniqBy(models, idField)
      uniqueModels.forEach((model) => {
        const channel = `${baseChannel}-${model[idField]}`

        stopListeningInternal(channel, event, debug && models.length === 1)
      })

      if (debug && uniqueModels.length > 1) {
        const leftChannels = uniqueModels.map((model) => model[idField]).join(', ')

        console.log(
          `Stopped listening to ${event} on channel private-${baseChannel}: ${leftChannels}`,
        )
      }
    })

    const relationResult: RelationForEachResult[] = []
    models.forEach((model) => {
      if (relations) {
        const result = relationsForEach(relations, model, (channel, event) =>
          stopListeningInternal(channel, event, false),
        )

        relationResult.push(...result)
      }
    })
    if (debug && relationResult.length > 0) {
      console.table(relationResult.map((result) => ({ ...result, type: 'stopped' })))
    }

    modelArray = modelArray.filter(
      (model) =>
        !models.find((internalModel) => internalModel[idField] === model[idField]),
    )
  }

  if (cancelOnUnmounted) {
    tryOnUnmounted(() => {
      // Only stop listening if we're still logged in (else it's being taken care of by (disconnectEcho))
      if (authStore.user) {
        stopListening()
      }
    })
  }

  return {
    getModel,
    getModels,
    setModel,
    setModels,
    stopListening,
  }
}

interface UseWebSocketPageParams<T extends ModelLike, Name extends keyof RouteMap> {
  model: WritableComputedRef<T | undefined>
  baseChannel: string
  event: string
  loadModelFunction: () => Promise<boolean>
  routeParam: keyof RouteParams<Name>
  idField?: IdField<T>
  callback?: CallbackFunction<T>
  relations?: RelationObject<T> | undefined
  cancelOnUnmounted?: boolean
  debug?: boolean
}

export function useWebSocketPage<
  const T extends ModelLike,
  const Name extends keyof RouteMap,
>(params: UseWebSocketPageParams<T, Name>) {
  const {
    model,
    baseChannel,
    event,
    loadModelFunction,
    routeParam,
    idField = 'id' as IdField<T>,
    callback = undefined,
    relations = undefined,
    cancelOnUnmounted = true,
    debug = import.meta.env.DEV,
  } = params

  const route = useRoute()

  const hasLoadedModel = computed<boolean>(
    () =>
      !!model.value && model.value[idField] === Number((route.params as any)[routeParam]),
  )

  const { setModel, stopListening, getModel } = useWebSocketModel<T>({
    baseChannel,
    event,
    callback,
    idField,
    relations,
    cancelOnUnmounted,
    debug,
  })

  const loadModel = async () => {
    if (hasLoadedModel.value && !stopListening) {
      setModel(model.value!)
      return
    } else if (getModel() === Number((route.params as any)[routeParam])) {
      return
    }

    model.value = undefined
    const result = await loadModelFunction()

    if (result && model.value) {
      setModel(model.value)
    }
  }

  watch(
    route,
    async () => {
      const paramId = (route.params as any)[routeParam]
      const currentId = model.value?.[idField]
      // Intentional loose equality check, since paramId can be a string, currentId is always a number
      if (paramId && paramId != currentId) {
        await loadModel()
      }
    },
    { deep: true },
  )

  if (getCurrentScope()) {
    onUnmounted(() => {
      model.value = undefined
    })
  }

  loadModel().then(() => {})

  return {
    hasLoadedModel,
    setModel,
    stopListening,
  }
}

interface UseWebSocketParams {
  event: string
  callback: CallbackFunction<never>
  cancelOnUnmounted?: boolean
  channel?: string
  delayStart?: boolean
  debug?: boolean
}

export function useWebSocket(params: UseWebSocketParams) {
  const {
    event,
    callback,
    cancelOnUnmounted = true,
    channel = 'tg-admin-channel',
    delayStart = false,
    debug = import.meta.env.DEV,
  } = params

  const authStore = useAuthStore()

  let internalChannel = channel

  const isListening = ref(false)

  if (!window.Echo) {
    connectEcho()
  }

  const setChannel = (newChannel: string) => {
    stopListeningInternal(internalChannel, event, debug)
    internalChannel = newChannel
    startListeningInternal(
      { id: -1 as AbstractId<never> },
      internalChannel,
      event,
      callback,
      debug,
    )
  }

  const startListening = () => {
    if (isListening.value) {
      return
    }
    startListeningInternal(
      { id: -1 as AbstractId<never> },
      internalChannel,
      event,
      callback,
      debug,
    )
    isListening.value = true
  }
  if (!delayStart) {
    startListening()
  }

  const stopListening = () => {
    isListening.value = false
    stopListeningInternal(internalChannel, event, debug)
  }

  if (cancelOnUnmounted) {
    tryOnUnmounted(() => {
      // Only stop listening if we're still logged in (else it's being taken care of by (disconnectEcho)
      if (authStore.user) {
        stopListening()
      }
    })
  }

  return {
    startListening,
    stopListening,
    setChannel,
    isListening,
  }
}
