<script lang="ts" setup generic="T extends string | number = string">
import type {
  ComponentClass,
  ComponentSize,
  VTabsAlign,
  VTabsItem,
  VTabsType,
} from '/@src/types/elements-ui'
import { isArray } from 'lodash'
import { toValue } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type { MaybeArray } from '/@src/types/utils'

interface VTabsProps {
  tabs: VTabsItem<T>[]
  size?: ComponentSize
  align?: VTabsAlign
  type?: VTabsType
  rounded?: boolean
  tabsClass?: ComponentClass
  listClass?: ComponentClass
}

const props = withDefaults(defineProps<VTabsProps>(), {
  size: 'normal',
  tabsClass: '',
  listClass: '',
  type: 'regular',
  align: 'left',
})

const activeValue = defineModel<MaybeArray<T | undefined>>('selected', { required: true })

const visibleTabs = computed<VTabsItem<T>[]>(() =>
  props.tabs.filter((t) => !toValue(t.hidden)),
)

const singleValue = computed<T | undefined>(() =>
  !isArray(activeValue.value) ? activeValue.value : undefined,
)

const toggle = (value: T): void => {
  if (isArray(activeValue.value)) {
    if (activeValue.value.includes(value)) {
      const idx = activeValue.value.findIndex((v) => v === value)
      activeValue.value.splice(idx, 1)
    } else {
      activeValue.value.push(value)
    }
  } else {
    activeValue.value = value
  }
}

const getTabClass = (tab: VTabsItem<T>): string | undefined => {
  const color = toValue(tab.color)

  let colorClass: string
  if (props.type === 'regular' || props.type === 'toggle') {
    colorClass = `!tw-text-${color} !tw-border-${color} hover:tw-brightness-90`
  } else if (props.type === 'rating') {
    colorClass = `!tw-text-${color} !tw-border-${color} hover:!tw-bg-${color} hover:!tw-text-${color}-text hover:tw-brightness-90`
  } else {
    colorClass = `tw-bg-${color} !tw-text-${color}-text !tw-border-${color} !tw-border hover:!tw-bg-${color}`
  }

  if (
    activeValue.value === tab.value ||
    (isArray(activeValue.value) && activeValue.value.includes(tab.value))
  ) {
    if (color) {
      if (props.type === 'rating') {
        colorClass = `tw-bg-${color} !tw-text-${color}-text !tw-border-${color} !tw-border hover:!tw-bg-${color}`
      }

      return colorClass
    } else {
      return 'is-active'
    }
  }

  if (color) {
    return colorClass
  }

  return undefined
}
</script>

<template>
  <div class="tw-tabs-wrapper">
    <div class="tw-tabs-inner">
      <div
        class="tw-tabs"
        :class="[`is-${size}`, `is-${type}`, rounded && 'is-rounded', tabsClass]"
      >
        <slot name="pre-tabs"></slot>
        <ul
          :class="[
            listClass,
            align === 'left' && 'tw-justify-start',
            align === 'center' && 'tw-justify-center',
            align === 'right' && 'tw-justify-end',
          ]"
        >
          <li
            v-for="(tab, key) in visibleTabs"
            :key="key"
            :class="[
              toValue(tab.hidden) && 'is-hidden',
              toValue(tab.disabled) && 'is-disabled',
            ]"
            v-tooltip="toValue(tab.tooltip) ?? ''"
          >
            <slot
              name="tab-link"
              v-bind="{
                activeValue,
                singleValue,
                tab,
                key,
                toggle,
              }"
            >
              <RouterLink
                v-if="toValue(tab.to)"
                :to="toValue(tab.to)! as RouteLocationRaw"
                :class="getTabClass(tab as VTabsItem<T>)"
                @click.prevent="() => toggle(tab.value as T)"
                @keydown.prevent.enter="() => toggle(tab.value as T)"
              >
                <VIcon
                  v-if="!!toValue(tab.icon)"
                  :icon="toValue(tab.icon)!"
                  :icon-pack="toValue(tab.iconPack)"
                />
                <span v-if="!!toValue(tab.label) || $slots['tab-link-label']">
                  <slot
                    name="tab-link-label"
                    v-bind="{
                      activeValue,
                      singleValue,
                      tab,
                      key,
                      toggle,
                    }"
                  >
                    {{ toValue(tab.label) }}
                  </slot>
                </span>
              </RouterLink>

              <a
                v-else
                :class="getTabClass(tab as VTabsItem<T>)"
                @click.prevent="() => toggle(tab.value as T)"
                @keydown.prevent.enter="() => toggle(tab.value as T)"
              >
                <VIcon
                  v-if="!!toValue(tab.icon)"
                  :icon="toValue(tab.icon)!"
                  :icon-pack="toValue(tab.iconPack)"
                />
                <span v-if="!!toValue(tab.label) || $slots['tab-link-label']">
                  <slot
                    name="tab-link-label"
                    v-bind="{
                      activeValue,
                      singleValue,
                      tab,
                      key,
                      toggle,
                    }"
                  >
                    {{ toValue(tab.label) }}
                  </slot>
                </span>
              </a>
            </slot>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!!$slots['tab']" class="tw-tab-content">
      <slot name="tab" :active-value="activeValue" :single-value="singleValue" />
    </div>
  </div>
</template>

<style scoped></style>
